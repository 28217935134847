import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Photo_selfie() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Social Media Profile tips & Strategies (Social Profile Strategies)
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <p className="pb-4 font-semibold text-xl">RESOURCES</p>

                <p className="font-semibold pb-4 flex break-all">
                  1)&nbsp;
                  <a
                    href="https://www.jasper.ai/blog/perfect-linkedin-profile?psafe_param=1&adgroupid=&campaignid=16165470700&utm_source=google&utm_term=&utm_content=&utm_campaign=Performance+max&utm_medium=ppc&hsa_acc=5024544109&hsa_cam=16165470700&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gclid=CjwKCAjw0ZiiBhBKEiwA4PT9zywD_jlf3NacjOXurwN1-YUrirGmVhGy_JtXMmspZifMXDgYNqCh9RoCaj4QAvD_BwE"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to create a perfect linkedein profile &nbsp;
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  2)&nbsp;
                  <a
                    href="https://www.jasper.ai/blog/optimize-linkedin-profile?psafe_param=1&adgroupid=&campaignid=16165470700&utm_source=google&utm_term=&utm_content=&utm_campaign=Performance+max&utm_medium=ppc&hsa_acc=5024544109&hsa_cam=16165470700&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gclid=CjwKCAjw0ZiiBhBKEiwA4PT9z7wYv9viLF2Vu-9Hi38bjNezrVXj7wf3jNQoY0TqeX3n336B2XqLUhoCuKQQAvD_BwEV"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    Thirteen tips to optimize your LinkedIn profile for better
                    opportunities
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  3)&nbsp;
                  <a
                    href="https://www.linkedin.com/business/sales/blog/profile-best-practices/17-steps-to-a-better-linkedin-profile-in-2017"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    Twenty steps to a better LinkedIn profile in 2023
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  4)&nbsp;
                  <a
                    href="https://www.linkedin.com/pulse/how-optimize-your-linkedin-profile-make-stand-out-plus-chan-mba/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Optimize Your LinkedIn Profile & Make It Stand Out
                    (Plus 10 great LinkedIn profiles!)
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  5)&nbsp;
                  <a
                    href="https://www.linkedin.com/pulse/improve-your-linkedin-profile-5-minutes-lillian-cotter"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    Improve Your LinkedIn Profile in 5 Minutes
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  6)&nbsp;
                  <a
                    href="https://www.coursera.org/articles/linkedin-profile?utm_medium=sem&utm_source=gg&utm_campaign=B2C_NAMER_ibm-data-science_ibm_FTCOF_professional-certificates_country-US-country-CA-pmax-nonNRL-within-14d&campaignid=19995348162&adgroupid=&device=c&keyword=&matchtype=&network=x&devicemodel=&adposition=&creativeid=&hide_mobile_promo&gclid=CjwKCAjw0ZiiBhBKEiwA4PT9z7IpWI1qNJzFgjBgAxDMVMlZzdtNmvIr8lyDBrslu7S7MgRiIZ0uHBoC3foQAvD_BwE"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Create a Striking LinkedIn Profile: Guide + Tips
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  7)&nbsp;
                  <a
                    href="https://zety.com/blog/optimize-your-linkedin-profile"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    99 LinkedIn Profile Tips: from Summary & Headline to Images
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  8)&nbsp;
                  <a
                    href="https://www.six-degrees.com/how-to-build-a-strong-brand-in-7-steps/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Build a Strong Brand in 7 Steps
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  9)&nbsp;
                  <a
                    href="https://socialcircleinc.com/brand-stategy-workbook?gclid=CjwKCAjw0ZiiBhBKEiwA4PT9zy7RlJebK7BDP510hqfTysf1YljsjBtYn4zDiEoT-S0GdqbzEezEsxoCwWgQAvD_BwE"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    Develop your brand in just 14 days
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  10)&nbsp;
                  <a
                    href="https://hbr.org/2022/09/how-to-build-your-personal-brand-at-work"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Build Your Personal Brand at Work
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  11)&nbsp;
                  <a
                    href="https://us.aicpa.org/interestareas/youngcpanetwork/resources/career/fivetipstobrandingyourself"
                    target="_blanck"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    Five Tips to Branding Yourself
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  12)&nbsp;
                  <a
                    href="https://blog.pivo.ai/2022/06/01/building-your-personal-brand-tips-for-beginners/?wickedsource=google&wickedid=CjwKCAjw0ZiiBhBKEiwA4PT9z46jmgBtJcOimnczBz0xY9r6-GHiUowaNhLnsMIjHHFOU-8qZHZMbxoCJIEQAvD_BwE&wv=3&wickedsource=google&wickedid=CjwKCAjw0ZiiBhBKEiwA4PT9z46jmgBtJcOimnczBz0xY9r6-GHiUowaNhLnsMIjHHFOU-8qZHZMbxoCJIEQAvD_BwE&wickedid=&wv=3.1&maas=maas_adg_api_8798368620301_macro_1_47&ref_=aa_maas&aa_campaignid=17436685124&aa_adgroupid=&aa_creativeid=|CjwKCAjw0ZiiBhBKEiwA4PT9z46jmgBtJcOimnczBz0xY9r6-GHiUowaNhLnsMIjHHFOU-8qZHZMbxoCJIEQAvD_BwE&&gclid=CjwKCAjw0ZiiBhBKEiwA4PT9z46jmgBtJcOimnczBz0xY9r6-GHiUowaNhLnsMIjHHFOU-8qZHZMbxoCJIEQAvD_BwE"
                    target="_blanck"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Build Your Personal Brand: Tips for Beginners
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  13)&nbsp;
                  <a
                    href="https://www.thinkific.com/blog/personal-branding-guide/"
                    target="_blanck"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    How to Build a Personal Brand (Complete Guide)
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  14)&nbsp;
                  <a
                    href="https://www.forbes.com/sites/goldiechan/2018/11/08/10-golden-rules-personal-branding/"
                    target="_blanck"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    10 Golden Rules Of Personal Branding
                  </a>
                  &nbsp;
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  15)&nbsp;
                  <a
                    href="https://www.canva.com/learn/personal-branding/"
                    target="_blanck"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    The complete guide to personal branding
                  </a>
                  &nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Photo_selfie;
