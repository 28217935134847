import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Problem() {
  const navigate = useNavigate();

  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-50 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-problem.png"
        />
        <div className="container mx-auto">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-24">
              Admission, Alumni Association, <br /> Career Center, and Athletic
              Administrators
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14] px-4">
        <div className="container mx-auto py-16">
          <p className="text-5xl leading-none font-bold pb-8 text-center ">
            Here’s the Problem
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="pt-10">
            <p className="font-semibold text-center text-3xl italic">
              Poor Linkedin profile headshots result in lost career
              opportunities!
            </p>
            <p className="font-semibold text-xl pt-8">
              Most people don’t put enough thought into their LinkedIn profile
              headshots.
            </p>
            <p className="font-medium text-xl pt-8">
              Research shows hiring managers spend 17 percent of their time on a
              profile page looking at candidates headshots.  Knowing that
              employers create "first Impressions" in just 1/10th of a second,
              helping students, grads, and alumni’s LinkedIn profile stand out
              is critical.
            </p>
          </div>
          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>71%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of recruiters didn’t hire a candidate because they had an
                  unimpressive LinkedIn profile photo.
                </p>
              </div>
            </div>

            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>76%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of hiring managers felt professional headshots make candidates
                  look more competent.
                </p>
              </div>
            </div>

            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>96%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of hiring managers believe a candidate's LinkedIn photo
                  inspires confidence.
                </p>
              </div>
            </div>
          </div>
          <div className="font-medium text-xl">
            <p>
              Additional research shows this results in a 40% increase in
              inMails received by recruiters and increases the chances of being
              contacted by a factor of 2 times.
            </p>
            <p className="py-8">
              Our mission is to help students, grads, and alumni act on this
              research so they can make an immediate impression on recruiters
              and hiring managers so they end up getting interviews and job
              offers faster with less effort
            </p>
            <p className="font-semibold">
              To learn how MySocial Headshots can help your college:
            </p>
          </div>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4 mt-8 mb-6">
            <p className="font-medium text-xl">
              Get students engaged in their careers earlier in their college
              experience.
            </p>
          </div>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4  mb-6">
            <p className="font-medium text-xl">
              Increase student and grad satisfaction and endorsement to increase
              enrollment.
            </p>
          </div>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4  mb-6">
            <p className="font-medium text-xl">
              Increase post-graduation career success so students can pay down
              their student loans faster.
            </p>
          </div>
          <p className="font-semibold text-xl pt-8">
            ...and help students, grads, and alumni:
          </p>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4 mt-8 mb-6">
            <p className="font-medium text-xl">Find mentors</p>
          </div>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4  mb-6">
            <p className="font-medium text-xl">Get internships</p>
          </div>
          <div className="border border-amber-400 rounded-lg bg-[#fff] p-4  mb-6">
            <p className="font-medium text-xl">Land jobs.</p>
          </div>
          <div className="flex justify-center font-semibold mt-10">
            <button
              type="button"
              className="w-full rounded-md max-w-[600px] py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
              onClick={() => {
                navigate("/solution");
                window.scrollTo(0, 0);
              }}
            >
              Check out My Social Headshots
            </button>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Problem;
