import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function BasicDetails() {
  const [editCancel, setEditCancel] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [formFields, setformFields] = useState([]);
  const navigate = useNavigate();

  const updateProfileFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...profileDetails,
          token: localStorage.getItem("user_token_headShots"),
        },
      });
      if (response.status == 200) {
        console.log(response.data, "1st page profile");
        // if(!checkTheField("Expected Year to Graduate")&&!checkTheField("College You Will Graduate From")){
        //   navigate("/additional_information");
        // }else{
        navigate("/college_details");
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileDetails = async () => {
    console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getFormFields = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/formfields/${
          JSON.parse(localStorage.getItem("university_data"))?.id
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      // console.log(response,"collage details")
      setformFields(response.data.formFields);
    } catch (error) {
      console.log(error);
    }
  };
  const checkTheField = (key) => {
    let find = formFields?.find((item) => item?.question == key);
    if (find?.isEnabled) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    setprofileDetails({ ...profileDetails, [e.target.id]: e.target.value });
  };
  console.log(profileDetails, "profile details");
  useEffect(() => {
    getProfileDetails();
    getFormFields();
  }, []);

  return (
    <>
      <Header />
      <Container className="txt-brown py-4  px-14">
        <Row>
          <Col className="col-12 pb-4">
            <h2 className="fw-600 fs-30">Basic Details</h2>
          </Col>
        </Row>
        {formFields?.length > 0 && (
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-8">
              <div className="row pb-4">
                {checkTheField("First Name") && (
                  <div className="col-sm-6">
                    <label htmlFor="first_name" className="form-label fw-600">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      id="firstName"
                      value={profileDetails?.firstName}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {checkTheField("Last Name") && (
                  <div className="col-sm-6">
                    <label htmlFor="last_name" className="form-label fw-600">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      id="lastName"
                      value={profileDetails?.lastName}
                      onChange={handleChange}
                    />
                  </div>
                )}

                <div className="col-sm-12 pt-4">
                  {checkTheField("Email") && (
                    <div className="pb-4">
                      <label htmlFor="email" className="form-label fw-600">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        id="email"
                        value={profileDetails?.email}
                        disabled
                      />
                    </div>
                  )}

                  <div className="pb-4 d-flex justify-content-end">
                    <div>
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2 px-4"
                      onClick={updateProfileFn}
                    >
                      Next
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={updateProfileFn}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default BasicDetails;
