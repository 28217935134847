import React from "react";
import { useNavigate } from "react-router-dom";

function Header2() {
  const navigate = useNavigate();

  return (
    <>
      <header className="bg-[#FFF6E4] h-20">
        <div className="container mx-auto flex items-center h-full px-4">
          <a
            onClick={() => {
              localStorage.removeItem("user_email_headShots");
              localStorage.removeItem("user_token_headShots");
              localStorage.removeItem("university_data");
              navigate("/");
              window.scrollTo(0, 0);
            }}
            className="cursor-pointer"
          >
            <img src="images/logo.png" alt="" className="h-16 w-48" />
          </a>
        </div>
      </header>
    </>
  );
}

export default Header2;
