import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../pages/logo.png";

function Footer({ collageData }) {
  const navigate = useNavigate();
  const getFormattedPhone = (phone) => {
    console.log('phone:', phone); // Debugging
    if (phone) {
      let phoneNumber = phone.toString(); // Ensure phone is a string
      const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      return formattedPhoneNumber;
    } else {
      return "";
    }
  }
  
  
  return (
    <>
      <footer style={{ background: "#2F2F2F" }}>
        <Container className="text-white px-14">
          <Row className="pb-4">
            <Col className="col-lg-3 col-12">
              <a className="cursor-pointer">
                <div
                  className="d-inline-block"
                  style={{ width: "60px", height: "60px" }}
                >
                  <img
                    src={
                      collageData && collageData?.logo
                        ? `${process.env.REACT_APP_BASE_URL}/${collageData?.logo}`
                        : `${process.env.REACT_APP_BASE_URL}/${
                            JSON.parse(localStorage.getItem("university_data"))
                              ?.logo
                          }`
                    }
                    className="w-100"
                  ></img>
                </div>
              </a>
              <p className="py-3">Powered by</p>
              <a
                className="cursor-pointer ft-logobox rounded-lg bg-white d-flex justify-content-center align-items-center"
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
              >
                <img src={logo} className=""></img>
              </a>
            </Col>
            <Col className="col-lg-2 col-12">
              <p className="fs-20 fw-600">About</p>
              <ul>
                <li className=" fw-500">
                  <a
                    className=""
                    onClick={() => {
                      navigate("/whatwedo");
                      window.scrollTo(0, 0);
                    }}
                  >
                    What we do
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    onClick={() => {
                      navigate("/faq");
                      window.scrollTo(0, 0);
                    }}
                  >
                    FAQ’s
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    onClick={() => {
                      navigate("/contact");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="col-lg-4 col-12">
              <p className="fs-20 fw-600">Career & Photo Resources</p>
              <ul>
                <li className=" fw-500">
                  <a
                    className=""
                    onClick={() => {
                      navigate("/why_enhance_profiles");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Why Enhance your LinkedIn profile photo
                  </a>
                </li>
                <li>
                  <a
                    className=""
                    onClick={() => {
                      navigate("/photo_selfie");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Photo (Selfie) Tips
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    onClick={() => {
                      navigate("/Linkedin_profile");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Social media and LinkedIn Profile Tips
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="col-lg-3 col-12">
              <p className="fs-20 fw-600">Organization Resources</p>
              <ul>
                {JSON.parse(localStorage.getItem("university_data"))
                  ?.careerCenterLabel && (
                  <li className=" fw-500">
                    <a
                      href={
                        collageData && collageData?.careerCenter
                          ? collageData?.careerCenter
                          : JSON.parse(localStorage.getItem("university_data"))
                              ?.careerCenter
                      }
                      className=""
                      target="_blank"
                    >
                      {
                        JSON.parse(localStorage.getItem("university_data"))
                          ?.careerCenterLabel
                      }
                    </a>
                  </li>
                )}
                {JSON.parse(localStorage.getItem("university_data"))
                  ?.alumniAssociationLabel && (
                  <li className="">
                    <a
                      href={
                        collageData && collageData?.alumniAssociation
                          ? collageData?.alumniAssociation
                          : JSON.parse(localStorage.getItem("university_data"))
                              ?.alumniAssociation
                      }
                      className=""
                      target="_blank"
                    >
                      {
                        JSON.parse(localStorage.getItem("university_data"))
                          ?.alumniAssociationLabel
                      }
                    </a>
                  </li>
                )}
                {JSON.parse(localStorage.getItem("university_data"))
                  ?.linkedinAlumniNetworkLabel && (
                  <li>
                    <a
                      href={
                        collageData && collageData?.linkedinAlumniNetwork
                          ? collageData?.linkedinAlumniNetwork
                          : JSON.parse(localStorage.getItem("university_data"))
                              ?.linkedinAlumniNetwork
                      }
                      className=""
                      target="_blank"
                    >
                      {
                        JSON.parse(localStorage.getItem("university_data"))
                          ?.linkedinAlumniNetworkLabel
                      }
                    </a>
                  </li>
                )}

                <li className="ft-copytxt">
                  <span>mail - </span>
                  <a className="">
                    {collageData && collageData?.universityEmail
                      ? collageData?.universityEmail
                      : JSON.parse(localStorage.getItem("university_data"))
                          ?.universityEmail}
                  </a>
                </li>
                <li>
                  <a className="">
                    Phone -{" "}
                    {collageData && collageData?.universityPhone
                      ? getFormattedPhone(collageData?.universityPhone)
                      : getFormattedPhone(JSON.parse(localStorage.getItem("university_data"))
                          ?.universityPhone)}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <hr />
          {/* <Row>
            <Col className="col-12 text-center pt-4 ft-copytxt">
              <span className="">
                © Copyright <a className="">MySocial Headshots</a> @{" "}
                {new Date().getFullYear()}
              </span>
            </Col>
          </Row> */}
          <div className="w-full mx-auto max-w-screen-xl p-4 pb-0 md:flex md:items-center md:justify-between">
            <span className="text-[#fff]  sm:text-center">
              © Copyright{" "}
              <a className="hover:underline hover:text-[#FBB522]">
                MySocial Headshots
              </a>{" "}
              @ {new Date().getFullYear()}
            </span>
            <ul className="flex flex-wrap items-center mt-3 font-medium text-[#fff] sm:mt-0">
              <li>
                <a
                  className="mr-4 hover:underline md:mr-6 hover:text-[#FBB522] cursor-pointer"
                  onClick={() => {
                    navigate("/privacy");
                    window.scrollTo(0, 0);
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="mr-4 hover:underline md:mr-6 hover:text-[#FBB522] cursor-pointer"
                  onClick={() => {
                    navigate("/terms");
                    window.scrollTo(0, 0);
                  }}
                >
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
