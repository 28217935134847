import React from 'react'
import Header from '../components/Header'

function After_signup() {
  return (
    <>
    <Header/>
    <div>After_signup</div>
    </>
  )
}

export default After_signup