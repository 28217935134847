import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const location = useLocation();
  console.log(location);
  let array = location.pathname?.split("/");
  console.log(array, "array");

  return (
    <>
      <div className="drop-header w-full">
        <Navbar expand="lg" className="px-12 w-full">
          <Container className="w-full">
            <Navbar.Brand
              className={
                location.pathname != "/"
                  ? "cursor-pointer  br-right"
                  : "cursor-pointer"
              }
              onClick={() => {
                localStorage.removeItem("user_email_headShots");
                localStorage.removeItem("user_token_headShots");
                localStorage.removeItem("university_data");
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src="images/logo.png" alt="logo"></img>
            </Navbar.Brand>

            {location.pathname != "/" && (
              <>
                {" "}
                <div className="">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${
                      JSON.parse(localStorage.getItem("university_data"))?.logo
                    }`}
                    width={50}
                    height={50}
                    alt="..."
                  />
                </div>
                <div className="px-2 fw-600">
                  {
                    JSON.parse(localStorage.getItem("university_data"))
                      ?.universityName
                  }
                </div>
              </>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {(location.pathname == "/login" ||
              location.pathname == "/sign_up" ||
              location.pathname == "/login_email" ||
              location.pathname == "/forgot_password" ||
              location.pathname == "/signup_email") && (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto flex flex-row justify-end mr-0 pr-0">
                  <div className="flex flex-row justify-end ms-auto log-singbtn ">
                    <Nav.Link
                      className="fs-20 fw-600 me-4"
                      style={{
                        color:
                          (location.pathname == "/login" ||
                            location.pathname == "/login_email") &&
                          "#FBB522",
                      }}
                      onClick={() => {
                        navigate("/login");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Log In
                    </Nav.Link>
                    <Nav.Link
                      className="fs-20 fw-600 cursor-pointer"
                      style={{
                        color:
                          (location.pathname == "/sign_up" ||
                            location.pathname == "/signup_email") &&
                          "#FBB522",
                      }}
                      onClick={() => {
                        navigate("/sign_up");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Sign Up
                    </Nav.Link>
                  </div>
                </Nav>
              </Navbar.Collapse>
            )}
            {location.pathname == "/my_profile" ||
            location.pathname == "/after_signup" ||
            location.pathname == "/create" ||
            location.pathname == "/myheadshot" ? (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto nav">
                  <Nav.Link
                    className={`nav-item ${
                      location.pathname === "/create" ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate("/create");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Create
                  </Nav.Link>
                  <Nav.Link
                    className={`nav-item ${
                      location.pathname === "/myheadshot" ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate("/myheadshot");
                      window.scrollTo(0, 0);
                    }}
                  >
                    My Headshots
                  </Nav.Link>
                  <Nav.Link
                    className={`nav-item ${
                      location.pathname === "/my_profile" ? "active" : ""
                    }`}
                    onClick={() => {
                      navigate("/my_profile");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Profile
                  </Nav.Link>
                  <Nav.Link
                    className={`nav-item ${
                      location.pathname === "/logout" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.removeItem("user_email_headShots");
                      localStorage.removeItem("user_token_headShots");
                      localStorage.removeItem("university_data");
                      navigate("/");
                    }}
                  >
                    Logout
                  </Nav.Link>
                  <span className="nav-indicator"></span>
                </Nav>
              </Navbar.Collapse>
            ) : (
              <>
                {location.pathname === "/login" ||
                location.pathname === "/sign_up" ||
                location.pathname === "/signup_email" ||
                location.pathname === "/login_email" ? (
                  ""
                ) : (
                  <Navbar.Collapse id="basic-navbar-nav">
                    {console.log("came inside")}
                    <Nav className="ms-auto nav">
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/create" ? "active" : ""
                        }`}
                      ></Nav.Link>
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/myheadshot" ? "active" : ""
                        }`}
                      ></Nav.Link>
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/my_profile" ? "active" : ""
                        }`}
                      ></Nav.Link>
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/logout" ? "active" : ""
                        }`}
                      ></Nav.Link>
                      <span className="nav-indicator"></span>
                    </Nav>
                  </Navbar.Collapse>
                )}
              </>
            )}
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
