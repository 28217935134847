import { Outlet, useNavigate } from "react-router-dom";
import AsideBar from "../components/AsideBar";
import Footer from "../components/Footer";
import Header from "./Header";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";

function LayoutOutlet() {
  const navigate = useNavigate();
  return (
    <>
      {/* <Header/> */}
      <div className="harvard-header">
        <Navbar
          expand="lg"
          style={{
            background: JSON.parse(localStorage.getItem("university_data"))
              ?.headerColor,
          }}
        >
          <Container>
            <Navbar.Brand className="cursor-pointer">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${
                  JSON.parse(localStorage.getItem("university_data"))?.logo
                }`}
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex ms-auto harvard-btn ">
              <button
                className="fs-19 fw-600 me-4"
                onClick={() => {
                  localStorage.removeItem("user_email_headShots");
                  localStorage.removeItem("user_token_headShots");
                  localStorage.removeItem("admin_data_headShots");
                  localStorage.removeItem("admin_data_headShots_collage");
                  localStorage.removeItem("university_data");

                  navigate("/");
                }}
              >
                Logout
              </button>
              {/* <button className="fs-18 fw-600 cursor-pointer">Sign Up</button> */}
            </div>
          </Container>
        </Navbar>
      </div>
      <div class="layout">
        <AsideBar></AsideBar>
        <main>
          <Outlet></Outlet>
        </main>
      </div>
      <Footer />
    </>
  );
}
export default LayoutOutlet;
