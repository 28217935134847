import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Privacy() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">Privacy Policy</p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <p className="pb-4 font-semibold text-xl">
                  Last Updated: January 2023
                </p>
                <p className="pb-4">
                  This Privacy Policy sets out the approach taken by TalentMarks
                  to the Personal Data of users and visitors of MySocia
                  Headshots and any other website ‘Powered by TalentMarks.
                  MySocial Headshots is a business of TalentMarks, Inc. By
                  browsing this website and the services within them
                  (collectively the “Services”), you (“You”) agree that your
                  personal data and other data (collectively, “Personal Data”)
                  will be dealt, in accordance with this Privacy Policy.
                  TalentMarks. serves a data controller in its use of your
                  Personal Data, as described in this Privacy Policy. Privacy
                  and confidentiality are at the heart of the Services.
                  Additionally, we understand the importance of maintaining the
                  confidentiality of any information stored about You while
                  using the Services. We respect your privacy – TalentMarks in
                  its provision of Services upholds the treating of Personal
                  Data properly, in the strictest confidence, with sensitivity
                  and in accordance with applicable law. The Personal Data You
                  provide by using the Services is owned by You, and You may ask
                  us to correct or delete any such information at any time, by
                  sending us an email stating in the subject heading, “Remove
                  Details”. Further information about how You can manage and
                  delete information about You is set out in paragraph 5
                  below.Except as otherwise in this Privacy Policy, the
                  definitions and interpretations contained in our General Terms
                  and Conditions shall also apply to this Privacy Policy.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Information Collection
                </p>

                <p className="pb-4">
                  We collect Personal Data when You register for an account,
                  when You use certain Services (including when You sign up for
                  an account, create or share, and message or communicate with
                  others) and when You visit certain Site pages. This can
                  include information on or about the content You provide or
                  about how You use our Services, such as the types of content
                  You view or engage with, the people and groups You are
                  connected to and how You interact with them or the frequency
                  and duration of your activities.
                </p>

                <p className="pb-4">
                  When You register with us, as further described under clause 3
                  to our General Terms and Conditions, we will ask You for
                  and/or collect Personal Data, directly (for example, to enter
                  your name, email address, birth date, gender, postal code,
                  occupation, industry, personal interests etc.) or indirectly
                  (through the “Connect with” LinkedIn or Facebook option,
                  located at our Website’s homepage).
                </p>

                <p className="pb-4">
                  By registering with us, You acknowledge and agree that We may
                  access and store your Personal Data anywhere in the world. If
                  You registered with us through the “Connect with” LinkedIn or
                  Facebook option, You also acknowledge and agree that we may
                  access and store information from your LinkedIn or Facebook
                  profile, respectively. Further information related to this
                  section is available under Clause 3 of our General Terms of
                  Use.
                </p>

                <p className="pb-4">
                  Once You register with Us and sign in to our Services, You are
                  not anonymous to Us. If You contact Us in relation to any of
                  the Services (via email, telephone, post or otherwise), We may
                  collect and retain your contact details and your communication
                  for the purpose of handling your query and keeping records of
                  communications.
                </p>

                <p className="pb-4">
                  We also collect information about You from CONNECTDDD (the
                  “Member Organization”) and from other people when they use our
                  Services, such as when they send a message to You, or upload,
                  sync or import your contact information. We also work closely
                  with other third parties (including, for example, business
                  partners, advertising networks, analytics providers, search
                  information providers), and We may sometimes receive
                  information about You from them. All such information may be
                  combined with the data You provide to Us when You register
                  with Us and / or when You use the Services, and the combined
                  data may be used by Us for the same purposes outlined in this
                  notice.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  How we use your Personal Data
                </p>

                <p className="pb-4">
                  Personal Data and other details about You that You supply as
                  part of the registration process or that are collected by Us
                  when You use the Services are used to provide You with the
                  Services and information as set out in our Terms of Use. We
                  will not use that information for any purpose other the
                  Services.
                </p>

                <p className="pb-4">
                  We will use information provided by You to help You advance
                  your career through inside connections, leverage your
                  professional network, get introduced to people, socially
                  interact with other users and share photos with them, create
                  and/or share and/or find business or mentoring opportunities
                  and stay in touch with the Membership Organization. You
                  consent to the use of your profile and Personal Data being
                  used for this purpose.
                </p>

                <p className="pb-4">
                  We use information for the following general purposes to
                  fulfil your requests for services, improve our services,
                  contact You, conduct research, and provide reporting for
                  internal and external clients. You agree that we may transfer
                  your Personal Data for the general purposes set out above
                  within TalentMarks to any group company and to the Membership
                  Organization. We reserve the right to send You certain
                  communications, such as service announcements and
                  administrative messages and other communications relating to
                  our Services, without offering You the opportunity to opt out
                  of receiving them. Your rights to opt-out of other types of
                  communications will be unaffected.
                </p>

                <p className="pb-4">
                  Where You have provided your explicit consent, your name,
                  email address and contact number may be used to provide You,
                  or permit selected third parties to provide You with
                  information about services we feel may interest You (You may
                  unsubscribe from this at any time by updating your
                  Notification and Privacy settings within the Site or &nbsp;
                  <span className="font-semibold">
                    emailing Us at:
                    <a href="#" className="hover:text-[#FBB522]">
                      {" "}
                      info@MySocialHeadshots.com
                    </a>
                  </span>
                </p>

                <p className="pb-4 font-semibold text-xl">
                  2. Sharing your Personal Data
                </p>

                <p className="pb-4">
                  The Personal Data You provide Us will be displayed to other
                  users of the platform, including your email address, unless
                  You choose to hide your Personal Data from other users. We do
                  not rent or sell Personal Data about You with other people or
                  non-affiliated third parties. We may share Personal Data about
                  You with non-affiliated third parties to provide products or
                  services You have expressly requested or when we have your
                  explicit consent, or under the following circumstances:
                </p>

                <p className="pb-4">
                  Responding to court orders, legal process, or to any
                  legitimate request by authorities with which we must comply;
                  and,
                </p>

                <p className="pb-4">
                  To investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, situations involving potential
                  threats to the physical safety of any person, violations of
                  our terms of use, or as otherwise required by law.
                </p>

                <p className="pb-4">
                  In reference to general Site activity, You retain the
                  copyright and any other rights You already hold in all
                  information and content which You submit, post or display on
                  or through the Member Organization Site. By using the Services
                  You grant us a nonexclusive, transferable, sub-licensable,
                  royalty-free, worldwide licence to use such information and
                  content that You post on the Site for the purposes set out in
                  paragraph 2 above, and for the purpose of enabling us to
                  provide the Services through the Site. Such a licence does not
                  include permission for us to commercialise (for our own
                  purposes) the information and content You have posted. This
                  licence will end in relation to certain information and
                  content that You delete from the Site when such certain
                  information and content are deleted, and will end in relation
                  to all such information and content on the Site when You
                  delete your account from the Site, unless the relevant
                  information or content have been shared with others and they
                  have not deleted such information or content.
                </p>

                <p className="pb-4">
                  For the sole purpose of the provision of the Services, You
                  authorise us to display and make available for download to
                  Users all media recordings of You which You submit to us or
                  post on the Site or through the Services.
                </p>

                <p className="pb-4">
                  You also agree that We may transfer your information to the
                  Membership Organization to use for its development and
                  advancement purposes (including in connection with the
                  Membership Organization’s work with alumni, friends, and
                  donors to support students, faculty, research, and outreach
                  initiatives at the Membership Organization).
                </p>

                <p className="pb-4 font-semibold text-xl">Cookies</p>

                <p className="pb-4">
                  We reserve the right to collect technical data about the type
                  of browser software or operating system You are using for the
                  purposes of tracking website use, or improving the services
                  offered through the Site. This information will not be used to
                  identify You personally. We may also place a ‘cookie’ on your
                  browser to provide us with information about your use of the
                  Site, and to help us identify You when You return to that Site
                  so that your preferences can be stored. Using cookies helps us
                  to improve our Site and to deliver a better and more
                  personalised service. We may also use cookies to identify
                  repeat visitors.
                </p>

                <p className="pb-4">
                  You can remove cookies from your computer at any time, and
                  choose to disable cookies in your internet browser settings.
                  Without cookies enabled, we cannot guarantee that the Site and
                  your experience of it are as we intended it to be.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  The length of time a cookie stays on your device depends on
                  its type. We use two types of cookies on the Website, as
                  follows:
                </p>

                <ul className="pb-4 list-disc pl-5 space-y-3">
                  <li>
                    Session cookies are temporary cookies which only exist
                    during the time You use a Site (or more strictly, until You
                    close the browser after using the Site). Session cookies
                    help the Site remember what You chose on the previous page,
                    avoiding the need to re-enter information.
                  </li>
                  <li>
                    Persistent cookies stay on your device after You have
                    visited the Site. For example, when You log onto a Site, a
                    persistent cookie will be used to remember your preferences,
                    so that the system remembers your choice the next time You
                    log in. Persistent cookies help us to identify You as a
                    unique visitor but do not contain any information that could
                    be used to identify You to another person.
                  </li>
                  <li>
                    We also use web analytics services from other companies to
                    track how visitors reach our Site and the path they take
                    through it. These companies use cookies to help us improve
                    our service to You.
                  </li>
                  <li>
                    Our native cookies are Strictly Necessary and Functional in
                    nature. Third party cookies within external feeds on the
                    Site form partners such as Twitter, Facebook, YouTube and
                    Microsoft are Functional in nature except for the following
                    which are Marketing in nature:
                  </li>
                </ul>

                {/* Table Start */}

                <table class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                  <thead class="text-white">
                    <tr class="bg-[#FBB422] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                      <th class="p-3 text-left">Name</th>
                      <th class="p-3 text-left">Type</th>
                      <th class="p-3 text-left">Purpose</th>
                      <th class="p-3 text-left">Native / 3rd Party</th>
                      <th class="p-3 text-left">Persistent / Session</th>
                      <th class="p-3 text-left">Third Party Source</th>
                    </tr>

                    <tr class="bg-[#FBB422] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                      <th class="p-3 text-left">Name</th>
                      <th class="p-3 text-left">Type</th>
                      <th class="p-3 text-left">Purpose</th>
                      <th class="p-3 text-left">Native / 3rd Party</th>
                      <th class="p-3 text-left">Persistent / Session</th>
                      <th class="p-3 text-left">Third Party Source</th>
                    </tr>

                    <tr class="bg-[#FBB422] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                      <th class="p-3 text-left">Name</th>
                      <th class="p-3 text-left">Type</th>
                      <th class="p-3 text-left">Purpose</th>
                      <th class="p-3 text-left">Native / 3rd Party</th>
                      <th class="p-3 text-left">Persistent / Session</th>
                      <th class="p-3 text-left">Third Party Source</th>
                    </tr>

                    <tr class="bg-[#FBB422] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                      <th class="p-3 text-left">Name</th>
                      <th class="p-3 text-left">Type</th>
                      <th class="p-3 text-left">Purpose</th>
                      <th class="p-3 text-left">Native / 3rd Party</th>
                      <th class="p-3 text-left">Persistent / Session</th>
                      <th class="p-3 text-left">Third Party Source</th>
                    </tr>
                  </thead>
                  <tbody class="flex-1 sm:flex-none">
                    <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        _fbp
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                        Cookie
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Marketing
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        3rd party
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Persistent
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        facebook.com
                      </td>
                    </tr>

                    <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        _gcl_au
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                        Cookie
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Marketing
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        3rd party
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Persistent
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        google.com
                      </td>
                    </tr>

                    <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        _uetvid
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                        Cookie
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Marketing
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        3rd party
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Persistent
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        microsoft.com
                      </td>
                    </tr>

                    <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        _uetsid
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                        Cookie
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Marketing
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        3rd party
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        Persistent
                      </td>
                      <td class="border-grey-light border hover:bg-gray-100 p-3">
                        microsoft.com
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="pb-4 font-semibold text-xl">
                  Edit and Delete your Information and Preferences
                </p>

                <ul className="pb-4 list-disc pl-5 space-y-3">
                  <li>
                    You can edit your Personal Data on your account at any time.
                  </li>
                  <li>
                    You can re-sync your account with your Linkedin Account at
                    any time.
                  </li>
                  <li>
                    We may restrict any changes being made to any report
                    produced about You, if we are of the opinion that such
                    report is not a fair and true representation of You.
                  </li>
                  <li>
                    We will endeavor to correct any material errors in your
                    Personal Data that You make us aware of.
                  </li>
                  <li>
                    You may delete your account through the Settings function
                    within the Site, by opening a support ticket through the
                    Site or by emailing us at info@mysocialheadshots.com,
                    verifying your password once more and confirming your choice
                    to terminate the account. We may, in accordance with
                    applicable law, however, retain certain aspects of your
                    account and other Personal Data for the purposes of
                    maintaining records of our dealings with You, analysis
                    and/or statistics.
                  </li>
                </ul>

                <p className="pb-4 font-semibold text-xl">Please note that:</p>

                <ul className="pb-4 list-disc pl-5 space-y-3">
                  <li>
                    Information that You have shared with others and that others
                    have shared about You is not part of your account and will
                    not be deleted when You delete your account or request that
                    information about You be deleted; and
                  </li>
                  <li>
                    It may take some time to delete data stored in backup
                    systems.
                  </li>
                </ul>

                <p className="pb-4 font-semibold text-xl">
                  Confidentiality and Security
                </p>

                <p className="pb-4">
                  Your Personal Data is stored in electronic and physical
                  records maintained by Us and / or our service providers. The
                  security of all personally identifiable information associated
                  with You is taken very seriously and all Personal Data
                  supplied is treated confidentially. We therefore have several
                  security measures in place to try to protect against the loss,
                  unauthorised use and corruption of any Personal Data that is
                  under our control. We review our security and privacy policies
                  regularly and improve them where it is reasonably possible to
                  do so. We do not guarantee that loss, unauthorized use,
                  corruption and/or alteration of information will never occur,
                  but we use all reasonable efforts to prevent it. Please note
                  that the input of data over the internet is never fully
                  secure. We cannot, therefore, guarantee the security of data
                  You submit via the Site in transit over the internet and any
                  such submission is at entirely your own risk. Please ensure
                  that You never leave your device logged on or use the Services
                  in a public place where others can potentially see your
                  details.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Posting on Publicly Accessible Areas
                </p>

                <p className="pb-4">
                  A publicly accessible area is any area of the Site and/or
                  Services that is accessible by the general public and may be
                  indexed by the search engines. We may offer You the
                  opportunity to post, submit and exchange content and
                  information on publicly accessible areas and You should
                  exercise caution if You choose to disclose Personal Data in a
                  publicly accessible area. Remember that this information may
                  remain indefinitely in the publicly accessible area of the
                  Site and Services. You are solely responsible for any Personal
                  Data that You disclose and disseminate on Site and through the
                  Services. We shall not be held liable for any loss of
                  information. Any information You submit to Us is at your own
                  risk of loss.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  User-to-User Communication and Sharing
                </p>

                <p className="pb-4">
                  You are solely responsible for your interactions with other
                  users. We reserve the right, but have no obligation, to
                  monitor disputes between You and other users and to restrict,
                  suspend, or close your account if we determine, in our sole
                  discretion, that doing so is necessary to enforce this Privacy
                  Policy. We cannot guarantee that other users will not use the
                  ideas and information that You share to and/or through our
                  Services. Therefore, if You have an idea or information that
                  You would like to keep confidential or do not want others to
                  use, or that is subject to third party rights that may be
                  infringed by your sharing it, do not post it to and/or through
                  our Services. We are not responsible for another’s misuse or
                  misappropriation of any content or information You post.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Transfer of personal data outside the EEA
                </p>

                <p className="pb-4">
                  Whilst we are based in the UK, we and/or our service providers
                  may have servers located outside the European Economic Area
                  (“EEA”), where the laws may not give the same level of
                  protection to personal data as within the UK. Notwithstanding,
                  we confirm that any transfer of personal data of EEA data
                  subjects outside of the EEA, will be undertaken only using a
                  method specifically permitted under UK and European Union law.
                  By submitting your personal data, You agree to allow your
                  personal data to be transferred, processed and stored overseas
                  as set out in this paragraph. Data that we collect from You
                  may also be processed by persons operating outside the EEA who
                  work for us or for one of our suppliers. Such persons may be
                  engaged in, among other things, the provision of certain
                  services which allow us to provide the Services to You. By
                  submitting your personal data, You agree to this transfer,
                  storing or processing. We will take all steps reasonably
                  necessary to ensure that your data is treated securely and in
                  accordance with this Privacy Policy, the DPA 2018 and GDPR.
                </p>

                <p className="pb-4 font-semibold text-xl">External Links</p>

                <p className="pb-4">
                  Although we only look to include quality, safe and relevant
                  external links to our Site, users should always adopt a policy
                  of caution before clicking any external web links mentioned
                  throughout a Site. If You follow a link to any external web
                  links mentioned throughout a Site, please note that these
                  external websites have their own privacy policies and that we
                  do not accept any responsibility or liability for these
                  policies. Please check these policies before You submit any
                  Personal Data to these websites.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Social Media Platforms
                </p>

                <p>
                  Communication, engagement and actions taken through external
                  social media platforms on which We participate are subject to
                  the terms and conditions as well as the privacy policies of
                  those social media platforms. The Site may use social sharing
                  buttons which help share web content directly from our web
                  pages to the social media platform in question. Where You use
                  such social sharing buttons You do so at your own discretion –
                  You should note that the social media platform may track and
                  save your request to share a web page respectively through
                  your social media platform account. Please note these social
                  media platforms have their own privacy policies, and We do not
                  accept any responsibility or liability for these policies.
                  Please check these policies before You submit any Personal
                  Data to these social media platforms. Please note that any
                  functionality included as part of the Services that is powered
                  by YouTube (a Google company) is also governed by the Terms of
                  Use of YouTube, which can be found here
                </p>
                <p>
                  <span className="font-semibold">
                    <a
                      href="https://www.Youtube.com/t/terms"
                      target="_blanck"
                      className="hover:text-[#FBB522] text-[#4285f4]"
                    >
                      https://www.Youtube.com/t/terms
                    </a>
                    &nbsp;Google’s Privacy Policy can be found here
                  </span>
                </p>
                <p className="pb-4">
                  <span className="font-semibold">
                    <a
                      href="http://www.google.com/policies/privacy."
                      target="_blanck"
                      className="hover:text-[#FBB522] text-[#4285f4]"
                    >
                      http://www.google.com/policies/privacy.
                    </a>
                  </span>
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Shortened Links in Social Media
                </p>

                <p className="pb-4">
                  We may through our social media platform accounts share web
                  links to relevant web pages. By default, some social media
                  platforms shorten lengthy URLs. Users are advised to take
                  caution and exercise good judgement before clicking any
                  shortened URLs published on social media platforms by us.
                  Despite the best efforts to ensure only genuine URLs are
                  published, many social media platforms are prone to spam and
                  hacking and therefore we cannot be held liable for any damages
                  or implications caused by visiting any shortened links.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Access to your Personal Data
                </p>

                <p className="pb-4">
                  If You would like to access a copy of any Personal Data which
                  we hold about You, or enquire about your other rights under
                  the terms of this Privacy Policy or applicable law, please
                  send a request by opening a support ticket through the Site or
                  by &nbsp;
                  <span className="font-semibold">
                    emailing Us at:
                    <a href="#" className="hover:text-[#FBB522]">
                      {" "}
                      info@MySocialHeadshots.com
                    </a>
                  </span>
                </p>

                <p className="pb-4 font-semibold text-xl">
                  Changes to this Privacy Policy
                </p>

                <p className="pb-4">
                  We may amend this policy from time to time. If we make any
                  substantial changes in the way that we use your Personal Data
                  We will notify You by sending a notice to the primary email
                  address specified in your account or by posting a prominent
                  notice on our Site. However, You should review this Privacy
                  Policy on a regular basis to check for any changes made to it.
                </p>

                <p className="pb-4 font-semibold text-xl">Contact</p>

                <p className="pb-4">
                  Questions, comments and requests regarding this Privacy Policy
                  are welcomed and should be addressed to:
                  <span className="font-semibold">
                    <a href="#" className="hover:text-[#FBB522]">
                      {" "}
                      info@mysocialheadshots.com
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Privacy;
