import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function ForgotPassword() {
  const [emailDetails, setemailDetails] = useState({});
  const navigate = useNavigate();
  const handleDetails = (e) => {
    setloginError({
      status: false,
      message: "",
    });
    setemailDetails({ ...emailDetails, [e.target.id]: e.target.value });
  };
  const [loggingIn, setloggingIn] = useState(false);
  const [loginError, setloginError] = useState({
    status: false,
    message: "",
  });
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const postEmailDetails = async () => {
    setloggingIn(true);
    setloginError({
      status: false,
      message: "",
    });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailDetails?.email,
          university_slug: JSON.parse(localStorage.getItem("university_data"))?.universitySlug
        },
      });

      // console.log(response, "response");
      if (response?.status == 200) {
        setloggingIn(false);
        navigate("/forgot_password_otp",{state:emailDetails?.email});
      }
    } catch (error) {

      setloggingIn(false);
      setloginError({
        status: true,
        message: error?.response?.data?.message,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_token_headShots")) {
      navigate("/myheadshot");
    }
  }, []);
  // console.log(emailDetails, "email details");
  return (
    <>
      <div className="drop-header w-full">
        <Navbar expand="lg" className="px-12 w-full">
          <Container className="w-full">
            <Navbar.Brand
              className="cursor-pointer br-right"
              onClick={() => {
                localStorage.removeItem("user_email_headShots");
                localStorage.removeItem("user_token_headShots");
                localStorage.removeItem("university_data");
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src="images/logo.png" alt="logo"></img>
            </Navbar.Brand>

            <div className="">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${
                  JSON.parse(localStorage.getItem("university_data"))?.logo
                }`}
                width={50}
                height={50}
                alt="..."
              />
            </div>
            <div className="px-2 fw-600">
              {
                JSON.parse(localStorage.getItem("university_data"))
                  ?.universityName
              }
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto flex flex-row justify-end mr-0 pr-0">
                <div className="flex flex-row justify-end ms-auto log-singbtn ">
                  <Nav.Link
                    className="fs-20 fw-600 me-4"
                    onClick={() => {
                      navigate("/login");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Log In
                  </Nav.Link>
                  <Nav.Link
                    className="fs-20 fw-600 cursor-pointer"
                    onClick={() => {
                      navigate("/sign_up");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Sign Up
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="hero-box">
        <img src="images/home-banner.png" className="hero-poster" alt="..." />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center txt-brown">
              <div className="login-form">
                <h2 className="fs-22 fw-700 text-center pb-4">
                  Please enter your email to receive a Verification code
                </h2>
                <div className="mb-3">
                  <label className="form-label fw-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    required
                    class="form-control"
                    placeholder="Enter your email"
                    value={emailDetails?.email}
                    onChange={handleDetails}
                  />
                </div>
                {loginError?.status && (
                  <div className="m-1 text-danger text-center">
                    {loginError?.message}
                  </div>
                )}
                <div >
                  <button
                    type="button"
                    className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    disabled={loggingIn}
                    onClick={() => {
                      if (
                        emailDetails.email == "" ||
                        !validateEmail(emailDetails.email)
                      ) {
                        setloginError({
                          status: true,
                          message: "Please enter valid email address",
                        });
                        return;
                      } else {
                        postEmailDetails();
                      }
                    }}
                  >
                  {loggingIn ? <Loader /> : "SEND OTP"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
