import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Linkedin_profile() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            PHOTO (SELFIE) TIPS
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <ol className="pb-4 pl-5 list-decimal space-y-3">
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://passport-photo.online/blog/linkedin-picture-hireability/"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Here’s how a LinkedIn headshot affects your hireability!
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.snappr.com/photo-analyzer/"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Get your photo reviewed!
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.tryitonai.com/examples"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Tips in taking self portraits and selfie headshots.
                      </a>
                      &nbsp;
                    </p>
                  </li>

                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.linkedin.com/business/talent/blog/product-tips/tips-for-taking-professional-linkedin-profile-pictures"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Ten tips for taking a professional LinkedIn profile
                        photo.
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.widsmob.com/articles/linkedin-profile-picture.html"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        How to make brillian headshots for LinkedIn!
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.fotor.com/"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Online photo editor for everyone.
                      </a>
                      &nbsp;
                    </p>
                  </li>

                  <li>
                    <span className="font-semibold flex break-all">
                      <a
                        href="https://www.facetuneapp.com/"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Facetune the way you want to.
                      </a>
                    </span>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.studioshot.ai/?utm_source=google&utm_medium=cpc&utm_campaign=google-searchads&utm_term=online%20headshot&gclid=Cj0KCQiAi8KfBhCuARIsADp-A550C5tk5ERZsYY0WCBScUsy2BEjdJ6FModoQtn_YIP9gGNlO6N9q0saAg6UEALw_wcB#pricing%20%20different%20background%20styles%20https://www.studioshot.ai/?utm_source=google&utm_medium=cpc&utm_campaign=google-searchads&utm_term=online"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Premium Linkedin and social headshots for professionals.
                      </a>
                      &nbsp;
                    </p>
                  </li>
                </ol>

                <p className="pb-4 font-semibold text-xl">
                  AI Generated Headshots!
                </p>

                <ul className="pb-4 list-disc pl-5 space-y-3">
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.everyme.co"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Love your new Linkedin profile picture
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://pfpmaker.com/headshot"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Professional AI generated headshots
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://secta.ai/?ref=linkedin"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        From “selfies” to professional headshots with AI&nbsp;
                      </a>
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p className="font-semibold flex break-all">
                      <a
                        href="https://www.retrato.app/en"
                        target="_blank"
                        className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                      >
                        Magical portraits with AI&nbsp;
                      </a>
                      &nbsp;
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Linkedin_profile;
