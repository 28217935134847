import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import Harvard_header from "../components/Harvard_header";
import axios from "axios";

function Harvard() {
  const navigate = useNavigate();
  const [collageData, setcollageData] = useState({});
  const params = useParams();
  console.log(params, "params");

  const getCollageData = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/university/${params?.id}`,
        method: "GET",
      });
      if (response.status == 200) {
        // console.log(response, "response");
        localStorage.setItem(
          "university_data",
          JSON.stringify(response?.data?.university)
        );
        setcollageData(response?.data?.university);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };
  console.log(collageData, "collage data");
  useEffect(() => {
    if (localStorage.getItem("university_data")) {
      if (
        JSON.parse(localStorage.getItem("university_data"))?.universitySlug !=
        params?.id
      ) {
        localStorage.removeItem("user_email_headShots");
        localStorage.removeItem("user_token_headShots");
        localStorage.removeItem("university_data");
      }
    }
    if (params?.id) {
      getCollageData();
    }
  }, [params?.id]);
  return (
    <>
      <div className="harvard-header">
        {/* <Navbar expand="lg">
          <Container>
            <Navbar.Brand className="cursor-pointer">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${collageData?.logo}`}
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex ms-auto harvard-btn ">
              <button className="fs-19 fw-600 me-4">Log In</button>
              <button className="fs-18 fw-600 cursor-pointer">Sign Up</button>
            </div>
          </Container>
        </Navbar> */}
        <Navbar expand="lg" style={{ background: collageData?.headerColor }}>
          <Container>
            <Navbar.Brand className="cursor-pointer">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${collageData?.logo}`}
              ></img>
            </Navbar.Brand>
            <div className="color me-auto text-white fw-bold fs-20">
              LinkedIn & Social Media Headshot Enhancing Tool
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <div className="d-flex ms-auto harvard-btn ">
                  <button
                    className="fs-19 fw-600 me-4"
                    onClick={() => navigate("/login")}
                  >
                    Log In
                  </button>
                  <button
                    className="fs-18 fw-600 cursor-pointer"
                    onClick={() => navigate("/sign_up")}
                  >
                    Sign Up
                  </button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="hero-box">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${collageData?.banner}`}
          className="hero-poster"
        />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <div className="login-boxl position-relative">
                <h2
                  className="fs-50 fw-700 text-center pb-3"
                  style={{ fontSize: "75px" }}
                >
                  {collageData?.title}
                </h2>
                <p
                  className="fs-4 fw-600 text-center"
                  style={{ textAlign: "justify" }}
                >
                  {collageData?.description}
                </p>
                {/* <p className="position-relative fs-5 fw-600 text-end pt-5">
                  Brought to you by the Career Center!
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer collageData={collageData} />
    </>
  );
}

export default Harvard;
