import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import { saveAs } from "file-saver";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Myheadshot() {
  const navigate = useNavigate();
  const [myHeadShots, setmyHeadShots] = useState([]);
  const [profileDetails, setprofileDetails] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedId, setselectedId] = useState("");
  const getMyHeadshots = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/headshot`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      //   console.log(response, "headshots details");
      setmyHeadShots(response?.data?.headshots);
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDetails = async () => {
    // console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteMyHeadshots = async (id) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/headshot/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      // console.log(response, "response");
      if (response.status == 200) {
        handleClose();
        getMyHeadshots();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // saveAs(imageUrl, "image.jpg"); // Put your image url here.

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/proxy?url=` +
          encodeURIComponent(imageUrl)
      );
      // const response = await fetch(imageUrl);
      // console.log(response);

      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Splitting for name
      const name = imageUrl?.split("/");

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = name[name?.length - 1];
      link.click();

      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  useEffect(() => {
    getProfileDetails();
    getMyHeadshots();
  }, []);

  return (
    <>
      <Header />
      <section className="wrapper-box">
        <Container className="txt-brown py-4 px-16">
          <Row>
            <Col className="col-12 text-center pb-4">
              <h2 className="fw-600 fs-45 py-3">
                {" "}
                {profileDetails?.firstName} {profileDetails?.lastName} Headshots
              </h2>
              {myHeadShots?.length == 0 && (
                <div className="col-12 fw-500">
                  <p className="">
                    This is your headshot profile page. Because you just
                    registered, you do not have any saved enhanced headshots.
                    Click on Create to set up your first enhanced headshot.
                  </p>
                </div>
              )}
              {myHeadShots?.length == 0 ? (
                <div className="w-100 text-center mt-2">
                  <button
                    type="button"
                    className=" rounded-md px-5 py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={() => navigate("/create")}
                  >
                    Create
                  </button>
                </div>
              ) : (
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className=" rounded-md ms-auto py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={() => navigate("/create")}
                  >
                    Create
                  </button>
                </div>
              )}
            </Col>
          </Row>

          {myHeadShots?.length > 0 && (
            <div className="col-12 fw-500 mb-4">
              <p className="">
                Make as many variations of headshots as you’d like. After you
                have saved your enhanced headshots, simply export them to your
                phone or computer and then upload them to your LinkedIn or
                social media profiles. You can edit and change any enhanced
                headshots you’ve saved.
              </p>
            </div>
          )}
          <Row className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 pb-4">
            {myHeadShots?.map((item, index) => (
              <Col className="" key={index}>
                <div className="headprofile-box">
                  <div className="d-flex justify-content-center p-4">
                    <div className="headprofile d-flex justify-content-center align-items-center ">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${item?.photo}`}
                        className="w-100"
                        style={{
                          width: "100% !important",
                          height: "100% !important",
                          objectFit: "cover",
                        }}
                        loading="lazy"
                      ></img>
                    </div>
                  </div>
                  <div className="w-100 three-btn">
                    <button
                      type="button"
                      onClick={() => navigate(`/create/${item?.id}`)}
                    >
                      <svg className="icon fs-22">
                        <use href="#icon_edit"></use>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        handleDownload(
                          `${process.env.REACT_APP_BASE_URL}/${item?.photo}`
                        )
                      }
                    >
                      <svg className="icon fs-30">
                        <use href="#icon_cloud"></use>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setselectedId(item?.id);
                        handleShow();
                      }}
                    >
                      <svg className="icon fs-22">
                        <use href="#icon_delete"></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <p  className="text-center py-4 fs-40 fw-600 cursor-pointer">Get helpful “selfie” and portrait photo tips and in link below!</p>
        </Container>
      </section>
      <div style={{ marginTop: myHeadShots?.length == 0 && "120px" }}>
        <Footer />
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this headshot ?
          </p>
          <div className="d-flex justify-content-center ">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleClose}
              style={{ backgroundColor: "#6C757D" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "rgba(108, 117, 125, 0.8)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#6C757D";
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              style={{ backgroundColor: "#0C6EFC" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "rgba(12, 110, 252, 0.8)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0C6EFC";
              }}
              onClick={() => {
                deleteMyHeadshots(selectedId);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Myheadshot;
