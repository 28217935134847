import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import axios from "axios";
import Loader from "../components/Loader";

function Contact() {
  const [profileDetails, setprofileDetails] = useState({
    firstName: "",
    lastName: "",
    collage: "",
    grad_year: "",
    major: "",
    email: "",
    title: "",
    phone: "",
    Lead_Comments: "",
    Lead_Product_Focus: "",
  });
  const [errors, setErrors] = useState({});
  const [accessTokenData, setaccessTokenData] = useState({});
  const [savedResponse, setsavedResponse] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileDetails({ ...profileDetails, [id]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
    if (id === "email") {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    if (id === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  function validateForm() {
    let valid = true;
    let newErrors = {};

    if (profileDetails.firstName == "") {
      newErrors.firstName = "Please Enter First Name";
      valid = false;
    }

    if (profileDetails.lastName == "") {
      newErrors.lastName = "Please Enter Last Name";
      valid = false;
    }
    if (profileDetails.title == "") {
      newErrors.title = "Please Enter Title";
      valid = false;
    }
    if (profileDetails.collage == "") {
      newErrors.collage = "Please Enter College";
      valid = false;
    }
    if (profileDetails.email == "" || !validateEmail(profileDetails.email)) {
      newErrors.email = "Please Enter Valid Email";
      valid = false;
    }
    if (profileDetails.phone == "") {
      newErrors.phone = "Please Enter Phone";
      valid = false;
    }
    if (profileDetails.Lead_Comments == "") {
      newErrors.Lead_Comments =
        "Please Enter What would you like this program to accomplish for you?";
      valid = false;
    }
    if (profileDetails?.Lead_Product_Focus == "") {
      newErrors.Lead_Product_Focus = "Tell us who your are";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  }
  const generateZohoAccessToken = async () => {
    try {
      // const response = await axios.post(
      //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
      // );
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
      });
      localStorage.setItem(
        "headshot_access_token",
        response?.data?.access_token
      );
      return response?.data?.access_token;

      // console.log(response, "response");
      // setaccessTokenData(response?.data);
      // Store access_token2 in state or do something with it
    } catch (error) {
      // Handle error
    }
  };
  const submitButton = async () => {
    if (!validateForm()) {
      return;
    }
    setloading(true);
    const access_token1 = await generateZohoAccessToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/zoho/contactus`,
        {
          data: {
            First_Name: profileDetails.firstName,
            Last_Name: profileDetails.lastName,
            Company: profileDetails.collage,
            Email: profileDetails.email,
            Phone: profileDetails.phone,
            Title: profileDetails.title,
            Lead_Product_Service_Interested_In: "Contact Us Page",
            Lead_Product_Focus: profileDetails.Lead_Product_Focus,
            Lead_Comments: profileDetails?.Lead_Comments,
            access_token: access_token1,
          },
        }
      );
      // console.log(response, "response");
      if (response.status == 200) {
        setsavedResponse(true);
        setprofileDetails({
          firstName: "",
          lastName: "",
          collage: "",
          grad_year: "",
          major: "",
          email: "",
          title: "",
          phone: "",
          Lead_Comments: "",
        });
        setloading(false);
        setTimeout(() => {
          setsavedResponse(false);
        }, 2000);
        seterrorMessage("");
      }
      // Handle response
    } catch (error) {
      setloading(false);
      console.log(error, "error");
      seterrorMessage(error?.response.data.message);
      // Handle error
    }
  };

  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-70 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-contact.png"
        />
        <div className="container mx-auto px-4">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-[220px]">
              Contact Us
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-10 px-4">
          <div className="flex flex-wrap w-full ">
            <div className="w-full md:w-12/12 flex justify-center">
              <div className="w-full md:w-6/12">
                <div>
                  <p className="font-bold text-2xl">
                    We’d love to help you acheive your goals!
                  </p>
                  <p className="py-8 font-semibold">
                    Tell us who you are and we’ll send additional information on
                    how you can provide timely and relevant career and
                    professional development programming to those you support.
                  </p>
                </div>
                <select
                  name="lname"
                  className="mb-6 placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                  value={profileDetails?.Lead_Product_Focus}
                  id="Lead_Product_Focus"
                  onChange={handleChange}
                >
                  <option value="">Tell us who you are</option>
                  <option value="Student, Grad, Alum">
                    Student, Grad, Alum
                  </option>
                  <option value="Admissions, Career Center, Alumni, Athletic">
                    Admissions, Career Center, Alumni, Athletic
                  </option>
                  <option value="Non-Profit, Annual Giving, Political">
                    Non-Profit, Annual Giving, Political
                  </option>
                  <option value="Employer, Recruiter, Brands">
                    Employer, Recruiter, Brands
                  </option>
                </select>
                {errors.Lead_Product_Focus && (
                  <p className="error-message text-danger mb-3">
                    {errors.Lead_Product_Focus}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">
                    First Name
                  </span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="text"
                    name="search"
                    placeholder="First name"
                    value={profileDetails.firstName}
                    id="firstName"
                    onChange={handleChange}
                  />
                </label>
                {errors.firstName && (
                  <p className="error-message text-danger mb-3">
                    {errors.firstName}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">
                    Last Name
                  </span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="text"
                    name="search"
                    value={profileDetails?.lastName}
                    id="lastName"
                    onChange={handleChange}
                    placeholder="Last name"
                  />
                </label>
                {errors.lastName && (
                  <p className="error-message text-danger mb-3">
                    {errors.lastName}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">Title</span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="text"
                    name="search"
                    value={profileDetails?.title}
                    id="title"
                    onChange={handleChange}
                    placeholder="Enter your Title"
                  />
                </label>
                {errors.title && (
                  <p className="error-message text-danger mb-3">
                    {errors.title}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">
                    Organization
                  </span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="text"
                    name="search"
                    placeholder="Enter your Organization"
                    value={profileDetails?.collage}
                    onChange={handleChange}
                    id="collage"
                  />
                </label>
                {errors.collage && (
                  <p className="error-message text-danger mb-3">
                    {errors.collage}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">Email</span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="text"
                    name="search"
                    placeholder="Enter your Email"
                    value={profileDetails?.email}
                    onChange={handleChange}
                    onBlur={handleChange2}
                    id="email"
                  />
                </label>
                {errors.email && (
                  <p className="error-message text-danger mb-3">
                    {errors.email}
                  </p>
                )}
                <label className="block pb-6">
                  <span className="block text-lg font-medium pb-2">
                    Phone Number
                  </span>
                  <input
                    className="placeholder:text-[#C09C85] block bg-white w-full py-2 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    type="number"
                    name="search"
                    value={profileDetails?.phone}
                    id="phone"
                    onChange={handleChange}
                    placeholder="Enter your phone"
                  />
                </label>
                {errors.phone && (
                  <p className="error-message text-danger mb-3">
                    {errors.phone}
                  </p>
                )}
                <div className="pb-6">
                  <span className="block text-lg font-medium pb-2">
                    What would you like this program to accomplish for you?
                  </span>
                  <textarea
                    value={profileDetails?.Lead_Comments}
                    id="Lead_Comments"
                    onChange={handleChange}
                    className="w-full placeholder:text-[#C09C85] block bg-white w-full py-4 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium h-28"
                  ></textarea>
                </div>
                {errors.Lead_Comments && (
                  <p className="error-message text-danger mb-3">
                    {errors.Lead_Comments}
                  </p>
                )}
                {savedResponse ? (
                  <div className=" mb-1 fw-bold text-center">
                    Details Submitted Successfully!
                  </div>
                ) : (
                  <div className=" mb-1 fw-bold text-center">
                    {errorMessage}
                  </div>
                )}
                <div className="pt-6">
                  <button
                    type="button"
                    disabled={loading}
                    onClick={submitButton}
                    className="w-full font-semibold text-xl rounded-md py-3 bg-[#FBB522] hover:bg-[#dfa423] text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                  >
                    {loading ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      "GET IN TOUCH"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Contact;
