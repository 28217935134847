import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CollageDetails() {
  const [editCancel, setEditCancel] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [collageDetails, setcollageDetails] = useState([]);
  const [formFields, setformFields] = useState([]);
  const [error, seterror] = useState({
    graduationYear: false,
    graduationCollege: false,
  });
  const navigate = useNavigate();
  const updateProfileFn = async () => {
    // console.log(profileDetails);
    let graduationYear = false;
    let graduationCollege = false;
    if (
      !profileDetails?.graduationYear ||
      profileDetails?.graduationYear == ""
    ) {
      graduationYear = true;
    }
    if (
      !profileDetails?.graduationCollege ||
      profileDetails?.graduationCollege == ""
    ) {
      graduationCollege = true;
    }
    seterror({
      graduationCollege: graduationCollege,
      graduationYear: graduationYear,
    });
    if (graduationCollege || graduationYear) {
      return;
    }
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...profileDetails,
          graduationYear: parseInt(profileDetails?.graduationYear),
          token: localStorage.getItem("user_token_headShots"),
        },
      });
      if (response.status == 200) {
        // console.log(response.data, "1st page profile");
        let keys = [
          "Your LinkedIn Profile URL",
          "Who are you",
          "Parent First Name (Optional)",
          "Parent Email (Optional)",
          "Cell Phone (Optional)",
          "Address 01",
          "Address 02",
          "Street",
          "State",
          "Zip",
        ];
        // Check if any key is enabled
        const anyEnabled = keys.some(checkTheField);

        // If none of the keys are enabled, navigate to page 2; otherwise, navigate to page 1
        if (!anyEnabled) {
          // Navigate to page 2
          navigate("/myheadshot");
        } else {
          // Navigate to page 1
          navigate("/additional_information");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDetails = async () => {
    // console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getFormFields = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/formfields/${
          JSON.parse(localStorage.getItem("university_data"))?.id
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      // console.log(response,"collage details")
      setformFields(response.data.formFields);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    setprofileDetails({ ...profileDetails, [e.target.id]: e.target.value });
  };
  console.log(profileDetails, "profile details");
  const checkTheField = (key) => {
    let find = formFields?.find((item) => item?.question == key);
    if (find?.isEnabled) {
      return true;
    } else {
      return false;
    }
  };
  const getCollageDetails = async () => {
    // console.log(
    //   JSON.parse(localStorage.getItem("university_data"))?.id,
    //   "university id"
    // );
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/collages`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
          universityid: JSON.parse(localStorage.getItem("university_data"))?.id,
        },
      });
      // console.log(response,"collage details")
      setcollageDetails(response.data.collages);
    } catch (error) {
      console.log(error);
    }
  };
  const getLabel = (key) => {
    let universityData = JSON.parse(localStorage.getItem("university_data"));
    let orgnizationType = universityData?.orgnizationType;
    // let orgnizationType = 2;
    console.log(orgnizationType, "orgnizationType");
    switch (orgnizationType) {
      case 0:
        if (key === "Year Associated with") {
          return "Graduation Year";
        } else {
          return "College Graduating From";
        }
      case 1:
        if (key === "Year Associated with") {
          return "Member Since";
        } else {
          return "Currently";
        }
      case 2:
        if (key === "Year Associated with") {
          return "Conference Year";
        } else {
          return "Conference Participating In";
        }
      case 3:
        if (key === "Year Associated with") {
          return "Year Participating";
        } else {
          return "Participating In";
        }
      case 4:
        if (key === "Year Associated with") {
          return "Member Since";
        } else {
          return "Associated With";
        }
      case 5:
        if (key === "Year Associated with") {
          return "Participation Year";
        } else {
          return "Participating With";
        }
      case 6:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Department Associated With";
        }
      case 7:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Relationship With Employer";
        }
      case 8:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Current Job Search Status";
        }
      case 9:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Current Job Search Status";
        }
      default:
        if (key === "Year Associated with") {
          return "";
        } else {
          return "";
        }
    }
  };
  

  const getTitle = () => {
    let universityData = JSON.parse(localStorage.getItem("university_data"));
    let orgnizationType = universityData?.orgnizationType;
    // if (orgnizationType == 0) {
    //   return "College Details";
    // } else {
    //   return "Organization Details";
    // }
    switch (orgnizationType) {
      case 0:
        return "College/University Details";
      case 1:
        return "Association Details";
      case 2:
        return "Conference Detail";
      case 3:
        return "Event Details";
      case 4:
      case 5:
        return "Organization Details";
      case 6:
      case 7:
        return "Employer Details";
      case 8:
        return "Staffing Agency Details";
      case 9:
        return "Job Board";
      default:
        return "";
    }
    
  };
  const yearsToDisplay = 10;
  const currentYear = new Date().getFullYear();

  const graduationYears = [];
  for (let i = 0; i <= yearsToDisplay; i++) {
    const year = currentYear + i;
    graduationYears.push(year);
  }

  // console.log(profileDetails, "profile details");
  useEffect(() => {
    getProfileDetails();
    getCollageDetails();
    getFormFields();
  }, []);

  return (
    <>
      <Header />
      <Container className="txt-brown py-4 px-14">
        <Row>
          <Col className="col-12 pb-4">
            <h2 className="fw-600 fs-30">{getTitle()}</h2>
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            {formFields?.length > 0 && (
              <div className="row pb-4">
                <div className="col-sm-12 pt-4">
                  {checkTheField("Year Associated with") && (
                    <>
                      <div className={error?.graduationYear ? "pb-1" : "pb-4"}>
                        <label htmlFor="graduate" className="form-label fw-600">
                          {getLabel("Year Associated with")}
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={profileDetails?.graduationYear}
                          id="graduationYear"
                          onChange={handleChange}
                        >
                          <option value="">
                            Select 
                          </option>
                          {graduationYears.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                      {error?.graduationYear && (
                        <div className="pb-2 text-danger fs-16">
                          Please select Graduation year
                        </div>
                      )}
                    </>
                  )}
                  {checkTheField("Department Associated with") && (
                    <>
                      <div
                        className={error?.graduationCollege ? "pb-1" : "pb-4"}
                      >
                        <label htmlFor="graduate" className="form-label fw-600">
                          {getLabel("Department Associated with")}
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={profileDetails?.graduationCollege}
                          id="graduationCollege"
                          onChange={handleChange}
                        >
                          <option value="">
                            Select 
                          </option>
                          {collageDetails?.map((item, index) => (
                            <option value={item?.id}>
                              {item?.collageName}
                            </option>
                          ))}
                        </select>
                      </div>
                      {error?.graduationCollege && (
                        <div className="pb-2 text-danger fs-16">
                          Please select Graduation collage
                        </div>
                      )}
                    </>
                  )}
                  {checkTheField("Major") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Major</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your major"
                        value={profileDetails?.major}
                        id="major"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="pb-4 d-flex justify-content-between">
                    <div>
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2 px-4"
                      onClick={() => navigate("/basic_details")}
                    >
                      Prev
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={() => navigate("/basic_details")}
                      >
                        Prev
                      </button>
                    </div>
                    <div>
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2 px-4"
                      onClick={updateProfileFn}
                    >
                      Next
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={updateProfileFn}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default CollageDetails;
