import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer2 from "../components/Footer2";
import axios from "axios";

function Home2() {
  const navigate = useNavigate();
  const responsive = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const state = searchParams.get("state");
    // console.log(state, "state");
    if (token && email && state) {
      localStorage.setItem("user_email_headShots", email);
      localStorage.setItem("user_token_headShots", token);
      if (state == 1) {
        navigate("/basic_details");
      } else {
        navigate("/myheadshot");
      }
    }
  }, []);

  return (
    <>
      <Header />
      <div className="main-hero relative w-full">
        <img
          className="left-img left-0 bottom-0 h-full absolute w-44"
          src="images/left-side.png"
        />
        <img
          className="right-img right-0 bottom-0 h-full absolute w-44"
          src="images/right-side.png"
        />
        <div className="container mx-auto flex items-center h-full px-4">
          <div className="w-full h-full py-10 z-10">
            <div className="flex flex-wrap text-center">
              <div className="w-1/2 md:w-1/5 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-1.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/5 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-2.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/5 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-3.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/5 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-4.png" />
                </div>
              </div>
              <div className="w-full md:w-1/5 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-5.png" />
                </div>
              </div>
            </div>
            <div className="text-center text-[#4E2A14] py-8">
              <p className="text-6xl font-bold pb-4">MySocial Headshots</p>
              <i className="font-semibold text-2xl">
                We help people, brands, and organizations get noticed!
              </i>
            </div>
            <div className="flex flex-wrap text-center md:px-36">
              <div className="w-1/2 md:w-1/4 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-6.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-7.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-8.png" />
                </div>
              </div>
              <div className="w-1/2 md:w-1/4 p-2 flex justify-center">
                <div className="w-40 h-40 rounded-full">
                  <img className="w-full object-cover" src="images/img-9.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-5">
        <div className="pb-4">
          {/* <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
            Sample Usage of Headshots
          </p> */}
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
        </div>
        <div className="container mx-auto px-4 social-slider mt-2">
          <Slider {...settings}>
            <div className="slider-box">
              <img src="images/social-slider/facebook.png" alt="..." />
            </div>
            <div className="slider-box">
              <img src="images/social-slider/twitter.png" alt="..." />
            </div>
            <div className="slider-box">
              <img src="images/social-slider/linkedin.png" alt="..." />
            </div>
            <div className="slider-box">
              <img src="images/social-slider/tiktok.png" alt="..." />
            </div>
            <div className="slider-box">
              <img src="images/social-slider/instagram.png" alt="..." />
            </div>
          </Slider>
        </div>
      </div>

      <div className="bg-[#FFF6E4]">
        <div className="container mx-auto py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
            What we do!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-8/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl p-2">
                <p>
                  MySocial Headshots offers colleges, non-profits, fundraisers,
                  political organizations, and college recruiters the ability to
                  give students, grads, alumni, supporters, and contributors a
                  program where they can enhance their online presence by adding
                  branding statements as well as logos, emblems, and mascots to
                  their LinkedIn, Facebook, Twitter, Instagram, Tiktok, and
                  other social media profile headshots.
                </p>
                <p className="pt-6">
                  MySocial Headshots offers a fun way to elevate everyone’s
                  social media presence and at the same time increasing brand
                  awareness and the opportunity to catch a viral messaging wave.
                </p>
              </div>
            </div>
            <div className="w-full md:w-4/12 p-2 flex justify-center">
              <div className="w-72">
                <img src="images/circule-img.png" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 px-4">
        <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
          Everyone benefits!
        </p>
        <div className="flex justify-center">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
        <div className="mt-8 text-[#4E2A14] p-2">
          <ul className="list-decimal divide-y divide-slate-200 text-xl font-normal pl-5">
            <li className="py-6">
              <span className="underline underline-offset-1">
                Students, grads,
              </span>{" "}
              and <span className="underline underline-offset-1">alumni</span>{" "}
              get internships and jobs faster with less efforts.
            </li>
            <li className="py-6">
            <span className="underline underline-offset-1">
            Admissions offices 
              </span>{" "}
              give prospective students statements to show
              their excitement and pride at applying and getting accepted
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">
                Career centers
              </span>{" "}
              get students engaged earlier in their college experience.
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">
                Alumni associations
              </span>{" "}
              increase event participation, mentoring, and alumni satisfaction.
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">
                Annual giving, non-profits,
              </span>{" "}
              and{" "}
              <span className="underline underline-offset-1">
                fundraising organizations
              </span>{" "}
              reach more contributors and increase contributions while giving
              contributors online recognition.
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">
                Athletic departments
              </span>{" "}
              give student-athletes,students,alumni,and fans the ability to show
              their zeal,pride,enthusiasm,and support for their favorite sports
              and teams!
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">
                College recruiters
              </span>{" "}
              adopt a unique onboarding strategy that increases retention and
              loyalty.
            </li>
            <li className="py-6">
              <span className="underline underline-offset-1">Brand</span> can
              tap into cosumer's social media channels and leverage their
              influence to start no-cost organic viral marketing campaigns!
            </li>
          </ul>
          <p className="text-xl font-normal pt-8">
            Don't miss out on the opportunity to harness the power of viral
            marketing with our proven and innovative branding strategy. With our
            help, you can create engaging events that will captivate your
            audience and drive massive online engagement.{" "}
            <strong>Find out more now!</strong>
          </p>
        </div>
      </div>

      <div className="bg-[#FCFCFC] py-16">
        <div className="container mx-auto px-4">
          <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
          Tell us who you are or what department you represent!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap text-centerl pt-10 text-[#4E2A14] p-2">
            <div className="w-1/2 md:w-3/12 p-2 flex justify-center">
              <a
                className="hover:text-[#FBB522] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/alumni");
                  window.scrollTo(0, 0);
                }}
              >
                <div className="">
                  <div className="w-full max-w-[224px] h-full max-h-[224px] rounded-full">
                    <img
                      className="w-full h-full object-cover"
                      src="images/h-1.png"
                    />
                  </div>
                  <div className="w-full max-w-[224pxl] flex break-all justify-center text-2xl font-semibold py-4">
                    <ul className="list-disc">
                      <li>Student</li>
                      <li>Grad</li>
                      <li>Alumni</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-1/2 md:w-3/12 p-2 flex justify-center">
              <a
                className="hover:text-[#FBB522] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/problem");
                  window.scrollTo(0, 0);
                }}
              >
                <div>
                  <div className="w-full max-w-[224px] h-full max-h-[224px] rounded-full">
                    <img
                      className="w-full h-full object-cover"
                      src="images/h-2.png"
                    />
                  </div>
                  <div className="w-full max-w-[224pxl] flex break-all justify-center text-2xl font-semibold py-4">
                    <ul className="list-disc">
                      <li>Admission Office</li>
                      <li>Alumni Association</li>
                      <li>Career Center</li>
                      <li>Athletic Department</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-1/2 md:w-3/12 p-2 flex justify-center">
              <a
                className="hover:text-[#FBB522] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/annual");
                  window.scrollTo(0, 0);
                }}
              >
                <div>
                  <div className="w-full max-w-[224px] h-full max-h-[224px] rounded-full">
                    <img
                      className="w-full h-full object-cover"
                      src="images/h-3.png"
                    />
                  </div>
                  <div className="w-full max-w-[224pxl] flex break-all justify-center text-2xl font-semibold py-4">
                    <ul className="list-disc">
                      <li>Annual Giving</li>
                      <li>Non-Profit</li>
                      <li>Political Group</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-1/2 md:w-3/12 p-2 flex justify-center">
              <a
                className="hover:text-[#FBB522] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/recruiter");
                  window.scrollTo(0, 0);
                }}
              >
                <div>
                  <div className="w-full max-w-[224px] h-full max-h-[224px] rounded-full">
                    <img
                      className="w-full h-full object-cover"
                      src="images/h-4.png"
                    />
                  </div>
                  <div className="w-full max-w-[224pxl] flex break-all justify-center text-2xl font-semibold py-4">
                    <ul className="list-disc">
                      <li>College Recruiter</li>
                      <li>Hiring Manager</li>
                      <li>Brand Manager</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="brand-slider">
        <div className="container mx-auto py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
            Partnered Solution
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="responsive pt-10">
            <Slider {...responsive}>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-1.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-2.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-3.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-4.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-5.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-6.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-7.png" />
                </div>
              </div>
              <div className="brand-box">
                <div className="brand-img">
                  <img className="w-full" src="images/partner-4.png" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Home2;
