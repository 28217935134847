import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function WhoWeAre() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full pt-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">What we do!</p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <p className="pb-4">
                  MySocial Headshots is one of a family of products and services
                  provided by TalentMarks designed to help higher education
                  departments engage their constituents. We help:
                </p>
                <div className="pb-4">
                  <ul className="list-disc pl-5 space-y-3 ">
                    <li>
                      Admissions offices increase enrollment using organic viral
                      marketing campaigns that leverage prospective student’s
                      influence on their social media channels. We also help
                      admissions professionals engage parents who are primary
                      influences of college choice.
                    </li>
                    <li>
                      First-Year Experience offices help students transition
                      from high school to college by providing on demand health,
                      well-being, relationship, and study best practices
                      programing. These programs are increase student’s
                      confidence and retention which of course increase revenue!
                    </li>
                    <li>
                      Career centers get students to take ownership of their
                      careers earlier in their college experience, visit the
                      career center frequently, and prepare them for their first
                      professional job search. This increases alumni
                      satisfaction, which increases enrollment!
                    </li>
                    <li>
                      Alumni associations connect and engage alumni by providing
                      online career and professional development programming to
                      support graduates as they are launching their careers to
                      alumni who are preparing to retire. These career
                      engagement programs are designed to increase alumni
                      support of mentoring and scholarship programs.
                    </li>
                    <li>
                      Annual giving programs by helping graduates and alumni
                      understand the connection between their alma mater’s
                      career and professional development support and
                      contributions.
                    </li>
                  </ul>
                </div>
                <p className="pb-4">
                  Outside of higher education our products and services can be
                  used to provide career and professional development
                  programming to members of veteran organizations, job boards,
                  national associations, national fraternities/sororities,
                  brands, sports teams, entertainment venues, clubs, and college
                  recruiters/employers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex items-center h-full px-4">
        <div className="w-full h-full pb-10 z-10">
          <div className="flex flex-wrap text-center">
            <div className="w-1/2 md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/talent_marks.png"
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/collage_career.png"
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/my_alumni.png"
                />
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/career.png"
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/grad.png"
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 p-2 flex justify-center items-center">
              <div className="w-40 h-40 flex justify-center items-center">
                <img
                  className="w-full object-cover"
                  src="images/brand/my_social.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default WhoWeAre;
