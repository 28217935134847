import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AdditionalInformation() {
  const [editCancel, setEditCancel] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [formFields, setformFields] = useState([]);
  const navigate = useNavigate();
  const updateProfileFn = async () => {
    console.log(profileDetails);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...profileDetails,
          phone: parseInt(profileDetails?.phone),
          zip: parseInt(profileDetails?.zip),
          token: localStorage.getItem("user_token_headShots"),
        },
      });
      if (response.status == 200) {
        navigate("/myheadshot");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDetails = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getFormFields = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/formfields/${
          JSON.parse(localStorage.getItem("university_data"))?.id
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      // console.log(response,"collage details")
      setformFields(response.data.formFields);
    } catch (error) {
      console.log(error);
    }
  };

  const checkTheField = (key) => {
    let find = formFields?.find((item) => item?.question == key);
    if (find?.isEnabled) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    setprofileDetails({ ...profileDetails, [e.target.id]: e.target.value });
  };
  console.log(profileDetails, "profile details");
  useEffect(() => {
    getProfileDetails();
    getFormFields();
  }, []);
  return (
    <>
      <Header />
      <Container className="txt-brown py-4 px-14">
        <Row>
          <Col className="col-12 pb-4">
            <h2 className="fw-600 fs-30">Tell us more about yourself.</h2>
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            {formFields?.length > 0 && (
              <div className="row pb-4">
                <div className="col-sm-12 pt-4">
                  {checkTheField("Your LinkedIn Profile URL") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Your LinkedIn Profile URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your linkedin URL"
                        value={profileDetails?.linkedIn}
                        id="linkedIn"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Who are you") && (
                    <div className="pb-4">
                      <label htmlFor="graduate" className="form-label fw-600">
                        Who are you
                      </label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={profileDetails?.occupation}
                        id="occupation"
                        onChange={handleChange}
                      >
                        <option selected>Select who are you</option>
                        <option value="Prospective Student">
                          Prospective Student
                        </option>
                        <option value="Student">Student</option>
                        <option value="Student-Athlete">Student-Athlete</option>
                        <option value="Grad">Grad</option>
                        <option value="Alumni">Alumni</option>
                        <option value="Contributor">Contributor</option>
                        <option value="Parent">Parent</option>
                      </select>
                    </div>
                  )}

                  {checkTheField("Parent First Name (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Parent First Name (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your parent name"
                        value={profileDetails?.parentFirstName}
                        id="parentFirstName"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Parent Email (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Parent Email (Optional)
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your parent email"
                        value={profileDetails?.parentEmail}
                        id="parentEmail"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Cell Phone (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Cell Phone (Optional)
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter your cell phone"
                        value={profileDetails?.phone}
                        id="phone"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Address 01") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Address 01</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your address 01"
                        value={profileDetails?.address1}
                        id="address1"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Address 02") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Address 02</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your address 02"
                        value={profileDetails?.address2}
                        id="address2"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Street") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Street</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your street"
                        value={profileDetails?.street}
                        id="street"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("State") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your state"
                        value={profileDetails?.state}
                        id="state"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {checkTheField("Zip") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Zip</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your zip"
                        value={profileDetails?.zip}
                        id="zip"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  <div className="pb-4 d-flex justify-content-between">
                    <div>
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2 px-4"
                      onClick={() => navigate("/college_details")}
                    >
                      Prev
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={() => {
                          // if (
                          //   !checkTheField("Expected Year to Graduate") &&
                          //   !checkTheField("College You Will Graduate From")
                          // ) {
                          //   navigate("/basic_details");
                          // } else {
                            navigate("/college_details");
                          // }
                        }}
                      >
                        Prev
                      </button>
                    </div>
                    <div>
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2 px-2"
                      onClick={updateProfileFn}
                    >
                      Submit
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={updateProfileFn}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default AdditionalInformation;
