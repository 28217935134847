import React from "react";
import { useNavigate } from "react-router-dom";

function Footer2() {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-gradient-to-r from-[#000000] to-[#626262] px-4 pb-4">
        <div className="container mx-auto items-center h-full text-[#fff]">
          <div className="flex flex-wrap pt-10 w-full pb-4">
            <div className="w-full sm:w-2/12 md:w-3/12 p-2">
              <p className="pb-4">Powered by</p>
              <a
                onClick={() => {
                  navigate("/home");
                  window.scrollTo(0, 0);
                }}
                className="cursor-pointer"
              >
                <div className="w-[180px] h-[80px] rounded-lg bg-[#fff] flex justify-center items-center">
                  <img
                    src="images/logo.png"
                    className="w-[150px] h-[50px]"
                  ></img>
                </div>
              </a>
            </div>
            <div className="w-full sm:w-2/12 md:w-3/12 p-2">
              <p className="font-semibold text-xl pb-4">About</p>
              <ul>
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/whatwedo");
                      window.scrollTo(0, 0);
                    }}
                  >
                    What we do
                  </a>
                </li>
                <li className="pb-4">
                  <a
                    onClick={() => {
                      navigate("/faq");
                      window.scrollTo(0, 0);
                    }}
                    className="cursor-pointer hover:text-[#FBB522]"
                  >
                    FAQ’s
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/contact");
                      window.scrollTo(0, 0);
                    }}
                    className="cursor-pointer hover:text-[#FBB522]"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-2/12 md:w-3/12 p-2">
              <p className="font-semibold text-xl pb-4">
                Career & Photo Resources
              </p>
              <ul>
                {/* <li className="pb-4">
                  <a className="cursor-pointer hover:text-[#FBB522]">
                    Job Search Strategies
                  </a>
                </li> */}
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/why_enhance_profiles");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Why Enhance your LinkedIn profile photo
                  </a>
                </li>
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/photo_selfie");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Photo (Selfie) Tips
                  </a>
                </li>
                {/* <li className="pb-4">
                  <a className="cursor-pointer hover:text-[#FBB522]">
                    Career Exploration Strategies
                  </a>
                </li> */}
                <li>
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/Linkedin_profile");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Social media and LinkedIn Profile Tips
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-2/12 md:w-3/12 p-2">
              <p className="font-semibold text-xl pb-4">Who are you?</p>
              <ul>
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/alumni");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Student,Grad, or Alumni
                  </a>
                </li>
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/problem");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Admission, Alumni Association, or Career Center
                    Administrator
                  </a>
                </li>
                <li className="pb-4">
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/annual");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Annual Giving, Non-Profit, or Political Administrator
                  </a>
                </li>
                <li>
                  <a
                    className="cursor-pointer hover:text-[#FBB522]"
                    onClick={() => {
                      navigate("/recruiter");
                      window.scrollTo(0, 0);
                    }}
                  >
                    College Recruiter or Hiring Manager
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="h-[1px] bg-[#fff] mb-2"></div>
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-[#fff]  sm:text-center">
              © Copyright{" "}
              <a className="hover:underline hover:text-[#FBB522]">
                MySocial Headshots
              </a>{" "}
              @ {new Date().getFullYear()}
            </span>
            <ul className="flex flex-wrap items-center mt-3 font-medium text-[#fff] sm:mt-0">
              <li>
                <a
                  className="mr-4 hover:underline md:mr-6 hover:text-[#FBB522] cursor-pointer"
                  onClick={() => {
                    navigate("/privacy");
                    window.scrollTo(0, 0);
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="mr-4 hover:underline md:mr-6 hover:text-[#FBB522] cursor-pointer"
                  onClick={() => {
                    navigate("/terms");
                    window.scrollTo(0, 0);
                  }}
                >
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer2;
