import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
// import "../Tailwind.css";

function Faq() {
  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-50 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-faq.png"
        />
        <div className="container mx-auto">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-[220px]">
              Frequently Asked Questions
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16">
          <p className="text-5xl font-bold pb-8 text-center "> FAQ’s</p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full ">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <Accordion className="accordionz w-full">
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      What is MySocial Headshots?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Based on the wildly successful program LinkedIn introduced
                      that allowed users to put #HIRING and #OPENTOWORK
                      statements on their profile headshots, MySocial Headshots
                      developed AI technology that enables users to add
                      organization’s statements, (examples, “I Gave to ABC”,
                      “ABC Freshman”, “I Support ABC”, “ABC Class of 2023”, ”I
                      Mentor ABC Students”) as well as logos, emblems, and
                      mascot graphics to their photos and then use the enhanced
                      headshots in their LinkedIn and social media profiles.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>How does it work?</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our software enables users to simply upload the profile
                      photos they want to enhance with your message/brand and
                      then click to add the statement and graphic image they
                      want to display to their network. The enhanced headshot
                      can then be downloaded to their phone or computer so they
                      can upload it to their LinkedIn or social media profiles.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      What organizations benefit from offering MySocial
                      Headshots to their intended audiences?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Higher education departments including admissions, career
                      services, athletic, alumni associations, annual giving,
                      dorm/housing, fraternities, and sororities all benefit.
                      Others include national student associations, as well as
                      national business membership associations, health clubs,
                      political organizations, veteran organizations, the
                      military, non-profits, and fundraisers. Brands (finance,
                      entertainment, hospitality, travel, beauty) see this as an
                      effective way to give members/consumers the keys to a
                      viral marketing campaign.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      Why should my organization use MySocial Headshots?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      It’s a terrific branding and engagement strategy! Think
                      about giving hundreds, if not thousands of your
                      students/alumni/employees/members/consumers the ability to
                      promote your organization on their LinkedIn and social
                      media profiles. Consumers willingness to share your brand
                      on their LinkedIn and social media profiles will help you
                      increase engagement, retention, registrations,
                      memberships, dues, revenue, and brand awareness! Every
                      post, like, and comment puts your brand out there!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      Why should individuals use MySocial Headshots?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul className="list-disc pl-5 space-y-3">
                      <li>
                        For job seekers, it helps them get noticed! LinkedIn
                        research shows recruiters spend 17 percent of their time
                        viewing individuals’ profile photos. More importantly,
                        the same research shows that a higher quality more
                        noticeable profile photo– not only increases
                        employers/recruiters confidence in candidates –but
                        increases candidates chances of being contacted by
                        employers and recruiters.
                      </li>
                      <li>
                        Consumers love to share their favorite coffee,
                        entertainer, clothing designer, athletic team, or
                        college on their hats or t-shirts. Why not on their
                        LinkedIn or social media profile? MySocial Headshots
                        leans in on that same behavior and gives consumers the
                        ability to share their affiliation, affection, and pride
                        with others!
                      </li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      How should we promote our MySocial Headshot portal?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Simply include a branded link in your emails, on your
                      website, in your newsletters, and in your social media
                      channels to alert your intended audience of the
                      opportunity. When they click on the link they will be
                      whisked to your branded portal page that will include the
                      statements, logos, emblems, and mascots you want them to
                      use.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      What controls do users have?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Your users will get their own profile page/dashboard that
                      will save their enhanced headshots. They can change
                      statements, emblems, logos, and mascot graphics as often
                      as they would like. Our patent pending photo enhancing
                      technology gives them complete control on the positioning
                      of the statement and graphic on their photo and even gives
                      them the ability to control the gradation of the ribbon.
                      Consider creating campaigns to encourage users to change
                      statements frequently and reward them for their efforts.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      How do we track utilization and engagement?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Simple! We create a custom dashboard for you that will not
                      only list all users, but it will provide helpful data sets
                      you can use to determine which marketing campaigns have
                      been most effective to increase awareness and
                      participation. For example you will be able to determine
                      which statements are the most popular, which graphics are
                      used the most, which users have created the most enhanced
                      photos and who the top ten users are, and much more.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      What is the annual subscription cost and what do we get?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-lg pb-2">
                      Your organization can subscribe to the MySocial Headshots
                      program and provide the service to your intended audience
                      for free. Yearly subscriptions includes:
                    </p>
                    <ul className="list-decimal font-normal pl-5">
                      <li className="py-2">
                        Customized registration fields that can capture basic
                        contact information or more detailed contact information
                        including physical address information, parent contact
                        information, college graduated from, graduation year,
                        degree, major, etc. Users have options of logging in via
                        their email, Google, or LinkedIn logins.
                      </li>
                      <li className="py-2">
                        Branded portal web page featuring an iconic photo of
                        your campus, headquarters, brand or product including
                        navigational links to your online websites.
                      </li>
                      <li className="py-2">
                        Statements that users select and add to their headshots
                        that you can request to change quarterly.
                      </li>
                      <li className="py-2">
                        Custom coloring of the background ribbon (which the
                        statement is displayed on) based on your organization’s
                        primary HEX color.
                      </li>
                      <li className="py-2">
                        Graphic image (logo, emblems, mascot images, etc.) that
                        you can change quarterly.
                      </li>
                      <li className="py-2">
                        Dashboard displaying total users, frequency of
                        use/utilization, most popular statements, graphics, and
                        top users.
                      </li>
                      <li className="py-2">
                        Marketing material you can use to get the message out
                        via emails, e-newsletters, and social media.
                      </li>
                      <li className="py-2">
                        Integration of Google Analytics to identify trends and
                        match results to your marketing campaigns.
                      </li>
                      <li className="py-2">
                        Free use by your intended audience.
                      </li>
                      <li className="py-2">
                        Your users will have access to tips, tools, and
                        strategies to take better “selfie headshots” as well as
                        direction to unique AI profile photo enhancing
                        technologies.
                      </li>
                      <li className="py-2">
                        Unique URL to promote your portal page, registration and
                        dashboard
                      </li>
                      <li className="py-2">
                        Participation in our community of users who– along with
                        our team – will share launching tips and advice and
                        provide on-going best practices.
                      </li>
                    </ul>
                    <p className="text-lg pt-3">
                      The yearly subscription is based on your number of
                      intended users. There is a one-time setup fee to customize
                      the registration fields, set up your portal page,
                      statements, dashboard, etc. You will be surprised how
                      reasonable our program is!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading className="font-semibold">
                    <AccordionItemButton>
                      How do we get started with MySocial Headshots?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="pb-3 text-center">
                      Simply reach out to chris@mysocialheadshots.com You will
                      be GLAD you did!
                    </p>
                    <p>
                      Our first step will be to create a custom registration
                      form that will capture data you want to collect from your
                      audience. Next we’ll create a web portal page with a
                      unique URL you will use to promote your program. After
                      that we’ll customize the photo enhancement technology with
                      your organization's HEX colors, statements, and logos.
                      Depending on our current demand, we should be able to have
                      you up and running your campaign within two weeks.
                    </p>
                    <p className="pb-3 text-center fw-600 pt-3">
                      Contact us for a customized quote built around your unique
                      campaign needs!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Faq;
