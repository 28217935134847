import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
function My_profile() {
  const [editCancel, setEditCancel] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [collageDetails, setcollageDetails] = useState([]);
  const [loading, setloading] = useState(true);
  const [formFields, setformFields] = useState([]);
  const navigate = useNavigate();

  const updateProfileFn = async () => {
    // console.log(profileDetails);
    setloading(false);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...profileDetails,
          phone: parseInt(profileDetails?.phone),
          zip: parseInt(profileDetails?.zip),
          token: localStorage.getItem("user_token_headShots"),
        },
      });
      if (response.status == 200) {
        setloading(true);
        setEditCancel(!editCancel);
        // console.log(response.data, "1st page profile");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLabel = (key) => {
    let universityData = JSON.parse(localStorage.getItem("university_data"));
    let orgnizationType = universityData?.orgnizationType;
    // let orgnizationType = 2;
    console.log(orgnizationType, "orgnizationType");
    switch (orgnizationType) {
      case 0:
        if (key === "Year Associated with") {
          return "Graduation Year";
        } else {
          return "College Graduating From";
        }
      case 1:
        if (key === "Year Associated with") {
          return "Member Since";
        } else {
          return "Currently";
        }
      case 2:
        if (key === "Year Associated with") {
          return "Conference Year";
        } else {
          return "Conference Participating In";
        }
      case 3:
        if (key === "Year Associated with") {
          return "Year Participating";
        } else {
          return "Participating In";
        }
      case 4:
        if (key === "Year Associated with") {
          return "Member Since";
        } else {
          return "Associated With";
        }
      case 5:
        if (key === "Year Associated with") {
          return "Participation Year";
        } else {
          return "Participating With";
        }
      case 6:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Department Associated With";
        }
      case 7:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Relationship With Employer";
        }
      case 8:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Current Job Search Status";
        }
      case 9:
        if (key === "Year Associated with") {
          return "Current Year";
        } else {
          return "Current Job Search Status";
        }
      default:
        if (key === "Year Associated with") {
          return "";
        } else {
          return "";
        }
    }
  };
  

  const getProfileDetails = async () => {
    // console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCollageDetails = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/collages`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
          universityid: JSON.parse(localStorage.getItem("university_data"))?.id,
        },
      });
      // console.log(response,"collage details")
      setcollageDetails(response.data.collages);
    } catch (error) {
      console.log(error);
    }
  };
  const getFormFields = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/formfields/${
          JSON.parse(localStorage.getItem("university_data"))?.id
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      // console.log(response,"collage details")
      setformFields(response.data.formFields);
    } catch (error) {
      console.log(error);
    }
  };
  const checkTheField = (key) => {
    let find = formFields?.find((item) => item?.question == key);
    if (find?.isEnabled) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setprofileDetails({ ...profileDetails, [e.target.id]: e.target.value });
  };
  const yearsToDisplay = 10;
  const currentYear = new Date().getFullYear();

  const graduationYears = [];
  for (let i = 0; i <= yearsToDisplay; i++) {
    const year = currentYear + i;
    graduationYears.push(year);
  }
  console.log(profileDetails, "profile details");
  console.log(formFields, "form fields");
  useEffect(() => {
    getProfileDetails();
    getCollageDetails();
    getFormFields();
  }, []);

  return (
    <>
      <Header />
      <Container className="txt-brown py-4  px-14">
        <Row>
          <Col className="col-12 text-center pb-4 mt-3">
            <h2 className="fw-600 fs-45">
              {profileDetails?.firstName} {profileDetails?.lastName} Profile
            </h2>
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="row">
              <div className="col-auto ms-auto">
                {editCancel ? (
                  <button
                    className="border-btn"
                    onClick={() => setEditCancel(!editCancel)}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="border-btn"
                    onClick={() => setEditCancel(!editCancel)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            {formFields?.length > 0 && (
              <div className="row pb-4">
                {checkTheField("First Name") && (
                  <div className="col-sm-6">
                    <label htmlFor="first_name" className="form-label fw-600">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      disabled={!editCancel}
                      id="firstName"
                      value={profileDetails?.firstName}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {checkTheField("Last Name") && (
                  <div className="col-sm-6">
                    <label htmlFor="last_name" className="form-label fw-600">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      disabled={!editCancel}
                      id="lastName"
                      value={profileDetails?.lastName}
                      onChange={handleChange}
                    />
                  </div>
                )}

                <div className="col-sm-12 pt-4">
                  {checkTheField("Email") && (
                    <div className="pb-4">
                      <label htmlFor="email" className="form-label fw-600">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your email"
                        disabled
                        id="email"
                        value={profileDetails?.email}
                      />
                    </div>
                  )}

                  {checkTheField("Year Associated with") && (
                    <div className="pb-4">
                      <label htmlFor="graduate" className="form-label fw-600">
                        {getLabel("Year Associated with")}
                      </label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        disabled={!editCancel}
                        value={profileDetails?.graduationYear}
                        id="graduationYear"
                        onChange={handleChange}
                      >
                        <option selected>Select</option>
                        {graduationYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {checkTheField("Department Associated with") && (
                    <div className="pb-4">
                      <label htmlFor="graduate" className="form-label fw-600">
                        {getLabel("Department Associated with")}
                      </label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        disabled={!editCancel}
                        value={profileDetails?.graduationCollege}
                        id="graduationCollege"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {collageDetails?.map((item, index) => (
                          <option value={item?.id}>{item?.collageName}</option>
                        ))}
                      </select>
                    </div>
                  )}

                  {checkTheField("Major") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Major</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your major"
                        disabled={!editCancel}
                        value={profileDetails?.major}
                        id="major"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Your LinkedIn Profile URL") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Your LinkedIn Profile URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your linkedin URL"
                        disabled={!editCancel}
                        value={profileDetails?.linkedIn}
                        id="linkedIn"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Who are you") && (
                    <div className="pb-4">
                      <label htmlFor="graduate" className="form-label fw-600">
                        Who are you
                      </label>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={profileDetails?.occupation}
                        id="occupation"
                        onChange={handleChange}
                      >
                        <option selected>Select who are you</option>
                        <option value="Prospective Student">
                          Prospective Student
                        </option>
                        <option value="Student">Student</option>
                        <option value="Student-Athlete">Student-Athlete</option>
                        <option value="Grad">Grad</option>
                        <option value="Alumni">Alumni</option>
                        <option value="Contributor">Contributor</option>
                        <option value="Parent">Parent</option>
                      </select>
                    </div>
                  )}

                  {checkTheField("Parent First Name (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Parent First Name (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your parent name"
                        disabled={!editCancel}
                        value={profileDetails?.parentFirstName}
                        id="parentFirstName"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Parent Email (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Parent Email (Optional)
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter your parent email"
                        disabled={!editCancel}
                        value={profileDetails?.parentEmail}
                        id="parentEmail"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Cell Phone (Optional)") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">
                        Cell Phone (Optional)
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter your cell phone"
                        disabled={!editCancel}
                        value={profileDetails?.phone}
                        id="phone"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Address 01") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Address 01</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your address 01"
                        disabled={!editCancel}
                        value={profileDetails?.address1}
                        id="address1"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Address 02") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Address 02</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your address 02"
                        disabled={!editCancel}
                        value={profileDetails?.address2}
                        id="address2"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Street") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Street</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your street"
                        disabled={!editCancel}
                        value={profileDetails?.street}
                        id="street"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("State") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your state"
                        disabled={!editCancel}
                        value={profileDetails?.state}
                        id="state"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {checkTheField("Zip") && (
                    <div className="pb-4">
                      <label className="form-label fw-600">Zip</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your zip"
                        disabled={!editCancel}
                        value={profileDetails?.zip}
                        id="zip"
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  {!loading ? (
                    <div className="w-100 text-center p-2">
                      <Loader />
                    </div>
                  ) : (
                    <div className="pb-4">
                      {/* <button
                      type="button"
                      className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2"
                      onClick={updateProfileFn}
                    >
                      Submit
                    </button> */}
                      <button
                        type="button"
                        className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                        onClick={updateProfileFn}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default My_profile;
