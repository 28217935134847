import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function LoginEmail() {
  const [emailDetails, setemailDetails] = useState({
    eyeShown:false
  });
  const [loginError, setloginError] = useState({
    status: false,
    message: "",
  });
  const [loggingIn, setloggingIn] = useState(false);

  const navigate = useNavigate();

  const handleDetails = (e) => {
    setemailDetails({ ...emailDetails, [e.target.id]: e.target.value });
  };

  const postEmailDetails = async () => {
    setloggingIn(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailDetails?.email,
          password: emailDetails?.password,
        },
      });

      // console.log(response, "response");
      if (response?.status == 200) {
        setloggingIn(false);

        if (
          response?.data?.universitySlug !==
          JSON.parse(localStorage.getItem("university_data"))?.universitySlug
        ) {
          setloginError({
            status: true,
            message: "User doesn't exist",
          });
        } else {
          localStorage.setItem("user_email_headShots", response?.data?.email);
          localStorage.setItem("user_token_headShots", response?.data?.token);
          navigate("/myheadshot");
        }
      }
    } catch (error) {
      setloggingIn(false);
      setloginError({
        status: true,
        message: error?.response?.data?.error,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_token_headShots")) {
      navigate("/myheadshot");
    }
  }, []);

  console.log(emailDetails, "email details");
  return (
    <>
      <Header />
      <div className="hero-box">
        <img src="images/home-banner.png" className="hero-poster" alt="..." />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center txt-brown">
              <div className="login-form">
                <h2 className="fs-22 fw-700 text-center pb-4">Login</h2>
                <div className="mb-3">
                  <label className="form-label fw-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="Enter your email"
                    value={emailDetails?.email}
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">Password</label>
                  <div className="relative">
                    <input
                      type={emailDetails.eyeShown ? "text" : "password"}
                      class="form-control"
                      id="password"
                      value={emailDetails?.password}
                      placeholder="Enter your password"
                      onChange={handleDetails}
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-0 h-100"
                      onClick={() =>
                        setemailDetails({
                          ...emailDetails,
                          eyeShown: !emailDetails.eyeShown,
                        })
                      }
                    >
                      {emailDetails.eyeShown ? (
                        <svg className="icon">
                          <use href="#icon_vision_withoutslash"></use>
                        </svg>
                      ) : (
                        <svg className="icon">
                          <use href="#icon_vision"></use>
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                {/* Forgot Password Section */}
                <div className="flex justify-end mb-3 font-semibold">
                  <Link to="/forgot_password">Forgot Password?</Link>
                </div>
                {loginError?.status && (
                  <div className="m-1 text-danger text-center">
                    {loginError?.message}
                  </div>
                )}
                <div className="mt-4">
                  <button
                    type="button"
                    disabled={loggingIn}
                    className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={postEmailDetails}
                  >
                    {loggingIn ? <Loader /> : "LOGIN"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default LoginEmail;
