import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
// import { GoogleLogin } from "react-google-login";

function Home() {
  const navigate = useNavigate();
  const params = useParams();

  const handleGoogleLoginSuccess = (response) => {
    console.log(response);
    // Handle the successful login with Google response
    // You can send the response to your backend for further authentication and authorization
    // After successful login, you can navigate to a different page if needed
  };

  const responseGoogleFailure = (error) => {
    console.error(error);
    // Handle error if Google login fails
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    if (token && email) {
      localStorage.setItem("user_email_headShots", email);
      localStorage.setItem("user_token_headShots", token);
      // navigate("/myheadshot");
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("user_token_headShots")) {
      navigate("/myheadshot");
    }
  }, []);

  return (
    <>
      <Header />
      <div className="hero-box">
        <img src="images/home-banner.png" className="hero-poster" />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <div className="login-box">
                <h2 className="fs-40 fw-700 text-center pb-4">Log In</h2>
                <button
                  type="button"
                  className="w-100 icon-btn google-btn d-flex align-items-center justify-content-center mb-4"
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BASE_URL}/auth/google`,
                      "_self"
                    )
                  }
                >
                  <svg className="icon fs-40 me-3">
                    <use href="#icon_google"></use>
                  </svg>
                  Log in With google
                </button>
                {/* Add the GoogleLogin component here */}
                {/* <GoogleLogin
                  clientId="833478557681-o392uareg05q85ut0lrgqkd6geqoou8e.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={responseGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                /> */}
                <button
                  type="button"
                  className="w-100 icon-btn email-btn d-flex align-items-center justify-content-center mb-4"
                  onClick={() => navigate("/login_email")}
                >
                  <svg className="icon fs-40 me-3">
                    <use href="#icon_email"></use>
                  </svg>
                  Log in With email
                </button>
                <p className="text-center fs-22 fw-500">
                  Don’t have an account?{" "}
                  <a
                    href="#"
                    className="sign-btn"
                    onClick={() => {
                      navigate("/sign_up");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Sign up
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Home;
