import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Terms() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            MySocial Headshots Terms of Service
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <p className="pb-4 font-semibold text-xl">January 1, 2023</p>
                <p className="pb-4">
                  Summary Welcome! You've discovered MySocial Headshots and our
                  Terms of Service. It's our contract with you describing the
                  rules for using MySocial Headshots. While you may want to skip
                  over some of this (admittedly, it's dry reading), we do our
                  best to keep things succinct and use plain English. Please
                  read the Terms carefully and completely as it'll help you
                  understand what's important to us and make you more
                  comfortable using MySocial Headshots. This Summary of the
                  Terms summarizes some of its provisions to make it easier to
                  digest, but the Summary itself is not technically part of the
                  Terms, nor does it limit the scope of the Terms in any way.
                  While you hopefully find it helpful, reading the Summary
                  doesn't count as reading the Terms in full. Okay, disclaimer
                  over. Below is a brief overview of what you can expect to find
                  in the Terms. Think of this as the Cliffs Notes covering some
                  of the important concepts. It's not all-inclusive and won't
                  get you an A on the test (did we mention there's a test?), but
                  it's a useful reference to guide your reading or help you
                  recall certain parts.
                </p>

                <p className="pb-4 font-semibold text-xl">1. The Basics</p>

                <p className="pb-4">
                  The "Basics" section includes the general acceptance
                  provision, some key words used throughout the Terms, and a
                  provision for how we'll communicate with you when we modify
                  the Terms. A couple of important concepts to watch out for as
                  you read section 1: with most online services, ours included,
                  you tacitly agree to the Terms by using the Service; and, also
                  like many other online services, we are growing and changing
                  rapidly and need to update our Terms from time to time as a
                  result. If you keep using the Service after an update, you're
                  agreeing to the new Terms.
                </p>

                <p className="pb-4 font-semibold text-xl">2. The Service</p>

                <p className="pb-4">
                  Section 2 offers more detail about how the Service actually
                  works. There is information about how to create accounts and
                  the confidentiality of what you do on the Service. In section
                  2, we also describe our Service as a "venue." In other words,
                  you'll be interacting with other users and we won't be
                  watching all the time. You should recognize that other users
                  of the Service are, in most cases, not MySocial Headshots
                  employees. While we work very hard to make MySocial Headshots
                  a place for trust and reliability, you should use good
                  judgment when interacting with other users because,
                  ultimately, you're at your own risk. There may be places on
                  the Site or App where we provide guidelines to help you to
                  protect yourself, but those never absolve you of taking
                  responsibility for your use of the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">3. Financial Terms</p>

                <p className="pb-4">
                  While we don't do it now, the Service may at some point allow
                  you to offer products or services for pay, so we need a
                  section to describe how the transactions within the Service
                  are handled. One specific point to keep in mind is that what
                  you do on the Service may have Tax implications for you
                  personally. We can't really help you figure that stuff out, so
                  you'll need to seek whatever Tax counsel you normally do if
                  you plan to be making money on the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">4. User Policies</p>

                <p className="pb-4">
                  Our user policies describe who can use the Service, how they
                  are expected to use it, and a few things about how we think
                  about their responsibility and ours for what goes on. To use
                  the Service, you need to be 13 and legally capable of signing
                  a contract. You also need to make sure that agreeing to our
                  Terms and using the Service doesn't break any laws or rules
                  that you need to be following. When you use the Service,
                  you're responsible for your actions. You can't use the Service
                  to break the law, steal things, or deceive people. We aren't
                  endorsing or verifying anyone on the Service, so use good
                  judgment. There's a lot of "Golden Rule" and "be smart" stuff
                  captured in section 4. 5. Licenses and Ownership Section 5
                  covers some intellectual property concepts, as they relate to
                  software that we create and Content that both we and users
                  create. This section establishes what we're all allowed to do
                  with that intellectual property. A specific provision to think
                  about is the rights that you give MySocial Headshots when you
                  upload Content to our Service. We need to capture a lot of
                  rights to what you upload so that we can safely provide the
                  Service, and we list those rights in section 5.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6. General Provisions
                </p>

                <p className="pb-4">
                  The last section, 6, captures a number of different legal
                  concepts that can really be best described as "miscellaneous."
                  Running a company and operating a Service is a complex
                  business, and there are lots of statements we need to make to
                  protect ourselves for every potential eventuality. In section
                  6, you'll find a number of additional waivers and protections
                  for all kinds of things, particularly around damages resulting
                  from use of the Service, and some ground rules like the
                  governing law for the contract, the dispute resolution
                  process, and what happens if anyone breaks the contract. These
                  provisions are more broadly legal in nature and will have less
                  information specific to the way our Service operates. Okay,
                  now now that you've got a few things in mind to watch out for,
                  on with the show! The complete Terms follow.
                </p>

                <p className="pb-4 font-semibold text-xl">1. The Basics</p>

                <p className="pb-4 font-semibold text-xl">
                  1.1 Accepting the Terms
                </p>

                <p className="pb-4">
                  While you're likely reading this on the MySocial Headshots
                  website or mobile application, we think of MySocial Headshots
                  as offering a "Service" and we'll refer collectively to the
                  various form factors, products, and services that comprise the
                  offering as such throughout the Terms. Individually, the
                  Service consists of the "Site," which can be accessed at
                  www.mysocialheadshots.com or other websites on which we may
                  make the Service available and the "App," which describes any
                  and all applications for mobile devices, tablets, and desktops
                  on which the Service is available. Thematically, the Service
                  is an online venue that connects "Alumni", or graduates of an
                  insitution (usually of higher learning) with one another to
                  share advice, jobs, and mentorship. The Service is owned and
                  operated by MySocial Headshots, Inc., which we may refer to as
                  "MySocial Headshots," "we," "us," or "our" for brevity. When
                  you use the Service, even if you decide not to become a
                  registered user, you agree to several things: (i) You'll
                  comply with and be legally bound by these Terms of Service
                  (we'll just call them "Terms" to keep things easy), the of
                  partnering organizations referenced here,  and any rules,
                  policies, or disclaimers posted on the Service about which you
                  are notified. Collectively, those things make up the
                  "Agreement," (ii) You acknowledge that you are accepting a
                  benefit that cannot be disgorged, (iii) You acknowledge that
                  the service is subject to U.S. export controls and that you
                  will comply with them, and (iv) You are neither located in a
                  sanctioned country nor a prohibited person. The Agreement
                  governs your access to and use of the Service and all
                  Collective Content (see the "Key Words" in section
                </p>

                <p className="pb-4 font-semibold text-xl">
                  1.2 if you see capitalized words and aren't sure what they
                  mean) and constitutes a binding legal agreement between you
                  and MySocial Headshots.
                </p>

                <p className="pb-4">
                  We ask that you to read carefully both the Terms here and our
                  Privacy Policy at www.mysocialheadshots.com/privacy. If you do
                  not accept the Agreement, that's okay, but you can't use the
                  Service. As a user you may interact with other users on the
                  service (in fact, you're pretty likely to, as that's kind of
                  the point of the Service), but in doing so you must recognize
                  that MySocial Headshots is not party to any agreements you
                  make with other users. We don't control users' conduct on the
                  Service or any information provided in connection thereto, so
                  to protect ourselves we must disclaim liability in this
                  regard.
                </p>

                <p className="pb-4 font-semibold text-xl">1.2 Key words</p>

                <p className="pb-4">
                  We use this list of key words to keep track of words and
                  phrases we repeat over and over again throughout the
                  Agreement. You'll recognize key words when you see them
                  because they'll always be capitalized. You can refer here for
                  clarification on what they mean. Additionally, when we use
                  "you" and "your" we're referring to both the specific reader
                  of these Terms, either an individual or entity that uses the
                  Service, as well as all users in general. If you accept the
                  Agreement on behalf of a company or other legal entity, you
                  represent and warrant that you have the authority to do that.
                  "AAA" refers to the American Arbitration Association. "AAA
                  Rules" refers to the Commercial Arbitration Rules and the
                  Supplementary Procedures for Consumer Related Disputes.
                  "Agreement" refers collectively to the Terms, Privacy Policy,
                  and any other rules, policies, or disclaimers posted on the
                  Service. "App" refers to any and all native mobile, tablet, or
                  desktop applications of the Service. "Collective Content"
                  means both Member Content and MySocial Headshots Content.
                  "Content" covers any text, graphics, images, music, software
                  (excluding the Site or App software), audio, video, data,
                  information or other materials users encounter on the Service.
                  "Dispute" describes any claim or controversy arising from or
                  relating to this Agreement, the breach, termination,
                  enforcement, interpretation or validity thereof, or your use
                  of the Service. "Exchange" describes the information exchange
                  or other transactional interactions between Members
                  facilitated by the Service. "Exchange Fee" refers to any
                  applicable transaction charge paid in consideration of the
                  advice and information provided as part of an Exchange. An
                  exchange may not (and often does not) have an associated
                  Exchange Fee. "Feedback" refers to feedback, comments and
                  suggestions for improvements to the Service. "Member" means a
                  person who completes MySocial Headshots's account registration
                  process as described under "User Account Registration" below.
                  "Member Content" covers all Content created by a Member on the
                  Service. "MySocial Headshots" refers to both the product at
                  www.mysocialheadshots.com and TalentMarks., the owner of the
                  Service. "MySocial Headshots Account" refers to the account
                  profile completed by Members during account registration.
                  "MySocial Headshots Content" covers all Content that MySocial
                  Headshots makes available through the Service including any
                  Content licensed from a third party, but excluding Member
                  Content. "Service" describes the various form factors,
                  products, and services that comprise the MySocial Headshots
                  offering. "Service Fee" refers to the percentage of any
                  Exchange Fees collected by MySocial Headshots in consideration
                  of the Service. "Settings" refers to parameters and
                  preferences associated with an MySocial Headshots Account that
                  Members can change to modify their experience on the Service.
                  "Site" refers to any and all web-based applications of the
                  Service, including MySocial Headshots and any other website on
                  which we make the Service available. "SN" refers to third
                  party social networking sites. For some SNs, you may use your
                  credentials with that site to create a MySocial Headshots
                  Account. "SN Account" means a user's credentials with a third
                  party SN that the user has linked to a MySocial Headshots
                  Account. "SN Content" refers to Content associated with a
                  user's SN Account that is accessed, stored, and/or made
                  available on the Service. This Content can include but is not
                  limited to a user's name, location, biographical details, and
                  images associated with their SN Account. "Tax" or "Taxes" mean
                  any sales taxes, value added taxes (VAT), goods and services
                  taxes (GST) and other similar municipal, provincial, state and
                  federal indirect or other withholding and personal or
                  corporate income taxes.
                </p>

                <p className="pb-4">
                  "Terms" is shorthand for these Terms of Service.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  1.3 Communicating with us digitally
                </p>

                <p className="pb-4">
                  When you visit the Site or App or send us e-mails, you are
                  communicating with MySocial Headshots digitally. By doing
                  this, you're agreeing to receive communications from us
                  digitally, and those communications satisfy any requirement to
                  deliver those communications in writing. It's a bit easier
                  than writing a letter these days, and we like to save paper.
                  When we need to tell you something, we'll either send you an
                  e-mail or post notices on the Site or App. If you register for
                  an account on MySocial Headshots, you agree to keep your email
                  associated with the MySocial Headshots Account current.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  1.4 Modifying this Agreement
                </p>

                <p className="pb-4">
                  MySocial Headshots is a young and growing company, and as such
                  we may need to update this Agreement (including the Privacy
                  Policy) from time to time. When we do this, we promise not to
                  keep it a secret. We'll post the modified Terms and/or Privacy
                  Policy to the Site and App and update the effective date
                  listed at the top. Once that happens, the new Agreement goes
                  into effect immediately, and if you continue using the Service
                  we'll take that to mean your acceptance of the new Agreement.
                </p>

                <p className="pb-4 font-semibold text-xl">2. The Service</p>

                <p className="pb-4 font-semibold text-xl">
                  2.1 MySocial Headshots is a venue
                </p>

                <p className="pb-4">
                  MySocial Headshots is a venue. What we mean by this is that
                  while we provide the offerings defined by the Service, it's
                  Members of the Service, not MySocial Headshots, who create and
                  provide Member Content, which is the substance of Exchanges.
                  Examples of the types of offerings MySocial Headshots
                  contributes are 1) connecting Members to exchange information,
                  2) scheduling Exchanges, 3) providing email notification of
                  updates associated with an Exchange, 4) hosting Collective
                  Content, 5) integrating with payment collection and
                  distribution services, and 6) otherwise facilitating
                  Exchanges. MySocial Headshots is not a party to Exchanges or
                  any other conversations between Members. MySocial Headshots is
                  not required to verify the credentials of or information
                  provided by any of its Members, but, because we're nice
                  people, we strive to ensure the validity of Members on the
                  Service so you can feel good about using it. Keep in mind,
                  Members are not employees or agents of MySocial Headshots.
                  Being a venue means that MySocial Headshots is not responsible
                  for Member Content, any other Content or information provided
                  as part of an Exchange or any other communication or
                  agreements between Members, or liable for any loss or damage
                  created as a result of your reliance on information provided
                  by Members or contained in Member Content. What we're saying
                  is that you use the Service and any information you get access
                  to as a result at your own risk. Let us get one more
                  disclaimer out of the way. Information and Content you access
                  via the Service is for general information purposes only. It
                  should not be considered a substitute for an in-person visit,
                  evaluation, or professional service. You may want to review
                  laws, regulations, standards, practices and procedures that
                  apply to your particular situation as they may differ
                  depending on your location and specifics. Additionally,
                  Members may represent certain licenses, certifications,
                  employment, or education credentials. While we ask all Members
                  to represent themselves as accurately as possible, their
                  profile on MySocial Headshots should not be construed as
                  validation of their credentials by MySocial Headshots. Bottom
                  line, don't believe what a Member says just because they are
                  listed on the Service, you'll need to make that judgment
                  yourself. At the risk of sounding repetitive (we repeat this
                  concept because it's that important), we reiterate that the
                  Service is a venue intended to facilitate Member interaction.
                  MySocial Headshots cannot and does not edit, modify, filter,
                  screen, monitor, control or guarantee any Member Content or
                  information exchanged between Members via the Service.
                  MySocial Headshots is not responsible for and disclaims any
                  and all liability related to Member Content and information
                  provided under the Service. Accordingly, Members use the
                  service at their own risk.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  2.2 Your communications are not confidential
                </p>

                <p className="pb-4">
                  We promise to be clear with you and tell you how you can
                  expect your information to be used when using the Service.
                  We've even got a great website that describes how we think
                  about your information and privacy. You should read it.
                  However, it's important that we call out specifically here in
                  the Terms that your communications and Content on the Service
                  are not confidential and shall not be the subject of any
                  privileges. For example, your communications and Content on
                  the Service are not subject to doctor-patient privilege,
                  attorney-client privilege, or any other privilege.
                  Communications on MySocial Headshots are limited as described
                  in these Terms, do not involve in-person evaluations or
                  visits, and and as such do not include safeguards and
                  procedures typical of in-person evaluations and visits.
                  Additionally, your communications and Content may be indexed
                  by search engines or otherwise publicly exposed.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  2.3 Registering for an account on the Service
                </p>

                <p className="pb-4">
                  If you visit the Site or App without registering for the
                  Service, you'll be able to see limited Content; however, to
                  use the full functionality of the Service including, but not
                  limited to, creating an Exchange, you'll first need an
                  MySocial Headshots Account. You may register directly via the
                  Site or App or as described in this section. Third Party
                  Registration: Many online services allow you to register for
                  an account using third party social networking service
                  credentials because it is faster and easier than creating an
                  account manually. We too allow you to register for a MySocial
                  Headshots Account in this way via our Site or App, but there
                  are a number of conditions you should keep in mind when you
                  decide to create a MySocial Headshots Account. As we describe
                  those conditions below, we'll use the shorthand "SN" to refer
                  to these sites individually or collectively as a group and "SN
                  Account" to refer to your credentials with a third party SN.
                  These social networks can be services like LinkedIn or
                  Facebook, but we may add others or remove them as registration
                  options from time to time. In order either to create a
                  MySocial Headshots Account using your credentials with a SN
                  Account or to link your MySocial Headshots Account and a SN
                  Account, you'll need to provide the Service with those
                  credentials or other access to that account. By doing so,
                  you're (i) representing that you are legally entitled to
                  provide those credentials or that access to the Service and
                  (ii) granting the Service access to that SN Account, and (iii)
                  representing that these actions on your part do not violate
                  any of the terms and conditions that govern your use of that
                  SN Account with the relevant SN. Effectively, you are agreeing
                  that MySocial Headshots is not responsible for any fees, usage
                  limitations, or other ramifications that may be imposed by the
                  third party SN when you connect a SN Account on the Service.
                  When you grant us access to SN Accounts, recognize that you
                  are also granting us additional rights to Content associated
                  with those SN Accounts ("SN Content"). SN Content can and
                  typically does include your user name, full name, location,
                  email address, profile image, and friends or connections on
                  that SN, but it can also include other Content. We will
                  access, store, and make available SN Content via your MySocial
                  Headshots Account. All SN Content is considered Member Content
                  for purposes of these Terms. Depending on the SN Accounts you
                  choose, you may be able to control our access to certain types
                  of SN Content and personal information through that third
                  party SN's privacy settings. You may also disable the
                  connection between your MySocial Headshots Account and your SN
                  Accounts by updating your MySocial Headshots Account
                  "Settings." Keep in mind that your relationship with the third
                  party SN associated with your SN Accounts is governed solely
                  by your agreement(s) with that SN. We will create your
                  MySocial Headshots Account and your profile page for your use
                  of the Service based upon the personal information you provide
                  to us or that we obtain via a third party SN as described
                  above. No user may have more than one (1) active MySocial
                  Headshots Account. You agree to provide accurate and complete
                  information during the registration process and to keep such
                  information current. If you violate these terms, we may
                  suspend or terminate your MySocial Headshots Account and
                  access to the Service. You are responsible for safeguarding
                  your password. You agree not to disclose your password to any
                  third party except in accordance with these Terms and to take
                  sole responsibility for any activities or actions under your
                  MySocial Headshots Account, regardless of whether you have
                  authorized such activities or actions. If you detect
                  unauthorized use of your MySocial Headshots Account, notify us
                  immediately.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  2.4 Offering paid products and services
                </p>

                <p className="pb-4">
                  As of the date on these Terms, we do not support the ability
                  to offer paid products and services as a Member of the
                  Service. However, we may in the future, and these are the
                  rules and expectations for such a feature. To offer such paid
                  options, you'll need to submit additional personal information
                  not required for an MySocial Headshots account. This required
                  personal information may include details of your expertise or
                  products, pricing and other financial terms applicable to your
                  offering, and additional images. Such information may be made
                  available to both Members and non-Member users through the
                  Service. MySocial Headshots collects such information through
                  a form on the Site or App, but completing this form does not
                  guarantee the approval and publication of your offerings. We
                  may ask you to edit or modify such information before
                  approving and posting it to the Service to comply with
                  MySocial Headshots guidelines or for any other reason. We also
                  reserve the right to reject and prevent publication of such
                  offerings outright, with or without explanation. You agree
                  that you have no legal remedy that would compel MySocial
                  Headshots to publish such offerings. If you find these
                  conditions a bit strict, recognize that they exist to protect
                  the quality of the Service, Members on the Service, and your
                  experiences and interactions with both. If you decide to offer
                  products or services through the Sevice, you agree that you
                  cannot alter the price or financial terms of an Exchange after
                  a Member has requested the Exchange. Any additional Content
                  supplied as a result of creating a product or service offering
                  shall be considered Member Content for purposes of these
                  Terms. More specifically, you are solely responsible for such
                  Content. Depending on your profession and situation, you may
                  be subject to additional laws, rules, or regulations. You also
                  agree that you alone are responsible for ensuring that your
                  use of the Service and any agreements you may enter into with
                  other Members as a result of your use of the Service comply
                  with applicable laws, rules and regulations, Tax requirements,
                  and any other agreements you may have with third parties.
                  MySocial Headshots may at any time and without prior notice
                  remove or disable any paid product or service offerings for
                  any reason. Reasons for removal may include, but are not
                  limited to, Content considered objectionable by MySocial
                  Headshots at our sole discretion, Content or actions that
                  violate these Terms, or Content or actions that otherwise may
                  harm the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  2.5 Addendum for Members bound by NCAA rules
                </p>

                <p className="pb-4">
                  Many communities on the Service are also member organizations
                  of the National Collegiate Athletic Association ("NCAA"), and
                  thus bound to their rules. You agree that, should such rules
                  apply to you, you will not violate them while using the
                  Service. NCAA rules regulate contact between representative of
                  athletics interests (collectively, "boosters" and individually
                  a "booster") and current student-athletes as well as their
                  families and friends. A booster is defined as an individual or
                  organization who: Has participated in or is a member of an
                  agency or organization as described that promotes an
                  intercollegiate athletics program; Has made financial
                  contributions to an athletics department or to an athletics
                  booster organization; Has been requested by an athletics
                  department staff to assist in the recruitment of prospective
                  student-athletes or is assisting in the recruitment of
                  prospective student-athletes; Has assisted or is assisting in
                  providing benefits to enrolled student-athletes; or Is
                  otherwise involved in promoting an intercollegiate athletics
                  program. In general, a booster may not provide anything or
                  make special arrangements for student-athletes, or their
                  friends or families, that are not available to the general
                  student population. Boosters are prohibited from the
                  following: Providing direct or indirect academic assistance;
                  Providing room and/or board or transportation for a
                  student-athlete with eligibility remaining, Entertaining
                  student-athletes or their friends or relatives other than an
                  occasional home meal preapproved by an athletics compliance
                  office, Using the name or picture of a current student-athlete
                  to promote a commercial product or service, Providing the use
                  of an automobile to a student-athlete for any purpose,
                  Providing an award or gift to a student-athlete Purchasing
                  complimentary admissions from a current student-athlete, or
                  provide an honorarium for a speaking engagement. It is
                  permissible for alumni and boosters to employ current
                  student-athletes. Compensation must be based on work actually
                  performed and at a rate commensurate with the going rate in
                  the locality for services of like character. Earnings may not
                  be based on the publicity, reputation, or notoriety generated
                  from the student-athlete’s athletics ability. Please note the
                  following restrictions regarding the employment of
                  student-athletes: Employers may not advertise a
                  student-athlete’s employment to generate extra business, nor
                  may they use a student-athlete’s name or picture to advertise,
                  promote, or recommend the sale or use of any commercial
                  product or service. A student-athlete cannot endorse a company
                  or employer through public advertising mediums.
                  Student-athletes cannot be provided with any benefits not
                  provided to other employees. This includes the use of
                  vehicles, transportation to or from work, meals, loans,
                  advances, or time off with pay. Any employment arrangement
                  must be approved by an athletics compliance office. A timecard
                  must be kept for all student-athletes you employ.
                  Student-athletes may not be paid using cash; check and direct
                  deposit are acceptable forms of payment.
                </p>

                <p className="pb-4 font-semibold text-xl">3. Financial Terms</p>

                <p className="pb-4 font-semibold text-xl">
                  3.1 Offering paid products and services
                </p>

                <p className="pb-4">
                  When another Member wants to purchase an offering from you via
                  the Service, we will share with you the username of the Member
                  and the details of his or her request. Such notification may
                  come via email, text message, Tweet, or App notification. For
                  simplicity, we'll refer to the amount a Member must pay
                  associated with an Exchange as the "Exchange Fee." Exchange
                  Fees may be set by MySocial Headshots or directly by the
                  provider of an offering, and are listed on the Service in U.S.
                  dollars. Where applicable, Members that offer services on the
                  Service that are compensated on a per minute or per hour rate
                  will list an "Exchange Fee Rate" for those services. For such
                  services, the amount of time associated with the Exchange
                  multiplied by the Exchange Fee Rate equals the Exchange Fee.
                  That's the most math you'll need to do when reading through
                  the Terms. Also associated with an Exchange is the "Service
                  Fee" collected by MySocial Headshots in consideration of the
                  Service. The Service Fee is calculated based on a percentage
                  of Exchange Fees collected on your behalf, and listed directly
                  on the Service. When you complete an Exchange, we calculate
                  the Exchange Fee and Service Fee, collect the Exchange Fee,
                  deduct the applicable Service Fee, and remit the balance of
                  the Exchange Fee to you via a third party payments provider,
                  ACH transfer (automated clearing house) or such other payment
                  methods as may be listed on the Site or via the App, in U.S.
                  dollars. Except as otherwise provided herein, Service Fees are
                  non-refundable.
                </p>
                <p className="pb-4 font-semibold text-xl">
                  3.2 Purchasing paid products and services
                </p>

                <p className="pb-4">
                  When you purchase an offering from another Member, recognize
                  that you are agreeing to the financial terms described in this
                  section 3.2. In addition to these Terms there will be terms,
                  conditions, rules and restrictions associated with the
                  individual offering and resulting transaction between you and
                  the other Member. Often these terms will include the pricing
                  terms of the Exchange, but they may also include other terms.
                  You, not MySocial Headshots, are responsible for performing
                  the obligations of any such agreements. MySocial Headshots is
                  not a party to such agreements and disclaims all liability
                  arising from or related to any such agreements. When making a
                  purchase, you agree to pay MySocial Headshots all applicable
                  Exchange Fees. In order to create an Exchange, we may need to
                  either pre-authorize your credit card to ensure your ability
                  to pay or charge the full amount of the Exchange Fee and place
                  the proceeds into an escrow account maintained by our payments
                  provider. You understand and agree that MySocial Headshots
                  reserves the right, at its sole discretion, to either obtain a
                  pre-authorization of your credit card, charge your credit card
                  a nominal amount, not to exceed one U.S. dollar ($1) in order
                  to prove your ability to pay, or charge your credit card the
                  full amount of the Exchange Fee and place the proceeds in an
                  escrow account as described above. Once you and the other
                  Member complete an Exchange, MySocial Headshots will either
                  process and collect the Exchange Fees payable or distribute
                  the appropriate proceeds from the escrow account in accordance
                  with these Terms and the terms of the offering. You should
                  also keep in mind that your bank or credit card provider may
                  collect additional fees for different types of charges,
                  debits, or deposits to your account. We cannot control these
                  types fees if they are charged related to our collection of
                  the Exchange Fees, and MySocial Headshots disclaims all
                  liability in this regard. In order to charge you, we or our
                  third party payments processor will ask you to provide
                  customary billing information such as name, billing address
                  and credit card information. You agree to pay MySocial
                  Headshots for any completed Exchanges in accordance with these
                  Terms by one of the methods described on the Site or App,. You
                  hereby authorize the collection of such amounts by charging
                  the credit card provided as part of requesting the Exchange,
                  either directly by MySocial Headshots or indirectly, via a
                  third party payment processor or by one of the payment methods
                  described on the Site or App. If you are directed to MySocial
                  Headshots's third party payment processor, you may be subject
                  to terms and conditions governing use of that third party's
                  service and that third party's personal information collection
                  practices, and you will want to review those terms before you
                  use their services. We will provide you a transaction history
                  for completed Exchanges either via email notification or a
                  private dashboard on the Site or App.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  3.3 Requesting a refund
                </p>

                <p className="pb-4">
                  Once you've selected and paid for a service or product
                  offering, there may be rare cases in which the Member offering
                  the product or Service clearly does not fulfill his or her
                  obligations as a seller. In such cases, such as an
                  unresponsive seller, you are entitled to a full refund. In
                  cases that aren't as clear (i.e. the selling Member made
                  reasonable effort to fulfill his or her obligations but you
                  aren't happy with the result), we will work with you to find
                  an equitable resolution, but do not guarantee a refund. Any
                  refunds we do offer in such circumstances do not set a
                  precedent for similar situations in the future. Under any
                  circumstance, though, your satisfaction is our utmost priority
                  and we may offer additional services as an alternative to a
                  refund including, but not limited to, MySocial Headshots
                  Content or the ability to transfer the Exchange to another
                  Member on the Service. We may offer a reduced Exchange Fee,
                  and specific terms would be listed in communication you
                  receive from us in such cases. Finally, we may, from time to
                  time, offer refunds, deals, rebates or other reductions to the
                  Exchange Fee for specific Members or Exchanges. It's because
                  we like you. When we make these offers, they will contain
                  their own terms, are specific to the individual or situation
                  defined in those terms, and do not limit or define any section
                  of this Agreement. In cases where you would like to request a
                  refund, you must do it within thirty (30) days. Otherwise, you
                  agree that MySocial Headshots may retain your payment in
                  consideration of access to the Service. If you believe you
                  have been improperly charged and require a refund, please
                  contact us at feedback@MySocial Headshots.com.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  3.4 Tax responsibility
                </p>

                <p className="pb-4">
                  When money changes hands, rest assured that Uncle Sam will
                  want his cut. By using the Service, you may create additional
                  Tax reporting needs on yourself. Such needs are for you and
                  your tax advisors to determine, and you recognize and agree
                  that such needs are your responsibility alone. We cannot and
                  do not offer Tax-related advice or reporting to any Members of
                  the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">4. User Policies</p>

                <p className="pb-4 font-semibold text-xl">
                  4.1 You must be 13 to enter
                </p>

                <p className="pb-4">
                  MySocial Headshots is intended for teens and adults age 13 or
                  older. If you're younger than 13, you'll have to wait until
                  you are 13 to use the Service. Life is like that. By
                  continuing to access and use the Service you represent and
                  warrant that you are 13 or older and able to form legally
                  binding contracts, and that your use of the Service and
                  acceptance of these Terms do not violate any applicable laws,
                  rules, regulations, Tax requirements, or agreements with third
                  parties that bind you. Additionally, if you are younger than
                  18, you'll need parental consent in order to upload your
                  personal information and agree to the Terms and Privacy
                  Policy. You cannot use the Service without such consent.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  4.2 MySocial Headshots doesn't endorse users or third parties
                </p>

                <p className="pb-4">
                  We're all adults here (or at least operating with adult
                  supervision, anyway -- if not you should re-read section 4.1),
                  and as such we expect Members and users to use good judgment
                  and take responsibility for their own actions. MySocial
                  Headshots does not endorse any of its Members. While Members
                  are required to provide accurate information, we do not
                  confirm any Member's identity or credentials. You are
                  responsible for determining the suitability of others who you
                  contact via the Service. We do not guarantee that you will
                  receive a response from another Member, or that you will be
                  satisfied with Exchanges or Member Content. You can read our
                  refund policies in section 3.3. You must evaluate on your own
                  any Member Content you consume for accuracy, quality, and
                  usefulness. Making use of information contained in Member
                  Content outside the Service is done at your own risk. If you
                  believe you have a claim as a result of your interaction with
                  other Members or third parties on the Service, you agree to
                  seek any legal or other remedy solely from the third party
                  that caused you harm and not MySocial Headshots. From time to
                  time, we may offer you products and services from third
                  parties. If we do we may include their information, logos,
                  links, or names on the Site or App. Doing so is not an
                  endorsement of any such third party, its products, or its
                  services. You're on your own and must determine its
                  suitability independently if you decide to utilize its
                  products or services.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  4.3 Terminating and deactivating this Agreement and your
                  account
                </p>

                <p className="pb-4">
                  We may, in our discretion and without liability to you, with
                  or without cause, with or without prior notice and at any
                  time: (a) terminate these Terms or your access to our Service,
                  and (b) deactivate or cancel your MySocial Headshots Account.
                  If this were to happen, we would promptly pay you any amounts
                  we owe you, as we reasonably determine in our discretion or
                  which we are legally obligated to pay you. Likewise, you would
                  remain liable for all amounts you owe to MySocial Headshots
                  hereunder. You may cancel your MySocial Headshots Account at
                  any time by contacting MySocial Headshots at feedback@MySocial
                  Headshots.com. We will typically complete a cancellation
                  within seven (7) business days after our receipt of your
                  notice. Keep in mind that if your MySocial Headshots Account
                  is cancelled, we are not obligated to delete or return to you
                  any Content you have posted to the Service, including, but not
                  limited to, any reviews or Feedback.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  4.4 How you're expected to conduct yourself on the Service
                </p>

                <p className="pb-4">
                  MySocial Headshots can't possibly know the situation of every
                  single user of the Service, and as such we can't guarantee
                  that your use of the Service won't violate laws, rules,
                  regulations, Tax obligations, or contracts that apply to you.
                  So be careful, because by using the Service you take
                  responsibility to ensure your compliance with applicable
                  requirements. Furthermore, there are a number of different
                  types of use of the Service that are prohibited. Collectively,
                  you might summarize these rules as "don't break the law,"
                  "don't steal," and "be honest". However, we've bulleted out
                  the specifics below to cover all the bases. When using our
                  Service, you recognize that it is prohibited to, and you agree
                  that you will not: break the law, or, more specifically,
                  violate any local, state, provincial, national, or other law
                  or regulation, or any order of a court, including, without
                  limitation, zoning restrictions and Tax regulations; use the
                  Service for any commercial or other purposes that are not
                  expressly permitted by these Terms or copy, store or otherwise
                  access any information contained on the Service or Content for
                  purposes not expressly permitted by these Terms; use manual or
                  automated software, devices, scripts robots, or other means or
                  processes to access, "scrape", "crawl" or "spider" any web
                  pages or other services contained in the Service;
                  systematically retrieve data or other Content from our Service
                  to create or compile, directly or indirectly, in single or
                  multiple downloads, a collection, compilation, database,
                  directory or the like, whether by manual methods, through the
                  use of bots, crawlers, or spiders, or otherwise; use automated
                  scripts to collect information or otherwise interact with the
                  Service; attack the Service, or otherwise interfere with or
                  damage our Service, including, without limitation, through the
                  use of viruses, cancel bots, Trojan horses, harmful code,
                  flood pings, denial-of-service attacks, packet or IP spoofing,
                  forged routing or electronic mail address information or
                  similar methods or technology; infringe the rights of any
                  person or entity, including without limitation, its
                  intellectual property, privacy, publicity or contractual
                  rights; use our Service to distribute unsolicited commercial
                  email ("spam") or advertise services unrelated to information
                  and expertise, except as expressly allowed by these Terms; use
                  our Service to transmit, distribute, post or submit any
                  information concerning any other person or entity, including
                  without limitation, photographs of others without their
                  permission, personal contact information or credit, debit,
                  calling card or account numbers; register for more than one
                  MySocial Headshots Account or register for a MySocial
                  Headshots Account on behalf of an individual other than
                  yourself; impersonate any person or entity, or falsify or
                  otherwise misrepresent yourself or your affiliation with any
                  person or entity; "stalk" or harass any other user of our
                  Service or collect or store any personally identifiable
                  information about any other user other than for purposes of
                  transacting as a Member; recruit or otherwise solicit any
                  other Member to join third party services that are competitive
                  to MySocial Headshots, without MySocial Headshots's prior
                  written approval; contact a Member for any purpose other than
                  the purposes explicitly provided in the Terms; circumvent the
                  obligation to pay any fees related to MySocial Headshots's
                  provision of the Service by creating an Exchange outside the
                  Service; attempt to publish an offering with false or
                  misleading information, or with a price you do not intend to
                  honor; post, upload, publish, submit or transmit any Content
                  that: (i) infringes, misappropriates or violates a third
                  party's patent, copyright, trademark, trade secret, moral
                  rights or other intellectual property rights, or rights of
                  publicity or privacy; (ii) violates, or encourages any conduct
                  that would violate, any applicable law or regulation or would
                  give rise to civil liability; (iii) is fraudulent, false,
                  misleading or deceptive; (iv) is defamatory, obscene,
                  pornographic, vulgar or offensive; (v) promotes
                  discrimination, bigotry, racism, hatred, harassment or harm
                  against any individual or group; (vi) is violent or
                  threatening or promotes violence or actions that are
                  threatening to any other person; or (vii) promotes illegal or
                  harmful activities or substances; use, display, mirror or
                  frame the Site or App, or any individual element within the
                  Service, MySocial Headshots's name, any MySocial Headshots
                  trademark, logo or other proprietary information, or the
                  layout and design of any page or form contained on a page,
                  without MySocial Headshots's express written consent; access,
                  tamper with, or use non-public areas of the Site or App,
                  MySocial Headshots's computer systems, or the technical
                  delivery systems of MySocial Headshots's service providers;
                  attempt to probe, scan, or test the vulnerability of any
                  MySocial Headshots system or network or breach any security or
                  authentication measures; avoid, bypass, remove, deactivate,
                  impair, descramble, or otherwise circumvent any technological
                  measure implemented by MySocial Headshots or any of MySocial
                  Headshots's providers or any other third party (including
                  another user) to protect the Service or Collective Content;
                  forge any TCP/IP packet header or any part of the header
                  information in any email or newsgroup posting, or in any way
                  use the Service or Collective Content to send altered,
                  deceptive or false source-identifying information; attempt to
                  decipher, decompile, disassemble or reverse engineer any of
                  the software used to provide the Service or Collective
                  Content; or advocate, encourage, or assist any third party in
                  doing any of the foregoing. We do take violations of the above
                  rules seriously, and may prosecute violators to the fullest
                  extent allowed by law. If we need to involve law enforcement,
                  we will. MySocial Headshots may, but has no obligation to,
                  monitor your access or use of the Service or Collective
                  Content or review or edit any Member Content. MySocial
                  Headshots may, at any time and without prior notice, remove or
                  disable access to any Collective Content that MySocial
                  Headshots, at its sole discretion, considers to be
                  objectionable for any reason, in violation of these Terms or
                  otherwise harmful to the Service, or just plain disgusting.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5. Licenses and Ownership
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5.1 License to use the App
                </p>

                <p className="pb-4">
                  Assuming you comply with the Agreement, MySocial Headshots
                  grants you a non-exclusive, non-transferable, revocable
                  limited license to access and use the App using a mobile or
                  desktop device solely for your own personal use. You agree you
                  won't use the App for any other purpose and that you have no
                  additional rights in the App other than those defined
                  explicitly here in section 5.1.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5.2 License to access and view Content
                </p>

                <p className="pb-4">
                  Assuming you comply with the Agreement, MySocial Headshots
                  grants you a non-exclusive, non-transferable, revocable
                  limited license, to (i) access and view any MySocial Headshots
                  Content solely for your own personal and non-commercial use
                  and (ii) access and view any Member Content to which you are
                  permitted access, solely for your own personal and
                  non-commercial use. You agree that you won't use MySocial
                  Headshots Content or Member Content for any other purpose and
                  that you have no additional rights in MySocial Headshots
                  Content or Member Content than those defined explicitly here
                  in section 5.2. Additionally, don't plagiarize. You agree not
                  to use, copy, adapt, modify, prepare derivative works based
                  upon, distribute, license, sell, transfer, publicly display,
                  publicly perform, transmit, broadcast or otherwise exploit the
                  Service or Collective Content, except as expressly permitted
                  in these Terms.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5.3 Content that you create
                </p>

                <p className="pb-4">
                  MySocial Headshots is designed to allow you to post, upload,
                  publish, submit or transmit Member Content. When you do so,
                  you own that Content, but you also grant MySocial Headshots a
                  worldwide, irrevocable, perpetual, non-exclusive,
                  transferable, royalty-free license, with the right to
                  sublicense, to use, view, copy, adapt, modify, distribute,
                  transfer, publicly display, publicly perform, transmit,
                  stream, broadcast, access, view, and otherwise exploit such
                  Member Content on, through, or by means of the Service.
                  Nothing in these Terms will be deemed to restrict any rights
                  that you may have to use and exploit any such Member Content.
                  You are also responsible for any Member Content that you
                  create or publish on the Service. Thus, when adding that
                  Content to the Service, you're telling us that you have the
                  legal right to do so. More specifically, you represent and
                  warrant that: (i) you either are the sole and exclusive owner
                  of all Member Content that you make available through the
                  Service or you have all rights, licenses, consents and
                  releases that are necessary to grant MySocial Headshots the
                  rights in such Member Content, as contemplated under these
                  Terms; and (ii) neither the Member Content nor your posting,
                  uploading, publication, submission or transmittal of the
                  Member Content or MySocial Headshots's use of the Member
                  Content (or any portion thereof) on, through or by means of
                  the Service will infringe, misappropriate or violate a third
                  party's patent, copyright, trademark, trade secret, moral
                  rights or other proprietary or intellectual property rights,
                  or rights of publicity or privacy, or result in the violation
                  of any applicable law or regulation.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5.4 Even more about licenses
                </p>

                <p className="pb-4">
                  Section 5 describes all applicable licenses granted to you as
                  a Terms-abiding user of our Service. No licenses or rights
                  other than those explicitly defined in this section 5 are
                  granted to you by implication or otherwise under any
                  intellectual property rights owned or controlled by MySocial
                  Headshots or its licensors.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  5.5 We own the Service
                </p>

                <p className="pb-4">
                  The Service and Collective Content are protected by copyright,
                  trademark, and other laws of the United States and foreign
                  countries. Accepting the Terms means you and agree that the
                  Service and Collective Content, including all associated
                  intellectual property rights is the exclusive property of
                  MySocial Headshots and its licensors. You will not remove,
                  alter or obscure any copyright, trademark, service mark or
                  other proprietary rights notices incorporated in or
                  accompanying the Service or Collective Content. That would
                  just be mean.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6. General Provisions
                </p>

                <p className="pb-4 font-semibold text-xl">6.1 Privacy Policy</p>

                <p className="pb-4">
                  You can find MySocial Headshots's Privacy Policy at
                  www.mysocialheadshots.com/privacy for information and notices
                  concerning MySocial Headshots's collection and use of your
                  personal information.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.2 Links to other sites and services
                </p>

                <p className="pb-4">
                  You may find links to third-party websites or resources on the
                  Service or as part of Collective Content. MySocial Headshots,
                  of course, doesn't control the internet, so you'll understand
                  that we are not responsible or liable for: (i) the
                  availability or accuracy of such websites or resources; or
                  (ii) the content, products, or services on or available from
                  such websites or resources. And you'll remember our
                  endorsement policy from section 4.2. Links on our Service to
                  third party websites or resources do not constitute our
                  endorsement of them or their content, products, or services.
                  Consequently, you're at your own risk if you utilize any of
                  the offerings of such third parties.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.3 Notice about proprietary rights and trademarks
                </p>

                <p className="pb-4">
                  Any trademarks, service marks, logos, trade names and other
                  proprietary designations of MySocial Headshots that you see
                  here in the Terms are trademarks or registered trademarks of
                  MySocial Headshots. Any other trademarks, service marks,
                  logos, trade names and other proprietary designations of
                  others that you see in other places on the Site or App are
                  their trademarks or registered trademarks.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.4 Infringing copyright
                </p>

                <p className="pb-4">
                  MySocial Headshots respects copyright law and expects its
                  users to do the same. We're a respectful bunch. It is MySocial
                  Headshots's policy to terminate in appropriate circumstances
                  the MySocial Headshots Accounts of Members who infringe or are
                  believed to be infringing the rights of copyright holders.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.5 Disclaimers galore
                </p>

                <p className="pb-4">
                  And you thought we had disclaimed and waived liability for
                  everything we possibly could at this point. Joking aside, we
                  include this section to protect our business, and it's
                  important for you as a user to know where we consider the line
                  of what we're responsible for and what you and other users
                  are. This section helps define that line. If you use the
                  service, you do it at your own risk. By doing so you are
                  agreeing to several things regarding our Service as follows
                  (we use bold font here to make this section stand out). As you
                  read this, if you aren't a lawyer, you may be confused by the
                  term "warranty." A plain English translation for this is
                  roughly "guarantee," "promise" or "commitment," so when we say
                  that we "disclaim any warranty" regarding something that means
                  we aren't promising that the Service will offer whatever comes
                  next. We provide this translation to help you read the Terms
                  more easily, but it does not limit the legal definition of
                  "warranty" with respect to this Agreement in any way. MySocial
                  Headshots has no obligation to conduct background checks on
                  any Member. The Service and Collective Content are provided
                  "as is," without warranty of any kind, either express or
                  implied. Without limiting the foregoing, MySocial Headshots
                  explicitly disclaims any warranties of merchantability,
                  fitness for a particular purpose, quiet enjoyment or
                  non-infringement, and any warranties arising out of course of
                  dealing or usage of trade. MySocial Headshots makes no
                  warranty that the Service or Collective Content will meet your
                  requirements or be available on an uninterrupted, secure, or
                  error-free basis. MySocial Headshots makes no warranty
                  regarding the quality of any offerings, the Service,
                  Collective Content or any information provided via Exchanges,
                  or the accuracy, timeliness, truthfulness, completeness or
                  reliability of any Collective Content obtained through the
                  Service or any information provided via Exchanges. No advice
                  or information, whether oral or written, obtained from
                  MySocial Headshots or through the Service or Collective
                  Content, will create any warranty not expressly made herein.
                  You are solely responsible for all of your communications,
                  interactions with other users of the Service and with other
                  persons you discover as a result of your use of the Service.
                  You will take reasonable precautions in all such
                  communications and interactions regardless of whether such
                  communications or interactions are organized by MySocial
                  Headshots. MySocial Headshots does not make any attempt to
                  verify the statements of users of the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.6 Limitation of liability
                </p>

                <p className="pb-4">
                  This section includes a fair bit of legalese, so we'll provide
                  plain English translations to help you comprehend the terms.
                  The translations are not intended to, and do not, limit or
                  define the sections they describe in any way, so read the
                  sections in full. We will emphasize the legal provisions using
                  bold font. You acknowledge and agree that, to the maximum
                  extent permitted by law, the entire risk arising out of your
                  access to and use of the Service, Collective Content, or
                  Exchanges via the Site and App, and any contact you have with
                  other users of the Service whether in person, by phone,
                  online, or other means remains with you. Neither MySocial
                  Headshots nor any other party involved in creating, producing,
                  or delivering the Service or Collective Content will be liable
                  for any incidental, special, exemplary, or consequential
                  damages arising out of or in connection with these Terms, the
                  use of or inability to use the Service or Collective Content,
                  or any communications or interactions with other users of the
                  Service or with other persons you discover as a result of your
                  use of the Service. Such limitation of liability holds true
                  regardless of whether such included damages are based on
                  warranty, contract, tort (including negligence), product
                  liability or any other legal theory and regardless of whether
                  MySocial Headshots has been informed of the possibility of
                  such damages, even if a limited remedy set forth herein is
                  found to have failed of its intended purpose. Types of damages
                  covered by this provision include, but are not limited to:
                  lost profits loss of data loss of goodwill service
                  interruption computer damage system failure personal or bodily
                  injury emotional distress Translation: If bad things happen to
                  you (generally referred to as "damages") when you use MySocial
                  Headshots, MySocial Headshots is not responsible, to the
                  greatest extent allowed by law. In no event will MySocial
                  Headshots's aggregate liability arising out of or in
                  connection with these Terms and your use of the Service exceed
                  the amounts you have paid or owe as a Member for Exchanges
                  made via the Service in the twelve (12) month period prior to
                  the event giving rise to the liability, or one hundred U.S.
                  dollars ($100) if no such payments have been made. The
                  limitations of damages set forth above are fundamental
                  elements of the basis of the bargain between you and MySocial
                  Headshots. Some jurisdictions do not allow the exclusion or
                  limitation of liability for consequential or incidental
                  damages, so the above limitation may not apply to you.
                  Translation: If bad things (again "damages") happen to you and
                  MySocial Headshots is found to be responsible and liable, the
                  most you can ask for is your money back that you've spent with
                  MySocial Headshots in the preceding year.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.7 Indemnification
                </p>

                <p className="pb-4">
                  You agree to release, indemnify, and hold MySocial Headshots
                  and its affiliates and subsidiaries, and their officers,
                  directors, employees and agents, harmless from and against any
                  claims, liabilities, damages, losses, and expenses, including
                  but not limited to reasonable legal and accounting fees,
                  arising from (i) your use of the Service or Collective
                  Content; (ii) your violation of these Terms; (iii) your Member
                  Content; (iv) your interaction with any Member, reliance on
                  any information exchanged via the Service; or (v) any
                  violation by you of applicable law or contract terms with a
                  third party to which you are subject. MySocial Headshots shall
                  have the right to control all defense and settlement
                  activities. Bottom line: we aren't responsible for your
                  actions if they get you into trouble, and you'll cover any
                  resulting losses we may suffer.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.8 Export control and restricted countries
                </p>

                <p className="pb-4">
                  You may not and will not use, export, re-export, import, or
                  transfer the App except as authorized by United States law,
                  the laws of the jurisdiction in which you obtained the App,
                  and any other applicable laws. In particular, but without
                  limitation, the App may not be exported or re-exported: (a)
                  into any United States embargoed countries; or (b) to anyone
                  on the U.S. Treasury Department's list of Specially Designated
                  Nationals or the U.S. Department of Commerce's Denied Person's
                  List or Entity List. You'll also recall the representation you
                  make in section 1.1 that you are neither located in a
                  sanctioned country nor a prohibited person. You agree that you
                  will not use the Service for purposes prohibited by U.S. law,
                  including the development, design, manufacture or production
                  of missiles, or nuclear, chemical or biological weapons. Of
                  course you've already agreed several times now in this
                  Agreement not to break the law. Consistent with U.S. embargo
                  restrictions, MySocial Headshots does not permit offerings
                  associated with certain countries.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.9 Give us feedback! And also report misconduct
                </p>

                <p className="pb-4">
                  We welcome and encourage you to provide feedback, comments and
                  suggestions for improvements to the Service (things we like to
                  call "Feedback"). Just email us at feedback@MySocial
                  Headshots.com to send us Feedback. We love hearing from you!
                  Of course, by doing so, you agree that such Feedback will be
                  the sole and exclusive property of MySocial Headshots and you
                  hereby irrevocably assign to MySocial Headshots and agree to
                  irrevocably assign to MySocial Headshots all of your right,
                  title, and interest in and to all Feedback and the
                  intellectual property rights therein, including without
                  limitation all worldwide patent, copyright, trade secret,
                  moral and other proprietary rights. If we're really interested
                  in your Feedback, then at our request and expense you will
                  execute documents and take such further acts as MySocial
                  Headshots may reasonably request to assist MySocial Headshots
                  to acquire, perfect, and maintain its intellectual property
                  rights and other legal protections for the Feedback. In
                  addition, if you feel any user is acting or has acted
                  inappropriately, including but not limited to, anyone who (i)
                  engages in offensive, violent or sexually inappropriate
                  behavior, (ii) you suspect of fraud, or (iii) engages in any
                  other disturbing conduct, you should immediately report such
                  person to the appropriate authorities and to MySocial
                  Headshots. Let's keep the Service clean.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.10 You cannot assign or transfer this Agreement
                </p>

                <p className="pb-4">
                  You may not assign or transfer these Terms, by operation of
                  law or otherwise, without MySocial Headshots's prior written
                  consent. If you try to do so without our consent, such attempt
                  will be null and void and of no effect. MySocial Headshots may
                  assign or transfer these Terms, at its sole discretion,
                  without restriction. Subject to the foregoing, these Terms
                  will bind and inure to the benefit of the parties, their
                  successors and permitted assigns.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.11 We will notify you of changes
                </p>

                <p className="pb-4">
                  Unless otherwise specified herein, any notices or other
                  communications permitted or required hereunder, including
                  those regarding modifications to these Terms, will be in
                  writing and given by MySocial Headshots (i) via email (in each
                  case to the email address associated with your MySocial
                  Headshots Account) or (ii) by posting to the Site or App. If
                  we go the email route, the date of receipt will be deemed the
                  date on which such notice is transmitted.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.12 Severability of the Agreement
                </p>

                <p className="pb-4">
                  This Agreement is intended to govern the agreement between you
                  and MySocial Headshots in accordance with and to the extent
                  permitted by all applicable laws, ordinances, rules, and
                  regulations. Should any provision of these Terms or the
                  application thereof to any person or circumstance, for any
                  reason or to any extent, be determined invalid or
                  unenforceable, but the extent of such invalidity or
                  unenforceability does not destroy the basis of the bargain
                  among the Members as expressed herein, the remainder of this
                  Agreement and the application of such provision to other
                  persons or circumstances shall not be affected thereby, but
                  rather shall be enforced to the greatest extent permitted by
                  law.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.13 New York law governs this Agreement
                </p>

                <p className="pb-4">
                  MySocial Headshots is based in Brooklyn, so we use New York
                  law to govern our operations. Thus, the law of the State of
                  New York (without reference to its choice of law principles)
                  shall govern these Terms. Subject to section 6.14, you and we
                  agree to submit to the personal jurisdiction of a state court
                  located in Kings County, New York for all matters arising in
                  connection with these Terms or your use of the Service.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.14 Dispute resolution process
                </p>

                <p className="pb-4">
                  You and MySocial Headshots agree that any dispute, claim or
                  controversy arising from or relating to this Agreement, the
                  breach, termination, enforcement, interpretation, or validity
                  thereof, or your use of the Service (we'll call them
                  "Disputes") will be settled by binding arbitration, except
                  that each party retains the right to seek injunctive or other
                  equitable relief in a court of competent jurisdiction to
                  prevent the actual or threatened infringement,
                  misappropriation or violation of a party's copyrights,
                  trademarks, trade secrets, patents, or other intellectual
                  property rights. You and we both hereby waive the right to a
                  trial by jury or to participate as a plaintiff or class member
                  in any purported class action or representative proceeding.
                  Further, unless both you and MySocial Headshots otherwise
                  agree in writing, the arbitrator may not consolidate more than
                  one person's claims, and may not otherwise preside over any
                  form of any class or representative proceeding. Should this
                  specific provision be held unenforceable, then the entirety of
                  this "Dispute Resolution" section 6.14 will be deemed void.
                  Except as provided in the preceding sentence, this "Dispute
                  Resolution" section 6.14 will survive any termination of this
                  Agreement. Arbitration Rules and Governing Law. Arbitration
                  will be administered by the American Arbitration Association
                  ("AAA") in accordance with the Commercial Arbitration Rules
                  and the Supplementary Procedures for Consumer Related Disputes
                  (the "AAA Rules") then in effect, except as modified by this
                  "Dispute Resolution" section 6.14. (The AAA Rules are
                  available at adr.org or by calling the AAA at 1-800-778-7879.)
                  The Federal Arbitration Act will govern the interpretation and
                  enforcement of this section. Arbitration Process. A party who
                  desires to initiate arbitration must provide the other party
                  with a written Demand for Arbitration as specified in the AAA
                  Rules. (The AAA provides a form Demand for Arbitration at
                  adr.org. The arbitrator will be either a retired judge or an
                  attorney licensed to practice law in the state of New York and
                  will be selected by the parties from the AAA's roster of
                  consumer dispute arbitrators. If the parties cannot agree upon
                  an arbitrator within seven (7) days of delivery of the Demand
                  for Arbitration, then the AAA will appoint the arbitrator in
                  accordance with the AAA Rules. Arbitration Location and
                  Procedure. Unless you and MySocial Headshots otherwise agree,
                  the arbitration will be conducted in the county where you
                  reside. If your claim does not exceed ten thousand U.S.
                  dollars ($10,000), then the arbitration will be conducted
                  solely on the basis of documents you and MySocial Headshots
                  submit to the arbitrator, unless you request a hearing or the
                  arbitrator determines that a hearing is necessary. If your
                  claim exceeds ten thousand U.S. dollars ($10,000), your right
                  to a hearing will be determined by AAA Rules. Subject to the
                  AAA Rules, the arbitrator will have the discretion to direct a
                  reasonable exchange of information by the parties, consistent
                  with the expedited nature of the arbitration. Arbitrator's
                  Decision. The arbitrator will render an award within the time
                  frame specified in the AAA Rules. The arbitrator's decision
                  will include the essential findings and conclusions upon which
                  the arbitrator based the award. Judgment on the arbitration
                  award may be entered in any court having jurisdiction thereof.
                  The arbitrator's award damages must be consistent with the
                  terms of the "Limitation of Liability" section 6.6 as to the
                  types and the amounts of damages for which a party may be held
                  liable. The arbitrator may award declaratory or injunctive
                  relief only in service of the claimant and only to the extent
                  necessary to provide relief warranted by the claimant's
                  individual claim. If you prevail in arbitration you will be
                  entitled to an award of attorneys' fees and expenses, to the
                  extent provided under applicable law. Fees. Your
                  responsibility to pay any AAA filing, administrative and
                  arbitrator fees will be solely as set forth in the AAA Rules.
                  However, if your claim for damages does not exceed seventy
                  five thousand U.S. dollars ($75,000), MySocial Headshots will
                  pay all such fees unless the arbitrator finds that either the
                  substance of your claim or the relief sought in your Demand
                  for Arbitration was frivolous or was brought for an improper
                  purpose (as measured by the standards set forth in Federal
                  Rule of Civil Procedure 11(b)). Changes. Notwithstanding the
                  provisions of the "Digital Communication and Modifications"
                  section 1.3, should we amend this "Dispute Resolution" section
                  6.14 at a point in which you had already accepted this
                  Agreement and this section, you will be notified of the
                  amendment in accordance with this Agreement. If your intent is
                  to reject any such change, you must do so by sending us
                  written notice (including by email to feedback@MySocial
                  Headshots.com) within 30 days of the date such change became
                  effective, as indicated by the effective date listed at the
                  top of the Terms or the date of MySocial Headshots's email to
                  you notifying you of such change. By rejecting any change, you
                  are agreeing that you will arbitrate any Dispute between you
                  and MySocial Headshots in accordance with the provisions of
                  this "Dispute Resolution" section 6.14 as of the date you
                  first accepted these Terms or accepted any subsequent changes
                  to these Terms.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.15 This Agreement is the entire Agreement
                </p>

                <p className="pb-4">
                  This Agreement constitutes the entire and exclusive
                  understanding and agreement between MySocial Headshots and you
                  regarding the Site, App, Service, Collective Content and any
                  Exchanges made via the Site or App, and this Agreement
                  supersedes and replaces any and all prior oral or written
                  understandings or agreements between you and MySocial
                  Headshots regarding the same. This is the whole shebang.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  6.16 If we don't enforce our rights, it doesn't forego or
                  reduce our rights
                </p>

                <p className="pb-4">
                  If we fail to enforce any right or provision of this
                  Agreement, such action does not constitute a waiver of future
                  enforcement of that right or provision. Such a waiver will be
                  effective only in writing and signed by an authorized
                  representative of MySocial Headshots. Our rights and remedies
                  under this Agreement are cumulative and the exercise of any
                  such right or remedy does not limit our ability to exercise
                  any other such right or remedy.
                </p>

                <p className="pb-4 font-semibold text-xl">
                  7. Get in touch with us
                </p>

                <p className="pb-4">
                  If you have suggestions or questions about these Terms, you
                  can contact us at:
                </p>

                <span className="font-semibold">
                  Email:
                  <a href="#" className="hover:text-[#FBB522]">
                    {" "}
                    info@MySocialHeadshots.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Terms;
