import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import Header from "../components/Header";
import axios from "axios";
// import Footer from '../components/Footer';

function All_students() {
  const [studentDetails, setstudentDetails] = useState([]);
  const getstudentDetails = async () => {
    // console.log();
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/university/students`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: JSON.parse(localStorage.getItem("admin_data_headShots"))
            ?.token,
          email: JSON.parse(localStorage.getItem("admin_data_headShots"))
            ?.email,
        },
      });
      //   console.log(response, "dashboard details");
      if (response.status == 200) {
        setstudentDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //   console.log(studentDetails, "student details");
  const getDate = (backendDate) => {
    const formattedDate = new Date(backendDate).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };
  useEffect(() => {
    getstudentDetails();
  }, []);
  return (
    <>
      <div className="container-fluid layout">
        <main>
          <Container fluid>
            <Row>
              <Col className="col-12 py-3 txt-brown text-center">
                <h2 className="fs-30 fw-600">List of Student Registered</h2>
              </Col>
            </Row>
          </Container>

          <Table responsive className="list-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>College</th>
                <th>Graduation Year</th>
                <th>last activity</th>
              </tr>
            </thead>
            <tbody>
              {studentDetails?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item?.firstName}&nbsp;{item?.lastName}
                  </td>
                  <td>{item?.collage}</td>
                  <td>{item?.graduationYear}</td>
                  <td>{getDate(item?.recentActivity)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </main>
      </div>
    </>
  );
}

export default All_students;
