import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import axios from "axios";
import Loader from "../components/Loader";

function Annual() {
  const [profileDetails, setprofileDetails] = useState({
    firstName: "",
    lastName: "",
    collage: "",
    grad_year: "",
    major: "",
    email: "",
    title: "",
    phone: "",
    Lead_Comments: "",
  });
  const [accessTokenData, setaccessTokenData] = useState({});
  const [savedResponse, setsavedResponse] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileDetails({ ...profileDetails, [id]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
    if (id === "email") {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    if (id === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  console.log(profileDetails, "profile details");
  function validateForm() {
    let valid = true;
    let newErrors = {};

    if (profileDetails.firstName == "") {
      newErrors.firstName = "Please Enter First Name";
      valid = false;
    }

    if (profileDetails.lastName == "") {
      newErrors.lastName = "Please Enter Last Name";
      valid = false;
    }
    if (profileDetails.title == "") {
      newErrors.title = "Please Enter Title";
      valid = false;
    }
    if (profileDetails.email == "" || !validateEmail(profileDetails.email)) {
      newErrors.email = "Please Enter Valid Email";
      valid = false;
    }
    if (profileDetails.phone == "") {
      newErrors.phone = "Please enter a valid phone number";
      valid = false;
    }
    if (profileDetails.collage == "") {
      newErrors.collage = "Please Enter Organization";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  }
  const generateZohoAccessToken = async () => {
    try {
      // const response = await axios.post(
      //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
      // );
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
      });
      localStorage.setItem(
        "headshot_access_token",
        response?.data?.access_token
      );
      return response?.data?.access_token;

      // console.log(response, "response");
      // setaccessTokenData(response?.data);
      // Store access_token2 in state or do something with it
    } catch (error) {
      // Handle error
    }
  };
  const submitButton = async () => {
    if (!validateForm()) {
      return;
    }
    setloading(true);
    const access_token1 = await generateZohoAccessToken();
    try {
      setloading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/zoho/annual`,
        {
          data: {
            First_Name: profileDetails.firstName,
            Last_Name: profileDetails.lastName,
            Email: profileDetails.email,
            Company: profileDetails.collage,
            Phone: profileDetails.phone,
            Title: profileDetails.title,
            Lead_Product_Service_Interested_In: "MySocial Headshots",
            Lead_Product_Focus: "Non-Profit, Annual Giving, Political",
            access_token: access_token1,
          },
        }
      );
      // console.log(response, "response");
      if (response.status == 200) {
        setsavedResponse(true);
        setprofileDetails({
          firstName: "",
          lastName: "",
          collage: "",
          grad_year: "",
          major: "",
          email: "",
          title: "",
          phone: "",
          Lead_Comments: "",
        });
        setloading(false);
        setTimeout(() => {
          setsavedResponse(false);
        }, 2000);
        seterrorMessage("");
      }
      // Handle response
    } catch (error) {
      setloading(false);
      console.log(error, "error");
      seterrorMessage(error?.response.data.message);
      // Handle error
    }
  };
  // useEffect(() => {
  //   // Generate Zoho access token
  //   if (!localStorage.getItem("headshot_access_token")) {
  //     const generateZohoAccessToken = async () => {
  //       try {
  //         // const response = await axios.post(
  //         //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
  //         // );
  //         const response = await axios({
  //           url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
  //         });
  //         localStorage.setItem(
  //           "headshot_access_token",
  //           response?.data?.access_token
  //         );

  //         // console.log(response, "response");
  //         // setaccessTokenData(response?.data);
  //         // Store access_token2 in state or do something with it
  //       } catch (error) {
  //         // Handle error
  //       }
  //     };

  //     generateZohoAccessToken();
  //   }
  // }, []);

  return (
    <>
      <Header2 />

      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-50 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-annual.png"
        />
        <div className="container mx-auto px-4">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-24">
              Annual Giving, Non-Profit and <br /> Political Administrators
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FCFCFC] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Reach Your Supporters & Contributor’s Social Networks!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full">
            <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 font-medium">
                <p className="font-semibold text-xl">
                  Think back to election day.
                </p>
                <p className="pt-4 text-xl">
                  After voting you likely had a chance to add a sticker to your
                  clothes that said something like, “I Voted!”
                </p>
                <p className="pt-4 text-xl">
                  Now your supporters, contributors, and members can add
                  statements and your logo, emblem, or mascot to their social
                  media headshots that will show their passion, commitment, and
                  support for your organization.  It’s an effortless way to give
                  them recognition in a highly scalable, viral, and professional
                  way.
                </p>
                <p className="pt-4 text-xl">
                  Not only will MySocial Headshots give you an opportunity to
                  increase participation in your events, activities, and
                  fundraising programs, but you will increase engagement too.
                </p>
                <p className="pt-4 text-xl">
                  You know better than us what statements you would like to see
                  shared on LinkedIn, Facebook, Twitter, Instagram, Tiktok and
                  other social media channels, but consider some of these:
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="w-full font-medium text-xl">
                <ul className="list-disc pl-5 space-y-3">
                  <li>I just gave to ABC</li>
                  <li>Proud Contributor to ABC</li>
                  <li>Join Me in Supporting ABC</li>
                  <li>Proud Supporter of ABC</li>
                  <li>Standing Strong with ABC: Join Me!</li>
                  <li>I Believe in ABC: Support their Efforts</li>
                  <li>Making a Positive Impact with ABC</li>
                  <li>Proudly Supporting ABC’s Mission</li>
                  <li>Supporting ABC: A Cause Close to My Heart</li>
                  <li>Advocating for ABC: Join the Movement</li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="h-[100%] max-h-[500px]">
                <img src="images/group-3.png" className="w-full h-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto items-center h-full py-16 text-[#4E2A14] px-4">
        <p className="text-5xl font-bold pb-8 text-center ">
          It’s simple to do.
        </p>
        <div className="flex justify-center">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
        <div className="flex flex-wrap pt-10 w-full">
          <div className="w-full md:w-4/12 p-2 flex justify-center items-center">
            <div className="h-[280] w-[320px]">
              <img src="images/group-2.png" className="w-full h-full" />
            </div>
          </div>
          <div className="w-full md:w-8/12 p-2 flex justify-center items-center">
            <div className="font-medium text-xl">
              <p className="">
                We’ll create a customized registration page where your
                supporters and contributors can upload their photo, select any
                approved statement and organization logo. You simply send them a
                link to access the page.  It’s simple for them to take advantage
                of this opportunity to get recognition from their friends,
                family, business associates, and members of their synagogue,
                mosque, or chuch.
              </p>
              <p className="pt-4">
                All they have to do is upload the photo they want to use, select
                the statement and logo they want, download the enhanced photo
                and then upload it to their social media channels.  You will
                have access to a dashboard that will list the number of
                participants, the frequency of visits, the most popular
                statement, which social media channels they are sharing the
                enhanced graphics.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14] px-4">
        <div className="container mx-auto py-16">
          <p className="text-5xl leading-none font-bold pb-8 text-center ">
            Learn how to offer this benefit!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="py-10">
            <p className="font-medium text-xl">
              Take a moment to share your contact information and we will send
              you additional details.
            </p>
          </div>

          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-5/12 p-2 flex justify-center items-center sm:order-1">
              <div className="w-72">
                <img src="images/abc.png" className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-7/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl w-full">
                <form>
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      First Name *
                    </span>
                    <input
                      type="text"
                      name="fname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="First name"
                      value={profileDetails.firstName}
                      id="firstName"
                      onChange={handleChange}
                    />
                  </label>
                  {errors.firstName && (
                    <p className="error-message text-danger mb-3">
                      {errors.firstName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Last Name *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.lastName}
                      id="lastName"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Last name"
                    />
                  </label>
                  {errors.lastName && (
                    <p className="error-message text-danger mb-3">
                      {errors.lastName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Title *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.title}
                      id="title"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your title"
                    />
                  </label>
                  {errors.title && (
                    <p className="error-message text-danger mb-3">
                      {errors.title}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Email *
                    </span>
                    <input
                      type="email"
                      name="lname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your email"
                      value={profileDetails?.email}
                      id="email"
                      onChange={handleChange}
                      onBlur={handleChange2}
                    />
                  </label>
                  {errors.email && (
                    <p className="error-message text-danger mb-3">
                      {errors.email}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Phone * (Enter phone without dash)
                    </span>
                    <input
                      type="number"
                      name="lname"
                      value={profileDetails?.phone}
                      id="phone"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your phone"
                    />
                  </label>
                  {errors.phone && (
                    <p className="error-message text-danger mb-3">
                      {errors.phone}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Organization *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your organization"
                      value={profileDetails?.collage}
                      onChange={handleChange}
                      id="collage"
                    />
                  </label>
                  {errors.collage && (
                    <p className="error-message text-danger mb-3">
                      {errors.collage}
                    </p>
                  )}
                  {savedResponse ? (
                    <div className=" mb-1 fw-bold text-center">
                      Details Submitted Successfully!
                    </div>
                  ) : (
                    <div className=" mb-1 fw-bold text-center">
                      {errorMessage}
                    </div>
                  )}
                  <div>
                    <button
                      type="button"
                      className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                      disabled={loading}
                      onClick={submitButton}
                    >
                      {loading ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Annual;
