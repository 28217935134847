import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AvatarEditor from "react-avatar-editor";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import htmlToImage from "html-to-image";
import logo from "./logo.png";
import Loader from "../components/Loader";

function CreateSingle() {
  const params = useParams();
  const [collageDetails, setcollageDetails] = useState([]);
  const [statementDetails, setstatementDetails] = useState([]);
  const [profileDetails, setprofileDetails] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageOriginal, setSelectedImageOriginal] = useState(null);
  const [statementPosition, setstatementPosition] = useState("left");
  const [selectedStatement, setselectedStatement] = useState("");
  const [selectedStatementColor, setselectedStatementColor] = useState("");
  const [selectedType, setselectedType] = useState("no");
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setselectedCategory] = useState("");
  const [croppedImage, setcroppedImage] = useState(null);
  const [croppedImageRaw, setcroppedImageRaw] = useState(null);
  const [allRotator, setallRotator] = useState(0);
  const [logoLeft, setLogoLeft] = useState("3");
  const [logoTop, setLogoTop] = useState(54);
  const [logoRotator, setLogoRotator] = useState(0);
  const [gradiantRotator, setgradiantRotator] = useState(248);
  const [gradiantRotatorSize, setgradiantRotatorSize] = useState(62);
  const [statementRotator, setstatementRotator] = useState(184);
  const [bgColor1, setBgColor1] = useState("orange");
  const [bgColor2, setBgColor2] = useState("purple");
  const [text, setText] = useState("");
  const [singleData, setsingleData] = useState(null);
  const [submitting, setsubmitting] = useState(false);
  const [chooseStatementError, setchooseStatementError] = useState(false);
  const [imageLoader, setimageLoader] = useState(true);
  const [url, seturl] = useState("");
  const [categoryFetched, setcategoryFetched] = useState(false);
  const handleLogoLeftChange = (e) => {
    setLogoLeft(e.target.value);
  };

  const handleLogoTopChange = (e) => {
    setLogoTop(e.target.value);
  };

  const handleLogoRotatorChange = (e) => {
    setLogoRotator(e.target.value);
  };
  const handleGradientRotatorChange = (e) => {
    setgradiantRotator(e.target.value);
  };
  const handleGradientRotatorSizeChange = (e) => {
    setgradiantRotatorSize(100 - parseInt(e.target.value));
  };
  const handleRotatorStatementChange = (e) => {
    setstatementRotator(e.target.value);
  };

  const handleBgColor1Change = (e) => {
    setBgColor1(e.target.value);
  };

  const handleBgColor2Change = (e) => {
    setBgColor2(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const getCircularText = (
    text,
    diameter,
    startAngle,
    align,
    textInside,
    inwardFacing,
    fName,
    fSize,
    kerning
  ) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const clockwise = align === "right" ? 1 : -1;
    startAngle = startAngle * (Math.PI / 180);

    const div = document.createElement("div");
    div.innerHTML = text;
    div.style.position = "absolute";
    div.style.top = "-10000px";
    div.style.left = "-10000px";
    div.style.fontFamily = fName;
    div.style.fontSize = fSize;
    document.body.appendChild(div);
    const textHeight = div.offsetHeight;
    document.body.removeChild(div);

    if (!textInside) diameter += textHeight * 2;

    canvas.width = diameter;
    canvas.height = diameter;
    ctx.fillStyle = "#fff";
    ctx.font = `${fSize} ${fName}`;

    if (
      (["left", "center"].indexOf(align) > -1 && inwardFacing) ||
      (align === "right" && !inwardFacing)
    ) {
      text = text.split("").reverse().join("");
    }

    ctx.translate(diameter / 2, diameter / 2);
    startAngle += Math.PI * !inwardFacing;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    if (align === "center") {
      for (let j = 0; j < text.length; j++) {
        const charWid = ctx.measureText(text[j]).width;
        startAngle +=
          ((charWid + (j === text.length - 1 ? 0 : kerning)) /
            (diameter / 2 - textHeight) /
            2) *
          -clockwise;
      }
    }

    ctx.rotate(startAngle);

    for (let j = 0; j < text.length; j++) {
      const charWid = ctx.measureText(text[j]).width;
      ctx.rotate((charWid / 2 / (diameter / 2 - textHeight)) * clockwise);
      ctx.fillText(
        text[j],
        0,
        (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2)
      );
      ctx.rotate(
        ((charWid / 2 + kerning) / (diameter / 2 - textHeight)) * clockwise
      );
    }

    return canvas;
  };

  // const handleSaveClick = () => {
  //   const canvasWrapper = document.querySelector(".canvas-wrapper");

  //   // Use dom-to-image to convert the div into an image
  //   domtoimage
  //     .toBlob(canvasWrapper)
  //     .then((blob) => {
  //       // Create a temporary link element
  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = URL.createObjectURL(blob);
  //       downloadLink.download = "canvas_image.png"; // Set the desired filename

  //       // Trigger the download
  //       downloadLink.click();
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occur during the conversion
  //       console.error("Error converting div to image:", error);
  //     });
  // };
  async function fetchCategories() {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/headshot/categories/all`,
        method: "GET",
        headers: {
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });

      if (response.status == 200) {
        // console.log(response?.data?.statements);
        setcategoryFetched(true);
        setCategories(response?.data?.categories || []);
      }
    } catch (error) {
      alert("Error while fetching Statements:" + error);
    }
  }
  const handleSaveClick = async () => {
    if (selectedStatement == "") {
      setchooseStatementError(true);
      return;
    } else {
      setchooseStatementError(false);
    }
    setsubmitting(true);
    const canvasWrapper = document.querySelector(".canvas-wrapper");
    console.log(canvasWrapper, "text-center py-4 fs-40 fw-600 cursor-pointer");

    // Use dom-to-image to convert the div into an image
    try {
      domtoimage
        .toBlob(
          canvasWrapper,
          {
            // useCORS: true,
            scale: window.devicePixelRatio,
            logging: false,
            scrollX: 0,
            scrollY: -window.scrollY,
            width: canvasWrapper.offsetWidth,
            height: canvasWrapper.offsetHeight,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            allowTaint: true,
            cacheBust: true,
          }
          // { cacheBust: true }
        )
        .then((blob) => {
          console.log(blob, "blob");
          const formData = new FormData();
          formData.append(
            "token",
            localStorage.getItem("user_token_headShots")
          );
          formData.append(
            "email",
            localStorage.getItem("user_email_headShots")
          );
          formData.append("collageId", profileDetails?.graduationCollege);
          formData.append("statementId", selectedStatement);
          formData.append("categoryId", selectedCategory?selectedCategory:"");
          formData.append("position", statementPosition);
          formData.append("logo", selectedType != "no" ? true : false);
          formData.append("logoUrl", selectedType);
          formData.append("photo", blob, "image.jpg");

          formData.append("all_rotator", allRotator);
          formData.append("logo_left", logoLeft);
          formData.append("logo_top", logoTop);
          formData.append("logo_rotator", logoRotator);
          formData.append("gradiant_rotator", gradiantRotator);
          formData.append("gradient_size", gradiantRotatorSize);
          formData.append("statement_rotator", statementRotator);
          console.log(formData, "form data");
          // Send the image data to the backend using an HTTP request

          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/headshot/${params?.id}`,
              formData
            )
            .then((response) => {
              setsubmitting(false);
              console.log(response, "response");
              // Handle the response from the backend
              navigate("/myheadshot");
            })
            .catch((error) => {
              setsubmitting(false);
              console.log(error);
              // Handle the error
            });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      setsubmitting(false);

      console.log(error);
    }
  };

  const roundedText = (e) => {
    if (imageLoader) {
      return;
    }
    const canvasRotator = document.querySelector(".canvas-rotator");
    const canvas = canvasRotator.querySelector("canvas");
    canvas?.remove();
    const circularText = getCircularText(
      e.target.value,
      272,
      statementRotator,
      "center",
      true,
      false,
      "Arial",
      "16.5pt",
      0.5
    );
    canvasRotator.appendChild(circularText);
  };

  const rotator = (e) => {
    // const canvasRotator = document.querySelector(".canvas-rotator");
    // console.log(e.target.value, "all rotator");
    // canvasRotator.style.transform = `rotate(${e.target.value}deg)`;
    setallRotator(e.target.value);
  };

  const ref = useRef();
  const navigate = useNavigate();
  const getCollageDetails = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile/collages`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
          universityid: JSON.parse(localStorage.getItem("university_data"))?.id,
        },
      });
      //   console.log(response, "collage details");
      setcollageDetails(response.data.collages);
    } catch (error) {
      console.log(error);
    }
  };
  const getstatementDetails = async (categoryid) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/headshot/statements/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
          categoryId:
            categoryid && categoryid !== "" ? parseInt(categoryid) : null,
        },
      });
      // console.log(response,"collage details")
      setstatementDetails(response?.data?.statments);
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDetails = async () => {
    // console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/profile`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      setprofileDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSingleDetails = async () => {
    // console.log(localStorage.getItem("user_email_headShots"), "email");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/headshot/${params.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("user_token_headShots"),
          email: localStorage.getItem("user_email_headShots"),
        },
      });
      console.log(response, "response");
      let item = response?.data?.headshot;
      setLogoLeft(item?.logo_left);
      setLogoTop(item?.logo_top);
      setLogoRotator(item?.logo_rotator);
      setallRotator(item?.all_rotator);
      setselectedStatement(item?.statementId);
      setgradiantRotator(item?.gradiant_rotator);
      setselectedType(item?.logo ? item?.logoUrl : "no");
      setselectedCategory(item?.categoryId);
      setgradiantRotatorSize(item?.gradient_size);
      setstatementRotator(item?.statement_rotator);
      setsingleData(response?.data?.headshot);
      setTimeout(() => {
        setimageLoader(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = (event) => {
    setSelectedImageOriginal(event.target.files[0]);
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
  };
  //   console.log(statementRotator, "statement rotator");
  //   console.log(params, "params");

  //   console.log(collageDetails, "collage details");
  //   collage name
  const getCollageName = () => {
    if (
      collageDetails?.length == 0 ||
      Object.keys(profileDetails || {}).length == 0
    ) {
      return "";
    } else {
      let name = collageDetails.find(
        (item) => item?.id == profileDetails?.graduationCollege
      )?.collageName;
      return name;
    }
  };
  const getCollageLogo = () => {
    if (
      collageDetails?.length == 0 ||
      Object.keys(profileDetails || {}).length == 0
    ) {
      return "";
    } else {
      let logo = JSON.parse(
        localStorage.getItem("university_data")
      )?.university_logos?.find((item) => item?.id == selectedType)?.logo;
      console.log(logo, "logo foud");
      return `${process.env.REACT_APP_BASE_URL}/${selectedType}`;
    }
  };
  useEffect(() => {
    if (!imageLoader) {
      if (statementDetails?.length > 0) {
        if (selectedStatement != "") {
          let detail = statementDetails?.find(
            (item) => item?.id == selectedStatement
          );
          let statementName = detail?.statement;
          console.log(detail, "detail");
          let colorCode = detail?.colorCode;
          console.log(statementName, "statementName code");
          if (statementName) {
            roundedText({
              target: {
                value: statementName,
              },
            });
          }
          setText(statementName);
          setselectedStatementColor(colorCode);
        } else {
          setText("");
        }
      }
    }
  }, [
    selectedStatement,
    statementDetails?.length,
    statementRotator,
    imageLoader,
  ]);
  // useEffect(() => {
  //   if (selectedImageOriginal) {
  //     handleSave(selectedStatement, statementPosition, "from_useEffect");
  //   }
  // }, [statementPosition, selectedImageOriginal, selectedStatement]);

  // console.log(statementPosition);
  // console.log(selectedImage, "selected image");
  // console.log(croppedImage, "croppedImage");
  console.log(selectedStatement,"selected stateemtn...")
  const canvasRef = useRef(null);

  useEffect(() => {
    getCollageDetails();
    getProfileDetails();
    getSingleDetails();
    fetchCategories();
  }, []);
  useEffect(() => {
    if (categoryFetched) {
      getstatementDetails(selectedCategory);
    }
  }, [selectedCategory, categoryFetched]);

  return (
    <>
      <div className="drop-header">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand
              className="cursor-pointer"
              onClick={() => {
                localStorage.removeItem("user_email_headShots");
                localStorage.removeItem("user_token_headShots");
                localStorage.removeItem("university_data");
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src={logo}></img>
            </Navbar.Brand>
            <div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${
                  JSON.parse(localStorage.getItem("university_data"))?.logo
                }`}
                width={50}
                height={50}
              />
            </div>
            <div className="px-2 fw-600">
              {
                JSON.parse(localStorage.getItem("university_data"))
                  ?.universityName
              }
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto nav">
                <Nav.Link
                  // href="#home"
                  // className="nav-item"
                  className={`nav-item active`}
                  onClick={() => {
                    navigate("/create");
                    window.scrollTo(0, 0);
                  }}
                >
                  Create
                </Nav.Link>
                <Nav.Link
                  href="#link"
                  className="nav-item"
                  onClick={() => {
                    navigate("/myheadshot");
                    window.scrollTo(0, 0);
                  }}
                >
                  My Headshots
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  onClick={() => {
                    navigate("/my_profile");
                    window.scrollTo(0, 0);
                  }}
                >
                  Profile
                </Nav.Link>
                <Nav.Link
                  className="nav-item"
                  onClick={() => {
                    localStorage.removeItem("user_email_headShots");
                    localStorage.removeItem("user_token_headShots");
                    navigate("/");
                  }}
                >
                  Logout
                </Nav.Link>
                <span className="nav-indicator"></span>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="wrapper-box">
        <Container className="txt-brown py-4">
          <Row>
            <Col className="col-12 text-center pb-4">
              <h2 className="fw-600 fs-30">
                {" "}
                Here's the first step to make your LinkedIn and social media
                profiles stand out!
              </h2>
            </Col>
            <Col className="col-12 col-lg-5">
              <div className=" d-flex justify-content-center">
                <div className="choose-box d-flex justify-content-center align-items-center">
                  {imageLoader ? (
                    <Loader />
                  ) : (
                    <div className="img-box">
                      <div
                        className="upload-box"
                        style={{ marginLeft: "-10px", marginTop: "-10px" }}
                      >
                        <div className="canvas-wrapper">
                          <div
                            className="canvas-rotator"
                            style={{
                              transform: `rotate(${allRotator}deg)`,
                            }}
                          >
                            {selectedType != "no" && (
                              <div
                                className="canvas-logo"
                                style={{
                                  left: `${logoLeft}%`,
                                  top: `${logoTop}%`,
                                  transform: `rotate(${logoRotator}deg)`,
                                }}
                              >
                                <img src={getCollageLogo()} />
                              </div>
                            )}
                            <div
                              className="canvas-gradientbg"
                              style={{
                                transform: `rotate(${gradiantRotator}deg)`,
                                backgroundImage: `conic-gradient(transparent 0%${gradiantRotatorSize}%, ${selectedStatementColor}, ${selectedStatementColor}, ${selectedStatementColor}, ${selectedStatementColor}, transparent)`,
                              }}
                            ></div>
                          </div>
                          <img
                            className="canvas-avatar"
                            src={`${process.env.REACT_APP_BASE_URL}/${singleData?.rawPhoto}`}
                            // src={`https://m.media-amazon.com/images/I/81XyIoZ8+HL._AC_UF1000,1000_QL80_.jpg`}
                            // loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-12 col-lg-5">
              {/* <div className="mb-3">
                <label className="form-label fw-600">College Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={getCollageName()}
                  disabled
                />
              </div> */}
              {categories?.length > 0 && (
                <div className="mb-3">
                  <label className="form-label fw-600">
                    After saving photo choose a category
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={selectedCategory}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        setselectedStatement("");
                        setstatementDetails([]);
                      }
                      setselectedCategory(e.target.value);
                    }}
                  >
                    <option value="">Choose Statement Category</option>
                    {categories?.map((item) => (
                      <option value={item?.id}>{item?.category}</option>
                    ))}
                  </select>
                </div>
              )}
              <div className="mb-3">
                <label className="form-label fw-600">
                  {" "}
                  After saving photo choose a statement
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={selectedStatement}
                  onChange={(e) => setselectedStatement(e.target.value)}
                >
                  <option value="">Choose Statement</option>
                  {statementDetails?.map((item) => (
                    <option value={item?.id}>{item?.statement}</option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label fw-600">
                  Now choose Logo/Emblem/Mascot
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={selectedType}
                  onChange={(e) => setselectedType(e.target.value)}
                >
                  <option
                    value={
                      JSON.parse(localStorage.getItem("university_data"))?.logo
                    }
                  >
                    Primary Logo
                  </option>
                  {JSON.parse(
                    localStorage.getItem("university_data")
                  )?.university_logos?.map((logo, logo_index) => (
                    <option value={logo?.url}>{logo?.name}</option>
                  ))}
                  <option value="no">no</option>
                </select>
              </div>
              <div className="row pb-3">
                <div className="col-6 ">
                  <div>Rotate All</div>
                </div>
                <div className="col-6 ">
                  <div>
                    <input
                      type="range"
                      max="360"
                      className="w-100"
                      value={allRotator}
                      onInput={rotator}
                    />
                  </div>
                </div>
              </div>
              <>
                {selectedType != "no" && (
                  <>
                    <div className="row pb-3">
                      <div className="col-6 ">
                        <div>Logo Move Left & Right</div>
                      </div>
                      <div className="col-6 ">
                        <div>
                          <input
                            type="range"
                            max="100"
                            className="w-100"
                            value={logoLeft}
                            onInput={handleLogoLeftChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-6 ">
                        <div>Logo Move Up & Down</div>
                      </div>
                      <div className="col-6 ">
                        <div>
                          <input
                            type="range"
                            max="100"
                            className="w-100"
                            value={logoTop}
                            onInput={handleLogoTopChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-6 ">
                        <div>Logo Spin</div>
                      </div>
                      <div className="col-6 ">
                        <div>
                          <input
                            type="range"
                            max="360"
                            className="w-100"
                            value={logoRotator}
                            onInput={handleLogoRotatorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>

              <div className="row pb-3">
                <div className="col-6 ">
                  <div>Ribbon Rotate</div>
                </div>
                <div className="col-6 ">
                  <div>
                    <input
                      type="range"
                      max="360"
                      className="w-100"
                      value={gradiantRotator}
                      onInput={handleGradientRotatorChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col-6 ">
                  <div>Ribbon Expand</div>
                </div>
                <div className="col-6 ">
                  <div>
                    <input
                      type="range"
                      max="100"
                      className="w-100"
                      value={100 - parseInt(gradiantRotatorSize)}
                      onInput={handleGradientRotatorSizeChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row pb-3">
                <div className="col-6 ">
                  <div>Statement Move</div>
                </div>
                <div className="col-6 ">
                  <div>
                    <input
                      type="range"
                      max="360"
                      className="w-100"
                      value={statementRotator}
                      onInput={handleRotatorStatementChange}
                    />
                  </div>
                </div>
              </div>
              {chooseStatementError && (
                <div className="text-danger m-2 text-center">
                  Please choose statement
                </div>
              )}
              <div className="mt-4">
                <button
                  type="button"
                  disabled={submitting}
                  className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                  onClick={() => {
                    handleSaveClick();
                  }}
                >
                  {submitting ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    "Save to Your Profile"
                  )}
                </button>
              </div>
            </Col>
            <p className="text-center py-4 fs-30 fw-600 cursor-pointer">
              Get helpful “selfie” and portrait photo tips and in link below!
            </p>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default CreateSingle;
const CircularImageCrop = ({
  image,
  croppedImage,
  setcroppedImage,
  croppedImageRaw,
  setcroppedImageRaw,
}) => {
  const editorRef = useRef();

  const croppedSize = 300; // Specify the desired size of the cropped image

  // ...
  const handleCrop = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const croppedImage = canvas.toDataURL();

      // Use the cropped image for further processing or saving
      // Apply circular mask to the cropped image
      applyCircularMask(croppedImage, croppedSize);
    }
  };
  const applyCircularMask = (imageDataUrl, size) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = size;
    canvas.height = size;

    const radius = size / 2;

    context.beginPath();
    context.arc(radius, radius, radius, 0, 2 * Math.PI, false);
    context.clip();

    const image = new Image();
    image.onload = () => {
      context.drawImage(image, 0, 0, size, size);

      const circularImage = canvas.toDataURL();
      // console.log(circularImage);
      setcroppedImage(circularImage);
      setcroppedImageRaw(circularImage);
    };
    image.src = imageDataUrl;
  };

  return (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={250}
        height={250}
        border={50}
        borderRadius={250}
        color={[255, 255, 255, 0.6]} // Color of the crop border
        scale={1.2} // Zoom factor
        rotate={0} // Rotation angle in degrees
      />
      <div className="d-flex-justify-content-center-align-items-center w-100 m-0 p-0">
        <div style={{ marginTop: "-20px" }}>
          <button
            type="button"
            className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2"
            onClick={handleCrop}
          >
            Adjust & Save Crop
          </button>
        </div>
      </div>
    </div>
  );
};
