import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function SignUpEmail() {
  const [emailDetails, setemailDetails] = useState({
    eyeShown: false,
  });
  const [regiterDetailsError, setregiterDetailsError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    passwordValid: true,
  });
  const [signingIn, setsigningIn] = useState(false);
  const [loginError, setloginError] = useState({
    status: false,
    message: "",
  });
  const [showRules, setShowRules] = useState(false);
  const [passwordValidation, setpasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });

  const navigate = useNavigate();

  const handleDetails = (e) => {
    if (e.target.id === "password") {
      let value = e.target.value;
      if (value.length > 0) {
        setShowRules(true);
      } else {
        setShowRules(false);
      }
      setpasswordValidation({
        length: value.length >= 8,
        lowercase: /[a-z]/.test(value),
        uppercase: /[A-Z]/.test(value),
        number: /\d/.test(value),
        special: /[!@#\$%^&*?_~]/.test(value),
      });
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: true,
      });
    }

    setemailDetails({ ...emailDetails, [e.target.id]: e.target.value });

    if (e.target.id == "first_name") {
      setregiterDetailsError({
        ...regiterDetailsError,
        first_name: false,
      });
    }
    if (e.target.id == "last_name") {
      setregiterDetailsError({
        ...regiterDetailsError,
        last_name: false,
      });
    }
    if (e.target.id == "email") {
      setregiterDetailsError({
        ...regiterDetailsError,
        email: false,
      });
    }
  };
  const checkPasswordValidation = () => {
    if (
      passwordValidation.length &&
      passwordValidation.lowercase &&
      passwordValidation.uppercase &&
      passwordValidation.number &&
      passwordValidation.special
    ) {
      return true;
    } else {
      return false;
    }
  };
  const postEmailDetails = async () => {
    setloginError({
      status: false,
      message: "",
    });
    if (checkPasswordValidation()) {
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: true,
      });
    } else {
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: false,
      });
      return;
    }
    setsigningIn(true);

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          firstName: emailDetails?.first_name,
          lastName: emailDetails?.last_name,
          email: emailDetails?.email,
          password: emailDetails?.password,
        },
      });

      console.log(response, "response");
      if (response?.status == 200) {
        setsigningIn(false);
        localStorage.setItem("user_email_headShots", response?.data?.email);
        localStorage.setItem("user_token_headShots", response?.data?.token);
        navigate("/basic_details");
      } else {
        setsigningIn(false);
        setloginError({
          status: true,
          message: response?.data?.message,
        });
      }
    } catch (error) {
      setsigningIn(false);
      setloginError({
        status: true,
        message: error?.response?.data?.message,
      });
      console.log(error);
    }
  };

  // console.log(emailDetails, "email details");
  useEffect(() => {
    if (localStorage.getItem("user_token_headShots")) {
      navigate("/myheadshot");
    }
  }, []);
  return (
    <>
      <Header />
      <div className="hero-box">
        <img src="images/home-banner.png" className="hero-poster" />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center txt-brown">
              <div className="login-form">
                <h2 className="fs-22 fw-700 text-center pb-4">SIGN UP</h2>
                <div className="mb-3">
                  <label className="form-label fw-600">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    class="form-control"
                    placeholder="Enter your First Name"
                    value={emailDetails?.first_name}
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    class="form-control"
                    placeholder="Enter your Last Name"
                    value={emailDetails?.last_name}
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="Enter your email"
                    value={emailDetails?.email}
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">Password</label>
                  <div className="relative">
                    <input
                      type={emailDetails.eyeShown ? "text" : "password"}
                      class="form-control"
                      id="password"
                      value={emailDetails?.password}
                      placeholder="Enter your password"
                      onChange={handleDetails}
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-0 h-100"
                      onClick={() =>
                        setemailDetails({
                          ...emailDetails,
                          eyeShown: !emailDetails.eyeShown,
                        })
                      }
                    >
                      {emailDetails.eyeShown ? (
                        <svg className="icon">
                          <use href="#icon_vision_withoutslash"></use>
                        </svg>
                      ) : (
                        <svg className="icon">
                          <use href="#icon_vision"></use>
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                {showRules && (
                  <div className="mb-2">
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.length ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast 8 character</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.lowercase ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Lower case</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.uppercase ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Upper case</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.number ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Number</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.special ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>
                        Atleast One Special Character (! @ # $ % ^ & % * ? _ ~)
                      </span>
                    </div>
                  </div>
                )}
                {!regiterDetailsError?.passwordValid && (
                  <div className="mb-3 text-danger">
                    Please enter valid password
                  </div>
                )}
                {loginError?.status && (
                  <div className="m-1 text-danger text-center">
                    {loginError?.message}
                  </div>
                )}
                <div className="mt-4">
                  <button
                    type="button"
                    disabled={signingIn}
                    className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={postEmailDetails}
                  >
                    {signingIn ? <Loader /> : "SIGN UP"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default SignUpEmail;
