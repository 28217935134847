import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import After_signup from "./pages/After_signup";
import Create from "./pages/Create";
import Myheadshot from "./pages/Myheadshot";
import Dashboard from "./pages/Dashboard";
import LayoutOutlet from "./components/LayoutOutlet";
import All_students from "./pages/All_students";
import My_profile from "./pages/My_profile";
import Sign_up from "./pages/Sign_up";
import Login from "./pages/Login";
import SignUpEmail from "./pages/SignUpEmail";
import BasicDetails from "./pages/BasicDetails";
import CollageDetails from "./pages/CollageDetails";
import AdditionalInformation from "./pages/AdditionalInformation";
import LoginEmail from "./pages/LoginEmail";
import CreateSingle from "./pages/CreateSingle";
import Home2 from "./pages/Home2";
import Alumni from "./pages/Alumni";
import Recruiter from "./pages/Recruiter";
import Annual from "./pages/Annual";
import Problem from "./pages/Problem";
import Solution from "./pages/Solution";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Photo_selfie from "./pages/Photo_selfie";
import Linkedin_profile from "./pages/Linkedin_profile";
import Why_enhance_profiles from "./pages/Why_enhance_profiles";
import DynamicCollage from "./pages/DynamicCollage";
import Harvard from "./pages/Harvard";
import WhoWeAre from "./pages/WhoWeAre";
import { Helmet } from 'react-helmet';
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordOtp from "./pages/ForgotPasswordOtp";


function App() {
  return (
    <BrowserRouter>
      <Helmet>
        {/* Paste your GTM script here */}
        <script>
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-THQ5W6K9')}
        </script>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/:id" element={<Harvard />} />

        <Route path="/:id/admin" element={<Login />} />
        <Route path="/login" element={<Home />} />
        <Route path="/sign_up" element={<Sign_up />} />
        <Route path="/login_email" element={<LoginEmail />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/forgot_password_otp" element={<ForgotPasswordOtp />} />
        <Route path="/signup_email" element={<SignUpEmail />} />
        <Route path="/after_signup" element={<After_signup />} />
        <Route path="/basic_details" element={<BasicDetails />} />
        <Route path="/college_details" element={<CollageDetails />} />
        <Route
          path="/additional_information"
          element={<AdditionalInformation />}
        />
        <Route path="/create" element={<Create />} />
        <Route path="/create/:id" element={<CreateSingle />} />
        <Route path="/myheadshot" element={<Myheadshot />} />
        <Route path="/my_profile" element={<My_profile />} />

        {/* hyperlinks */}
        <Route path="/alumni" element={<Alumni />} />
        <Route path="/recruiter" element={<Recruiter />} />
        {/* image  */}
        <Route path="/annual" element={<Annual />} />
        {/*image  */}
        <Route path="/problem" element={<Problem />} />
        {/*image  */}
        <Route path="/solution" element={<Solution />} />
        {/*image  */}
        <Route path="/contact" element={<Contact />} />
        {/*image  */}
        <Route path="/faq" element={<Faq />} />
        {/*image  */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/whatwedo" element={<WhoWeAre />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/photo_selfie" element={<Photo_selfie />} />
        <Route path="/linkedin_profile" element={<Linkedin_profile />} />
        <Route
          path="/why_enhance_profiles"
          element={<Why_enhance_profiles />}
        />
        <Route path="/" element={<LayoutOutlet />}>
          {/* <Route index element={<Home />}/> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/all_students" element={<All_students />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
