import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import { debounce } from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Alumni() {
  const navigate = useNavigate();
  const [dropDownOpen, setdropDownOpen] = useState(false);
  const [searchedText, setsearchedText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [profileDetails, setprofileDetails] = useState({
    firstName: "",
    lastName: "",
    collage: "",
    grad_year: "",
    major: "",
    email: "",
  });
  const [accessTokenData, setaccessTokenData] = useState({});
  const [savedResponse, setsavedResponse] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
    if (id === "email") {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    if (id === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };

  function validateForm() {
    let valid = true;
    let newErrors = {};

    if (profileDetails.firstName == "") {
      newErrors.firstName = "Please Enter First Name";
      valid = false;
    }

    if (profileDetails.lastName == "") {
      newErrors.lastName = "Please Enter Last Name";
      valid = false;
    }
    if (profileDetails.collage == "") {
      newErrors.collage = "Please Enter College";
      valid = false;
    }
    if (profileDetails.grad_year == "") {
      newErrors.grad_year = "Please Enter Graduation Year";
      valid = false;
    }
    if (profileDetails.major == "") {
      newErrors.major = "Please Enter Major";
      valid = false;
    }
    if (profileDetails.email == "" || !validateEmail(profileDetails.email)) {
      newErrors.email = "Please Enter Valid Email";
      valid = false;
    }
    // if (profileDetails.email == "") {
    //   newErrors.email = "Please Enter Email";
    //   valid = false;
    // }

    setErrors(newErrors);

    return valid;
  }

  console.log(profileDetails, "profile details");
  const generateZohoAccessToken = async () => {
    try {
      // const response = await axios.post(
      //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
      // );
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
      });
      localStorage.setItem(
        "headshot_access_token",
        response?.data?.access_token
      );
      return response?.data?.access_token;

      // console.log(response, "response");
      // setaccessTokenData(response?.data);
      // Store access_token2 in state or do something with it
    } catch (error) {
      // Handle error
    }
  };
  const getEmailStatus = async (email, access_token) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/zoho/get_email_id`,
        method: "POST",
        data: {
          email: email,
          access_token: access_token,
        },
      });

      return response?.data;
    } catch (error) {
      // Handle error
    }
  };
  const submitButton = async () => {
    if (!validateForm()) {
      return;
    }
    setloading(true);
    const access_token1 = await generateZohoAccessToken();

    let data = {
      First_Name: profileDetails.firstName,
      Last_Name: profileDetails.lastName,
      Email: profileDetails.email,
      Company: profileDetails.collage,
      Year_Graduated: profileDetails.grad_year,
      Major: profileDetails.major,
      Lead_Product_Service_Interested_In: "MySocial Headshots",
      Lead_Product_Focus: "Student, Grad, Alum",
      access_token: access_token1,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/zoho/alumni`,
        {
          data: data,
        }
      );
      if (response.status == 200) {
        setsavedResponse(true);
        setprofileDetails({
          firstName: "",
          lastName: "",
          collage: "",
          grad_year: "",
          major: "",
          email: "",
        });
        setloading(false);
        setTimeout(() => {
          setsavedResponse(false);
        }, 2000);
      }
      // Handle response
    } catch (error) {
      console.log(error);
      setloading(false);
      console.log(error, "error");
      seterrorMessage(error?.response.data.message);
      // Handle error
    }
  };

  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full w-full flex items-center z-1">
        <div className="absolute top-0 left-0 w-full h-full">
          <img
            className="z-0 w-full h-full absolute object-cover object-center left-0 top-0"
            src="images/Bg.png"
          />
        </div>
        <div class="container mx-auto">
          <div class="w-full md:w-12/12 h-full  text-center text-[#4E2A14] break-all">
            <p class="text-6xl font-bold absolute leading-[80px] left-0 right-0 top-24">
              Students, Grads, Alumni
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4]">
        <div className="container mx-auto py-16">
          <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
            Want to improve your chances of getting internships and jobs?
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-12/12 flex items-center text-[#4E2A14]">
              <div className="p-2">
                <p className="font-medium text-xl">
                  MySocial Headshots will help you build creative, standout
                  social media and LinkedIn headshots. You can use any headshot
                  photo and add a statement to show your affiliation with your
                  college along with the college logo,emblem, or mascot. It
                  takes only seconds to do and will make your profile headshot
                  stand out!
                </p>
                <p className="font-semibold text-2xl pt-3">
                  Here is why this will help you land internships and jobs!
                </p>
              </div>
            </div>

            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>71%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of recruiters didn’t hire a candidate because they had an
                  unimpressive LinkedIn profile photo.
                </p>
              </div>
            </div>

            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>76%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of hiring managers felt professional headshots make candidates
                  look more competent.
                </p>
              </div>
            </div>

            <div className="w-full md:w-4/12 p-2 flex justify-center text-[#4E2A14]">
              <div className="w-full text-center p-4">
                <div className="flex justify-center">
                  <div className="w-40 h-40 rounded-full font-bold text-5xl flex justify-center items-center bg-[#fff] border-2 border-[#FBB522]">
                    <span>96%</span>
                  </div>
                </div>
                <p className="font-medium text-xl pt-4">
                  of hiring managers believe a candidate's LinkedIn photo
                  inspires confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16">
        <p className="text-5xl font-bold pb-8 text-center text-[#4E2A14]">
          Why enhance your LinkedIn photo?
        </p>
        <div className="flex justify-center">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
        <div className="mx-auto max-w-2xl px-4 lg:max-w-7xl mt-10">
          <div className="grid grid-cols-1">
            <div className="basis-full number-grid">
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14]">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/01.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Impress employers!
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    Research shows employers spend 17 percent of their time
                    while on a profile looking at the profile headshot.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14] px-8">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/02.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Connect with alumni mentors and build your professional
                      network.
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    Research suggests over 80 percent of job seekers land jobs
                    through the network they’ve developed. You have a unique
                    opportunity to build a network of alumni who came before
                    you. Your college branding will help you find alumni
                    mentors.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14] px-8">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/03.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Land internships and jobs with less effort, faster!
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    You don’t have a lot on your resume to help employers
                    understand why you will be the right choice among the
                    millions of other college students that apply for
                    internships and flood the employment market each May and
                    June. Your enhanced headshot will make you STAND OUT!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14] px-4">
        <div className="container mx-auto py-16">
          <p className="text-5xl font-bold pb-8 text-center ">
            It's super easy to use
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-4/12 p-2 flex justify-center">
              <div className="w-72">
                <img src="images/Form.png" className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-8/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl p-2">
                <p>
                  Simply <strong>upload a photo</strong> you want to use, choose
                  the statements and graphics (college logo, emblem, or mascot),
                  download the enhanced photo and then upload it to your{" "}
                  <strong>social media or LinkedIn profiles.</strong>
                </p>
                <p className="pt-6">
                  You can change statements and graphics as often as you’d
                  like.  There is <strong>no cost,</strong> as long as your
                  college is subscribed to <strong>MySocial Headshots</strong>
                </p>
              </div>
            </div>
          </div>
          {/* <p className="font-semibold text-2xl text-center py-12">
            See if your college is providing you a free subscription to MySocial
            Headshots
          </p>
          <div className="position-relative">
            <div className="flex justify-center ">
              <label className="relative block w-[800px]">
                <span className="sr-only z-10">Search</span>
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    className="h-5 w-5 fill-slate-300"
                    viewBox="0 0 20 20"
                  ></svg>
                </span>
                <input
                  className="placeholder:text-[#C09C85] block bg-white w-full drop-shadow-md  rounded-md py-4 pl-9 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  value={searchedText}
                  onChange={(e) => {
                    const text = e.target.value;

                    if (text.length >= 3) {
                      debouncedSearch(text);
                    } else {
                      setdropDownOpen(false);
                      setSearchResults([]);
                    }
                    setsearchedText(e.target.value);
                  }}
                  onClick={() => {
                    if (searchResults?.length > 0) {
                      setdropDownOpen(true);
                    }
                  }}
                />
                <p className="pt-4 text-[#B7B7B7]">
                Harvard University hasn’t subscribed
              </p>
              </label>
            </div>
            <div>
              {searchedText?.length >= 3 &&
              dropDownOpen &&
              searchResults.length > 0 ? (
                <div className="shadow rounded-2 filter-content-mobile l">
                  <div style={{ padding: "10px" }}>
                    {searchResults.slice(0, 5).map((product, product_index) => (
                      <div
                        key={product_index}
                        style={{
                          lineHeight: "45px",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="d-flex align-items-center "
                          onClick={() => {
                            navigate(`/${product?.universitySlug}`);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center me-2"
                            style={{ width: "50px", height: "50px" }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/${product.logo}`}
                              alt="..."
                            />
                          </div>
                          <div className="filter-search">
                            {" "}
                            {product.universityName}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {searchedText.length > 3 && dropDownOpen && (
                    <div className="shadow rounded-2 filter-content-mobile">
                      <div style={{ padding: "10px" }}>
                        <div>No Universities found for your search</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div> */}

          <div className="py-10">
            <p className="font-medium text-xl pb-12">
              There is no cost to create your enhanced social headshot. To see
              if your college is offering the MySocial Headshot program fill in
              the form below.
            </p>
            <p className="font-semibold text-2xl text-center">
              Complete the form and we'll send you a free soft skills course on
              <br /> Listening, Goal Setting, and Time Management.
            </p>
          </div>

          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-5/12 p-2 flex justify-center items-center sm:order-1">
              <div className="w-72">
                <img src="images/10.png" className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-7/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl w-full">
                <form>
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      First Name *
                    </span>
                    <input
                      type="text"
                      name="fname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="First name"
                      value={profileDetails.firstName}
                      id="firstName"
                      onChange={handleChange}
                    />
                  </label>
                  {errors.firstName && (
                    <p className="error-message text-danger mb-3">
                      {errors.firstName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Last Name *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.lastName}
                      id="lastName"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Last name"
                    />
                  </label>
                  {errors.lastName && (
                    <p className="error-message text-danger mb-3">
                      {errors.lastName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      College *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.collage}
                      onChange={handleChange}
                      id="collage"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your college"
                    />
                  </label>
                  {errors.collage && (
                    <p className="error-message text-danger mb-3">
                      {errors.collage}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Graduation Year *
                    </span>
                    <select
                      name="lname"
                      value={profileDetails?.grad_year}
                      id="grad_year"
                      onChange={handleChange}
                      className="block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                    >
                      <option value="">Select your graduation year</option>
                      {Array.from(
                        { length: new Date().getFullYear() - 1939 },
                        (_, index) => (
                          <option key={index} value={1940 + index}>
                            {1940 + index}
                          </option>
                        )
                      )}
                    </select>
                  </label>
                  {errors.grad_year && (
                    <p className="error-message text-danger mb-3">
                      {errors.grad_year}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Major *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      id="major"
                      value={profileDetails?.major}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your Major"
                      onChange={handleChange}
                    />
                  </label>
                  {errors.major && (
                    <p className="error-message text-danger mb-3">
                      {errors.major}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Email *
                    </span>
                    <input
                      type="email"
                      name="lname"
                      value={profileDetails?.email}
                      id="email"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleChange2}
                    />
                  </label>
                  {errors.email && (
                    <p className="error-message text-danger mb-3">
                      {errors.email}
                    </p>
                  )}
                  {savedResponse ? (
                    <div className=" mb-1 fw-bold text-center">
                      Details Submitted Successfully!
                    </div>
                  ) : (
                    <div className=" mb-1 fw-bold text-center">
                      {errorMessage}
                    </div>
                  )}

                  <div>
                    <button
                      type="button"
                      className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                      disabled={loading}
                      onClick={submitButton}
                    >
                      {loading ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Alumni;
