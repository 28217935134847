import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";

function Why_enhance_profiles() {
  return (
    <>
      <Header2 />

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Here is why you should enhance your social media and LinkedIn
            profile photos!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>

          <div className="flex flex-wrap w-full pt-8">
            <div className="w-full flex justify-center items-center text-center">
              <img src="/images/brand/enhance.png" width={"40%"} />
            </div>
            <div className="w-full md:w-12/12 p-2 flex justify-center">
              <div className="w-full">
                <p className="font-semibold pb-4 flex break-all">
                  1)&nbsp;{" "}
                  <a
                    href="https://www.mycomputercareer.edu/news/4-reasons-linkedin-important-professional-success/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    LinkedIn is the go to place for recruiters to find
                    candidates!
                  </a>
                  &nbsp;
                </p>
                <p className="pb-4 ">
                  LinkedIn will help you find a job faster because most hiring
                  managers and recruiters are already using it. A whopping 87%
                  of recruiters find LinkedIn to be most effective when vetting
                  candidates during the hiring process – especially those under
                  45 (90%).
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  2) &nbsp;
                  <a
                    href="https://www.forbes.com/sites/bernardmarr/2023/01/06/12-easy-steps-to-build-your-personal-brand-on-social-media/?sh=56337a722696"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    It’s a great way to build your professional brand!
                  </a>
                  &nbsp;
                </p>

                <p className="pb-4">
                  Building and maintaining your personal brand is an important
                  part of your job. But it's becoming important in so many
                  professions, way beyond the realms of influencers,
                  entrepreneurs, and thought leaders. Whether you're an
                  architect, entrepreneur, designer, blogger, lawyer, or
                  whatever, your personal brand can help you stand out from the
                  crowd and attract exciting new opportunities your way
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  3) &nbsp;{" "}
                  <a
                    href="https://www.linkedin.com/pulse/7-benefits-using-linkedin-sarah-rycraft/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    It gives you the opportunity to learn about companies you
                    want to work for.
                  </a>
                  &nbsp;
                </p>

                <p className="pb-4">
                  LinkedIn is a brilliant tool for researching organisations and
                  people that work at them. You can use this to target people
                  within your network that work for organisations that you
                  really want to work for.
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  4) &nbsp;{" "}
                  <a
                    href="https://careersidekick.com/5-reasons-you-should-use-linkedin/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    You will develop a powerful professional network!
                  </a>
                  &nbsp;
                </p>

                <p className="pb-4">
                  Networking is the fastest and easiest way to find a job right
                  now (and I explain why here), but you need to maintain
                  connections and build relationships. Research suggests over 80
                  percent of job seekers end up finding jobs through people they
                  know or are connected to!
                </p>

                <p className="font-semibold pb-4 flex break-all">
                  <a
                    href="https://blog.linkboost.co/get-ready-for-your-dream-job-set-up-linkedin-job-alerts/"
                    target="_blank"
                    className="hover:text-[#FBB522] text-[#4285f4] underline underline-offset-1"
                  >
                    5) &nbsp; You can set up internship and job alerts!
                  </a>
                  &nbsp;
                </p>

                <p className="pb-4 leading-[30px]">
                  Linkedin can be a highly valuable tool for networking, job
                  search, and hiring, as it allows people to keep up with new
                  opportunities in their field. The best way to be one step
                  ahead is by leveraging LinkedIn and networking with alumni.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Why_enhance_profiles;
