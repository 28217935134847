import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import axios from "axios";
import Loader from "../components/Loader";

function Solution() {
  const [showModal, setShowModal] = React.useState(false);
  const [profileDetails, setprofileDetails] = useState({
    firstName: "",
    lastName: "",
    collage: "",
    grad_year: "",
    major: "",
    email: "",
    title: "",
    phone: "",
    Lead_Comments: "",
  });
  const [errors, setErrors] = useState({});
  const [accessTokenData, setaccessTokenData] = useState({});
  const [savedResponse, setsavedResponse] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileDetails({ ...profileDetails, [id]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
    if (id === "email") {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    if (id === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };

  function validateForm() {
    let valid = true;
    let newErrors = {};

    if (profileDetails.firstName == "") {
      newErrors.firstName = "Please Enter First Name";
      valid = false;
    }

    if (profileDetails.lastName == "") {
      newErrors.lastName = "Please Enter Last Name";
      valid = false;
    }
    if (profileDetails.title == "") {
      newErrors.title = "Please Enter Title";
      valid = false;
    }
    if (profileDetails.collage == "") {
      newErrors.collage = "Please Enter College";
      valid = false;
    }
    if (profileDetails.email == "" || !validateEmail(profileDetails.email)) {
      newErrors.email = "Please Enter Valid Email";
      valid = false;
    }
    // if (profileDetails.email == "") {
    //   newErrors.email = "Please Enter Email";
    //   valid = false;
    // }

    if (profileDetails.phone == "") {
      newErrors.phone = "Please enter a valid phone number";
      valid = false;
    }
    if (profileDetails.Lead_Comments == "") {
      newErrors.Lead_Comments =
        "Please Enter What would you like this program to accomplish for you?";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  }
  const generateZohoAccessToken = async () => {
    try {
      // const response = await axios.post(
      //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
      // );
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
      });
      localStorage.setItem(
        "headshot_access_token",
        response?.data?.access_token
      );
      return response?.data?.access_token;

      // console.log(response, "response");
      // setaccessTokenData(response?.data);
      // Store access_token2 in state or do something with it
    } catch (error) {
      // Handle error
    }
  };
  // console.log(profileDetails, "profile details");
  const submitButton = async () => {
    if (!validateForm()) {
      return;
    }
    setloading(true);
    const access_token1 = await generateZohoAccessToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/zoho/solution`,
        {
          data: {
            First_Name: profileDetails.firstName,
            Last_Name: profileDetails.lastName,
            Email: profileDetails.email,
            Company: profileDetails.collage,
            Phone: profileDetails.phone,
            Title: profileDetails.title,
            Lead_Product_Service_Interested_In: "MySocial Headshots",
            Lead_Product_Focus: "Admissions, Career Center, Alumni, Athletic",
            Lead_Comments: profileDetails?.Lead_Comments,
            access_token: access_token1,
          },
        }
      );
      // console.log(response, "response");
      if (response.status == 200) {
        setsavedResponse(true);
        setprofileDetails({
          firstName: "",
          lastName: "",
          collage: "",
          grad_year: "",
          major: "",
          email: "",
          title: "",
          phone: "",
          Lead_Comments: "",
        });
        setloading(false);
        setTimeout(() => {
          setsavedResponse(false);
        }, 2000);
        seterrorMessage("");
      }
      // Handle response
    } catch (error) {
      setloading(false);
      console.log(error, "error");
      seterrorMessage(error?.response.data.message);
      // Handle error
    }
  };
  // useEffect(() => {
  //   // Generate Zoho access token
  //   if (!localStorage.getItem("headshot_access_token")) {
  //     const generateZohoAccessToken = async () => {
  //       try {
  //         // const response = await axios.post(
  //         //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
  //         // );
  //         const response = await axios({
  //           url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
  //         });
  //         localStorage.setItem(
  //           "headshot_access_token",
  //           response?.data?.access_token
  //         );

  //         // console.log(response, "response");
  //         // setaccessTokenData(response?.data);
  //         // Store access_token2 in state or do something with it
  //       } catch (error) {
  //         // Handle error
  //       }
  //     };

  //     generateZohoAccessToken();
  //   }
  // }, []);

  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-50 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-solution.png"
        />
        <div className="container mx-auto px-4">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-24">
              Solution
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FCFCFC] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Customized College Branded Social Media and LinkedIn Headshots
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full">
            <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 font-medium">
                <p className="pt-4 text-xl">
                  Your department can give prospective/admitted students,
                  student-athletes, students, grads, alumni and even fans the
                  ability to add branded statements that overlay their social
                  media and LinkedIn photos as well as add the college logo,
                  emblems, and/or mascots.
                </p>
                <p className="font-semibold text-xl py-4">
                  Here’s why this is totally unique!
                </p>
                <p className="text-xl">
                  LinkedIn research shows members can significantly increase
                  connections and networking by simply adding #Hiring and
                  #OpentoWork on their profile page.  LinkedIn data has also
                  found that having a statement like #OPENTOWORK and #HIRING on
                  a LinkedIn profile photo increases one’s chances of a
                  recruiter contacting them by a factor of two as well as makes
                  it 40% more likely to receive an InMail from a recruiter.
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="w-full font-medium text-xl">
                <p className="font-semibold text-xl py-4">
                  Departments on campus like:
                </p>
                <ul className="list-disc leading-[40px] pl-5 divide-y divide-slate-200">
                  <li className="py-4">Admissions</li>
                  <li className="py-4">Career Centers</li>
                  <li className="py-4">Student Groups, Clubs, Frats</li>
                  <li className="py-4">Athletic Departments</li>
                  <li className="py-4">Alumni Associations</li>
                  <li className="py-4">National Student Groups</li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="h-96l h-64">
                <img src="images/group-single.png" className="w-full h-full" />
              </div>
            </div>
            <p className="text-xl font-medium">
              ...can provide students, grads or alumni the option of including
              statements and college branding on their  LinkedIn profile that
              will help them get internship offers and jobs.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            The first step is to create a customized college registration page
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full">
            <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 font-medium">
                <p className="pt-4 text-xl flex break-all">
                  To get you started, we’ll create a custom college registration
                  page you can use to invite students, grads, and alumni to take
                  advantage of this free social media branding tool.   
                </p>
                <p className="text-xl pt-4 flex break-all">
                  Your emails, enewsletters, and social media marketing to raise
                  awareness of the opportunity can include a unique branded URL
                  like mysocialheadshots.com/collegename  You can decide how
                  much information to ask students, grads, and alumni to provide
                  when they register. Some of the suggested data fields include:
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="w-full font-medium text-xl">
                <ul className="list-disc leading-[40px] pl-5 divide-y divide-slate-200">
                  <li className="py-4">First Name</li>
                  <li className="py-4">Last Name</li>
                  <li className="py-4">Email</li>
                  <li className="py-4">Year graduated</li>
                  <li className="py-4">College you will graduate from</li>
                  <li className="py-4">Personal Linkedin URL</li>
                  <li className="py-4">
                    Who are you (student, grad, alumni, parent)
                  </li>
                  <li className="py-4">Cell phone (optional)</li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="h-96">
                <img src="images/group-input.png" className="w-full h-full" />
              </div>
            </div>
            <p className="text-xl font-medium flex break-all">
              You will also have access to a dashboard that will track
              individual use, top users, most popular statements and graphics
              and frequency of use.
            </p>
          </div>
        </div>
      </div>

      <div className="text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 px-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Next, we’ll create customized graphics that display your college
            brand
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full">
            <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 font-medium">
                <p className="pt-4 text-xl">
                  Next, we’ll create custom graphics from the 10 statements you
                  want to give students, grads, or alumni  the option of using.
                  You can create up to 10 college branded statements for them to
                  choose from and can include up to three graphic options to add
                  to their photo such as the college logo, emblems, and/or
                  mascot. Examples of statements include:
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="w-full font-medium text-xl">
                <ul className="list-disc leading-[40px] pl-5 divide-y divide-slate-200">
                  <li className="py-4">“Name of College”  Student</li>
                  <li className="py-4">
                    “Name of College” Freshman, Sophomore, Junior, Senior
                  </li>
                  <li className="py-4">"Name of College" Mascot</li>
                  <li className="py-4">Looking for a Summer Job</li>
                  <li className="py-4">
                    State College Student Looking for Internships
                  </li>
                  <li className="py-4">
                    "Name of College" Looking for Mentors
                  </li>
                  <li className="py-4">"Name of College" Just Graduated!</li>
                  <li className="py-4">Looking for a job!</li>
                  <li className="py-4">"Name of College" Class of 2023</li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="h-[100%] max-h-[500px]">
                <img src="images/group-4.png" className="w-full h-full" />
              </div>
            </div>
            <p className="text-xl font-medium">
              They  can change statements and graphics as often as they want.
              Your department/college’s subscription will give everyone free
              access.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 text-[#4E2A14] px-4">
        <p className="text-5xl font-bold pb-8 text-center ">
          It’s simple to offer to your prospective/admitted students,
          student-athletes, students, grads, and alumni and it's easy for them
          to use!
        </p>
        <div className="flex justify-center pb-5">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
        <div className="mx-auto max-w-2xl px-4 lg:max-w-7xl mt-10">
          <div className="grid grid-cols-1">
            <div className="basis-full number-grid">
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14]">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full h-full" src="images/sol-1.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      snap a "selfie" or upload a photo
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    They upload any professional digital photo or selfie
                    portrait from any cell phone or computer.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14] px-8">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/sol-2.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Select a statement
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    You can offer a variety of approved statements and graphics.
                    (college logos, emblems, and mascots)
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14] px-8">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/sol-3.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Registration captures engagement
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    You can ask as much or as little information in the
                    registration process.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap pl-12">
                <div className="basis-full sm:basis-6/12">
                  <div className="text-center text-[#4E2A14] px-8">
                    <div className="flex justify-center">
                      <div className="max-h-72 w-full max-w-xs">
                        <img className="w-full" src="images/sol-4.png" />
                      </div>
                    </div>
                    <p className="font-semibold text-2xl pt-6">
                      Download enhanced photo!
                    </p>
                  </div>
                </div>
                <div className="basis-full sm:basis-6/12 flex items-center">
                  <p className="font-medium text-xl text-[#4E2A14]">
                    Enhanced photos can then be downloaded to phones or
                    computers and then uploaded to LinkedIn and social media
                    profiles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14] px-4">
        <div className="container mx-auto py-16">
          <p className="text-5xl leading-none font-bold pb-8 text-center ">
            Learn how to offer this benefit!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="py-10">
            <p className="font-medium text-xl">
              If you’ve read this far you must be interested.  Thanks,{" "}
              <span className="underline underline-offset-1">
                we know you will be impressed!
              </span>
            </p>
            <p className="font-medium text-xl pt-4">
              Take a moment to share your contact information and we will
              immediately give you access to more detailed information
            </p>
          </div>

          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-5/12 p-2 flex justify-center items-center sm:order-1">
              <div className="w-72">
                <img src="images/img-10.png" className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-7/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl w-full">
                <form>
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      First Name *
                    </span>
                    <input
                      type="text"
                      name="fname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="First name"
                      value={profileDetails.firstName}
                      id="firstName"
                      onChange={handleChange}
                    />
                  </label>
                  {errors.firstName && (
                    <p className="error-message text-danger mb-3">
                      {errors.firstName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Last Name *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.lastName}
                      id="lastName"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Last name"
                    />
                  </label>
                  {errors.lastName && (
                    <p className="error-message text-danger mb-3">
                      {errors.lastName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Title *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.title}
                      id="title"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your Title"
                    />
                  </label>
                  {errors.title && (
                    <p className="error-message text-danger mb-3">
                      {errors.title}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      College *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your College"
                      value={profileDetails?.collage}
                      onChange={handleChange}
                      id="collage"
                    />
                  </label>
                  {errors.collage && (
                    <p className="error-message text-danger mb-3">
                      {errors.collage}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Email *
                    </span>
                    <input
                      type="email"
                      name="lname"
                      value={profileDetails?.email}
                      id="email"
                      onChange={handleChange}
                      onBlur={handleChange2}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your email"
                    />
                  </label>
                  {errors.email && (
                    <p className="error-message text-danger mb-3">
                      {errors.email}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Phone * (Enter phone without dash)
                    </span>
                    <input
                      type="number"
                      name="lname"
                      value={profileDetails?.phone}
                      id="phone"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your phone"
                    />
                  </label>
                  {errors.phone && (
                    <p className="error-message text-danger mb-3">
                      {errors.phone}
                    </p>
                  )}
                  <div className="pb-6">
                    <span className="block text-lg font-medium pb-2">
                      What would you like this program to accomplish for you? *
                    </span>
                    <textarea
                      placeholder=""
                      value={profileDetails?.Lead_Comments}
                      id="Lead_Comments"
                      onChange={handleChange}
                      className="w-full placeholder:text-[#C09C85] block bg-white w-full py-4 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium h-28"
                    ></textarea>
                  </div>
                  {errors.Lead_Comments && (
                    <p className="error-message text-danger mb-3">
                      {errors.Lead_Comments}
                    </p>
                  )}
                  {savedResponse ? (
                    <div className=" mb-1 fw-bold text-center">
                      Details Submitted Successfully!
                    </div>
                  ) : (
                    <div className=" mb-1 fw-bold text-center">
                      {errorMessage}
                    </div>
                  )}
                  <div>
                    <button
                      type="button"
                      className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                      // onClick={() => setShowModal(true)}
                      disabled={loading}
                      onClick={submitButton}
                    >
                      {loading ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-5xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#FFF6E4] outline-none focus:outline-none max-w-5xl">
                  {/*body*/}
                  <div className="relative px-16 py-8 flex-auto text-[#4E2A14]">
                    <p className="my-4 text-[34px] font-bold text-center leading-relaxed">
                      Thank you for submitting the form
                    </p>
                    <p className="text-2xl pb-6">
                      You will get immediate access to learn how this program
                      can increase:
                    </p>
                    <ul className="list-disc pl-6 text-2xl leading-[55px]">
                      <li>Enrollment</li>
                      <li>Student mentoring</li>
                      <li>
                        Students with internships and jobs by graduation day
                      </li>
                      <li>Alumni satisfaction</li>
                      <li>Contributions</li>
                    </ul>
                  </div>
                  {/*footer*/}
                  {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button>
                </div> */}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>

      <Footer2 />
    </>
  );
}

export default Solution;
