import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
function Login() {
  const [emailDetails, setemailDetails] = useState({
    eyeShown: false,
  });
  const [collageData, setcollageData] = useState({});
  const [loginError, setloginError] = useState({
    status: false,
    message: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  console.log(params, "params");

  const handleDetails = (e) => {
    setemailDetails({ ...emailDetails, [e.target.id]: e.target.value });
  };

  console.log(emailDetails);
  const postEmailDetails = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/admin/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailDetails?.email,
          password: emailDetails?.password,
        },
      });

      console.log(response, "response");
      if (response?.status == 200) {
        if (
          response?.data?.universitySlug !==
          JSON.parse(localStorage.getItem("university_data"))?.universitySlug
        ) {
          setloginError({
            status: true,
            message: "Admin doesn't exist",
          });
        } else {
          localStorage.setItem(
            "admin_data_headShots",
            JSON.stringify(response?.data)
          );
          localStorage.setItem(
            "admin_data_headShots_collage",
            collageData?.title
          );
          if (localStorage.getItem("admin_data_headShots")) {
            navigate("/dashboard");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCollageData = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/university/${params?.id}`,
        method: "GET",
      });
      if (response.status == 200) {
        // console.log(response, "response");
        localStorage.setItem(
          "university_data",
          JSON.stringify(response?.data?.university)
        );
        setcollageData(response?.data?.university);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };
  console.log(collageData, "collage data");
  useEffect(() => {
    if (params?.id) {
      getCollageData();
    }
  }, [params?.id]);

  return (
    <>
      {/* <Header /> */}
      <div className="drop-header ">
        <Navbar
          expand="md"
          className="px-12"
          style={{ background: collageData?.headerColor }}
        >
          <Container>
            <Navbar.Brand
              className="cursor-pointer "
              onClick={() => {
                localStorage.removeItem("user_email_headShots");
                localStorage.removeItem("user_token_headShots");
                localStorage.removeItem("university_data");
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${collageData?.logo}`}
              ></img>
            </Navbar.Brand>
            <div className="color me-auto text-white fw-bold fs-20">
              LinkedIn & Social Media Headshot Enhancing Tool
            </div>
          </Container>
        </Navbar>
      </div>
      <div className="hero-box">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${collageData?.banner}`}
          className="hero-poster"
        />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <div className="login-form">
                <div className="d-flex justify-content-center">
                  <div className="logo-box">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${collageData?.logo}`}
                      className="logo"
                    />
                  </div>
                </div>
                <h2 className="fs-22 fw-700 text-center pb-4">
                  {collageData?.title}
                </h2>
                <div className="mb-3">
                  <label className="form-label fw-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    value={emailDetails?.email}
                    placeholder="Enter your email"
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">Password</label>
                  <div className="relative">
                    <input
                      type={emailDetails.eyeShown ? "text" : "password"}
                      class="form-control"
                      id="password"
                      value={emailDetails?.password}
                      placeholder="Enter your password"
                      onChange={handleDetails}
                    />
                    <button
                      type="button"
                      className="absolute right-2 top-0 h-100"
                      onClick={() =>
                        setemailDetails({
                          ...emailDetails,
                          eyeShown: !emailDetails.eyeShown,
                        })
                      }
                    >
                      {emailDetails.eyeShown ? (
                        <svg className="icon">
                          <use href="#icon_vision_withoutslash"></use>
                        </svg>
                      ) : (
                        <svg className="icon">
                          <use href="#icon_vision"></use>
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                {loginError?.status && (
                  <div className="m-1 text-danger text-center">
                    {loginError?.message}
                  </div>
                )}
                <div className="mt-4">
                  {/* <button
                    type="button"
                    className="w-100 btn-yellow border-0 fw-600 rounded-3 p-2"
                    onClick={postEmailDetails}
                  >
                    Login
                  </button> */}
                  <button
                    type="button"
                    className="w-full rounded-md py-2 bg-[#FBB522] px-4 hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={postEmailDetails}
                  >
                    Login
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Login;
