import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import axios from "axios";
import Loader from "../components/Loader";

function Recruiter() {
  const [profileDetails, setprofileDetails] = useState({
    firstName: "",
    lastName: "",
    collage: "",
    grad_year: "",
    major: "",
    email: "",
    title: "",
    phone: "",
    Lead_Comments: "",
  });
  const [errors, setErrors] = useState({});
  const [accessTokenData, setaccessTokenData] = useState({});
  const [savedResponse, setsavedResponse] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setprofileDetails({ ...profileDetails, [id]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
    if (id === "email") {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    setprofileDetails((prevProfileDetails) => ({
      ...prevProfileDetails,
      [id]: value,
    }));
    if (id === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  };
  function validateForm() {
    let valid = true;
    let newErrors = {};

    if (profileDetails.firstName == "") {
      newErrors.firstName = "Please Enter First Name";
      valid = false;
    }

    if (profileDetails.lastName == "") {
      newErrors.lastName = "Please Enter Last Name";
      valid = false;
    }
    if (profileDetails.title == "") {
      newErrors.title = "Please Enter Title";
      valid = false;
    }
    if (profileDetails.collage == "") {
      newErrors.collage = "Please Enter Organization";
      valid = false;
    }
    if (profileDetails.email == "" || !validateEmail(profileDetails.email)) {
      newErrors.email = "Please Enter Valid Email";
      valid = false;
    }
    if (profileDetails.phone == "") {
      newErrors.phone = "Please enter a valid phone number";
      valid = false;
    }
    if (profileDetails.Lead_Comments == "") {
      newErrors.Lead_Comments =
        "Please Enter What would you like this program to accomplish for you?";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  }
  const generateZohoAccessToken = async () => {
    try {
      // const response = await axios.post(
      //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
      // );
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
      });
      localStorage.setItem(
        "headshot_access_token",
        response?.data?.access_token
      );
      return response?.data?.access_token;

      // console.log(response, "response");
      // setaccessTokenData(response?.data);
      // Store access_token2 in state or do something with it
    } catch (error) {
      // Handle error
    }
  };
  console.log(profileDetails, "profile details");
  const submitButton = async () => {
    if (!validateForm()) {
      return;
    }
    setloading(true);
    const access_token1 = await generateZohoAccessToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/zoho/solution`,
        {
          data: {
            First_Name: profileDetails.firstName,
            Last_Name: profileDetails.lastName,
            Email: profileDetails.email,
            Company: profileDetails.collage,
            Phone: profileDetails.phone,
            Title: profileDetails.title,
            Lead_Product_Service_Interested_In: "MySocial Headshots",
            Lead_Product_Focus: "Employer, Recruiter, Brands",
            Lead_Comments: profileDetails?.Lead_Comments,
            access_token: access_token1,
          },
        }
      );
      // console.log(response, "response");
      if (response.status == 200) {
        setsavedResponse(true);
        setprofileDetails({
          firstName: "",
          lastName: "",
          collage: "",
          grad_year: "",
          major: "",
          email: "",
          title: "",
          phone: "",
          Lead_Comments: "",
        });
        setloading(false);
        setTimeout(() => {
          setsavedResponse(false);
        }, 2000);
      }
      // Handle response
    } catch (error) {
      setloading(false);
      console.log(error, "error");
      seterrorMessage(error?.response.data.message);
      // Handle error
    }
  };
  // useEffect(() => {
  //   // Generate Zoho access token
  //   if (!localStorage.getItem("headshot_access_token")) {
  //     const generateZohoAccessToken = async () => {
  //       try {
  //         // const response = await axios.post(
  //         //   "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.5017c23d9ddaabfb75d59f379ff916be.81f50a2dfa84664dc4dd071aaadb406e&client_id=1000.8ZE7LVLPY037AVZKU5ZBQMN1LK8EDD&client_secret=4943080b2d867bbe48dc89ae5e913841185615e0cb&grant_type=refresh_token"
  //         // );
  //         const response = await axios({
  //           url: `${process.env.REACT_APP_BASE_URL}/get_access_code`,
  //         });
  //         localStorage.setItem(
  //           "headshot_access_token",
  //           response?.data?.access_token
  //         );

  //         // console.log(response, "response");
  //         // setaccessTokenData(response?.data);
  //         // Store access_token2 in state or do something with it
  //       } catch (error) {
  //         // Handle error
  //       }
  //     };

  //     generateZohoAccessToken();
  //   }
  // }, []);

  return (
    <>
      <Header2 />
      <div className="hero-box relative h-full bg-[#000000] z-1 w-full">
        <img
          className="opacity-50 z-0 w-full h-full absolute object-cover object-center left-0 top-0"
          src="images/bg-recruiter.png"
        />
        <div className="container mx-auto mx-4">
          <div className="w-full md:w-12/12 h-full  text-center text-[#fff] break-all">
            <p className="text-6xl font-bold absolute leading-[80px] left-0 right-0 bottom-24">
              Employer/Recruiter/Brand
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#FCFCFC] text-[#4E2A14]">
        <div className="container mx-auto items-center h-full py-16 mx-4">
          <p className="text-5xl font-bold pb-8 text-center ">
            Let Your New College Interns and Full Time Hires Show Their Pride &
            Enthusiasm about being Hired by Your Firm!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="flex flex-wrap pt-10 w-full">
            <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 font-medium text-xl">
                <p className="">
                  Your new college interns and full time hires are making the
                  most important career move of their life when they accept your
                  offer to work for your firm.  As part of your onboarding
                  program you can now give your new hires the ability to enhance
                  their LinkedIn profile photo by adding a statement and
                  organization logo.
                </p>
                <p className="pt-4">
                  You are probably aware of LinkedIn’s highly successful new
                  feature that enables members to add <strong>#HIRING</strong>{" "}
                  and <strong>#OPENTOWORK</strong> on their profile page.  You
                  can give your new hires the ability to add company approved
                  statements like:
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 px-5 p-2 flex justify-center items-center">
              <div className="w-full font-medium px-5 text-xl">
                <ul className="list-disc pl-5 space-y-3">
                  <li>I’m starting work at (Name of Organization)</li>
                  <li>Starting my career at (Name of organization)</li>
                  <li>(Name of organization) offered me a job!</li>
                  <li>Recently hired at (Name of organization)</li>
                  <li>I’m now at (Name of organization)</li>
                  <li>(Name of organization) wants my talent!</li>
                  <li>Next stop (Name of organization)</li>
                  <li>Transitioning to (Name of organization)</li>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-6/12 p-2 flex justify-center items-center">
              <div className="h-[100%] w-[70%] max-h-[620px]">
                <img src="images/group-1.png" className="w-full h-full" />
              </div>
            </div>
            {/* <div className="w-full md:w-12/12 flex items-center] mb-10">
              <div className="p-2 text-[#4E2A14]">
                <p className="font-semibold text-[32px] py-4">Who we are!</p>
                <p className="font-bold text-[20px] pb-4">
                  MySocial Headshots is one of a family of products and services
                  provided by TalentMarks designed to help higher education
                  departments engage their constituents. We help:
                </p>
                <ul className="list-disc pl-5 space-y-3 font-medium text-xl">
                  <li>
                    Admissions offices increase enrollment using organic viral
                    marketing campaigns that leverage prospective student’s
                    influence on their social media channels. We also help
                    admissions professionals engage parents who are primary
                    influences of college choice.
                  </li>
                  <li>
                    First-Year Experience offices help students transition from
                    high school to college by providing on demand health,
                    well-being, relationship, and study best practices
                    programing. These programs are increase student’s confidence
                    and retention which of course increase revenue!
                  </li>
                  <li>
                    Career centers get students to take ownership of their
                    careers earlier in their college experience, visit the
                    career center frequently, and prepare them for their first
                    professional job search. This increases alumni satisfaction,
                    which increases enrollment!
                  </li>
                  <li>
                    Alumni associations connect and engage alumni by providing
                    online career and professional development programming to
                    support graduates as they are launching their careers to
                    alumni who are preparing to retire. These career engagement
                    programs are designed to increase alumni support of
                    mentoring and scholarship programs.
                  </li>
                  <li>
                    Annual giving programs by helping graduates and alumni
                    understand the connection between their alma mater’s career
                    and professional development support and contributions.
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container mx-auto items-center h-full py-16 text-[#4E2A14] mx-4">
        <p className="text-5xl font-bold pb-8 text-center ">
          Marketing professionals and brands can “light a match” to organic
          viral campaigns!
        </p>
        <div className="flex justify-center">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
      </div>
      <div className="w-full mx-4  p-2 flex justify-center items-center">
        <div className="font-medium text-xl">
          {" "}
          <p>
            Imagine starting a campaign where your fans, customers, brand
            ambassadors, and influencers add campaign statements and graphics to
            their social media and LinkedIn headshots. Every, like, comment, or
            repost will provide another level of brand awareness through their
            networks and those they are connected with’s networks.
          </p>
        </div>
      </div>

      <div className="container mx-auto items-center h-full py-16 text-[#4E2A14] mx-4">
        <p className="text-5xl font-bold pb-8 text-center ">
          It’s simple to do.
        </p>
        <div className="flex justify-center">
          <div className="w-24 h-1.5 bg-[#FBB522]"></div>
        </div>
        <div className="flex flex-wrap pt-10 w-full">
          <div className="w-full md:w-4/12 p-2 flex justify-center items-center">
            <div className="h-[280] w-[320px]">
              <img src="images/group-2.png" className="w-full h-full" />
            </div>
          </div>
          <div className="w-full md:w-8/12 p-2 flex justify-center items-center">
            <div className="font-medium text-xl">
              <p className="">
                We’ll create a customized registration page where they can
                upload their photo, select any approved statement and company
                logo. You simply send them a link to access the page.  It’s
                simple for them to take advantage of this onboarding perk too.
                Your new hires will upload the photo they want to use, select
                the statement and logo they want, download the enhanced photo
                and then upload it to their LinkedIn profile.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FFF6E4] text-[#4E2A14] px-4">
        <div className="container mx-auto py-16 mx-4">
          <p className="text-5xl leading-none font-bold pb-8 text-center ">
            Learn how to offer this benefit!
          </p>
          <div className="flex justify-center">
            <div className="w-24 h-1.5 bg-[#FBB522]"></div>
          </div>
          <div className="py-10">
            <p className="font-medium text-xl">
              Take a moment to share your contact information and we will
              immediately give you access to more comprehensive and detailed
              product and service information.
            </p>
          </div>

          <div className="flex flex-wrap pt-10">
            <div className="w-full md:w-5/12 p-2 flex justify-center items-center sm:order-1">
              <div className="w-72">
                <img src="images/form-2.png" className="w-full" />
              </div>
            </div>
            <div className="w-full md:w-7/12 flex items-center text-[#4E2A14]">
              <div className="font-medium text-xl w-full">
                <form>
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      First Name *
                    </span>
                    <input
                      type="text"
                      name="fname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="First name"
                      value={profileDetails.firstName}
                      id="firstName"
                      onChange={handleChange}
                    />
                  </label>
                  {errors.firstName && (
                    <p className="error-message text-danger mb-3">
                      {errors.firstName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Last Name *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.lastName}
                      id="lastName"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Last name"
                    />
                  </label>
                  {errors.lastName && (
                    <p className="error-message text-danger mb-3">
                      {errors.lastName}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Title *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      value={profileDetails?.title}
                      id="title"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14]  rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your title"
                    />
                  </label>
                  {errors.title && (
                    <p className="error-message text-danger mb-3">
                      {errors.title}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Organization *
                    </span>
                    <input
                      type="text"
                      name="lname"
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your organization"
                      value={profileDetails?.collage}
                      onChange={handleChange}
                      id="collage"
                    />
                  </label>
                  {errors.collage && (
                    <p className="error-message text-danger mb-3">
                      {errors.collage}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Email *
                    </span>
                    <input
                      type="email"
                      name="lname"
                      value={profileDetails?.email}
                      id="email"
                      onChange={handleChange}
                      onBlur={handleChange2}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your email"
                    />
                  </label>
                  {errors.email && (
                    <p className="error-message text-danger mb-3">
                      {errors.email}
                    </p>
                  )}
                  <label className="block pb-6">
                    <span className="block text-lg font-medium pb-2">
                      Phone * (Enter phone without dash)
                    </span>
                    <input
                      type="number"
                      name="lname"
                      value={profileDetails?.phone}
                      id="phone"
                      onChange={handleChange}
                      className="placeholder:text-[#C09C85] block bg-white w-full border border-[#4E2A14] rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium"
                      placeholder="Enter your phone"
                    />
                  </label>
                  {errors.phone && (
                    <p className="error-message text-danger mb-3">
                      {errors.phone}
                    </p>
                  )}
                  <div className="pb-6">
                    <span className="block text-lg font-medium pb-2">
                      What would you like this program to accomplish for you? *
                    </span>
                    <textarea
                      placeholder=""
                      value={profileDetails?.Lead_Comments}
                      id="Lead_Comments"
                      onChange={handleChange}
                      className="w-full placeholder:text-[#C09C85] block bg-white w-full py-4 pl-4 pr-3 focus:outline-none focus:border-[#FBB522] focus:ring-[#FBB522] focus:ring-1 sm:text-md font-medium h-28"
                    ></textarea>
                  </div>
                  {errors.Lead_Comments && (
                    <p className="error-message text-danger mb-3">
                      {errors.Lead_Comments}
                    </p>
                  )}
                  {savedResponse ? (
                    <div className=" mb-1 fw-bold text-center">
                      Details Submitted Successfully!
                    </div>
                  ) : (
                    <div className=" mb-1 fw-bold text-center">
                      {errorMessage}
                    </div>
                  )}
                  <div>
                    <button
                      type="button"
                      className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                      disabled={loading}
                      onClick={submitButton}
                    >
                      {loading ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Recruiter;
