import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [dashboardDetails, setdashboardDetails] = useState({});
  const getDashboardDetails = async () => {
    let token = await JSON.parse(localStorage.getItem("admin_data_headShots"))
      ?.token;
    let email = await JSON.parse(localStorage.getItem("admin_data_headShots"))
      ?.email;
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/university/dashboard`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
          email: email,
        },
      });
      //   console.log(response, "dashboard details");
      if (response.status == 200) {
        setdashboardDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(dashboardDetails, "dashboard details");
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("admin_data_headShots"))?.token &&
      JSON.parse(localStorage.getItem("admin_data_headShots"))?.email
    )
      getDashboardDetails();
  }, [
    JSON.parse(localStorage.getItem("admin_data_headShots"))?.token,
    JSON.parse(localStorage.getItem("admin_data_headShots"))?.email,
  ]);
  useEffect(() => {
    if (!localStorage.getItem("admin_data_headShots_collage")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="container-fluid layout">
        <main>
          <Container fluid>
            <Row>
              <Col className="col-12 py-3 txt-brown text-center">
                <h2 className="fs-30 fw-600">
                  {localStorage.getItem("admin_data_headShots_collage")}
                </h2>
                <p className="fw-600 fs-20">
                  MyLinkedIn Headshots dashboard for adminstrators only
                </p>
              </Col>
            </Row>
            <Row className="row-cols-2 row-cols-lg-3 g-4">
              <Col className="">
                <div className="number-card">
                  <p className="fw-700"># of Students Registered</p>
                  <h5 className="fs-40 fw-700">
                    {dashboardDetails?.studentsRegistered}
                  </h5>
                </div>
              </Col>
              <Col className="">
                <div className="number-card">
                  <p className="fw-700"># of Photos Uploaded</p>
                  <h5 className="fs-40 fw-700">
                    {dashboardDetails?.photosUploaded}
                  </h5>
                </div>
              </Col>
              <Col className="">
                <div className="number-card">
                  <p className="fw-700"># of custom headshots made</p>
                  <h5 className="fs-40 fw-700">
                    {dashboardDetails?.customHeadshots}
                  </h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 py-4 txt-brown text-center">
                <p className="fw-600 fs-20">Registration By Class</p>
              </Col>
            </Row>
            <Row className="row-cols-2 row-cols-lg-3 g-4">
              {dashboardDetails?.regByClass?.map((item, index) => (
                <Col className="" key={index}>
                  <div className="number-card">
                    <p className="fw-700">Class of {item?.year}</p>
                    <h5 className="fs-40 fw-700">{item?.students}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
}

export default Dashboard;
