import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";

function AsideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <aside className="left-aside">
        <ul className="px-5">
          <li>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="txt-btn"
                style={{
                  color: location.pathname == "/dashboard" && "#FBB522",
                }}
                onClick={() => {
                  navigate("/dashboard");
                  window.scrollTo(0, 0);
                }}
              >
                Dashboard
              </button>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="txt-btn"
                style={{
                  color: location.pathname == "/all_students" && "#FBB522",
                }}
                onClick={() => {
                  navigate("/all_students");
                  window.scrollTo(0, 0);
                }}
              >
                All Students
              </button>
            </div>
          </li>
        </ul>
      </aside>
    </>
  );
}
export default AsideBar;
