import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import OtpInput from "../components/OtpInput";

function ForgotPasswordOtp() {
  const location = useLocation();
  const [emailDetails, setemailDetails] = useState({
    email: location.state || "",
  });
  const [loginError, setloginError] = useState({
    status: false,
    message: "",
  });
  const [regiterDetailsError, setregiterDetailsError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    passwordValid: true,
  });
  const [passwordValidation, setpasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });
  const [showRules, setShowRules] = useState(false);
  const [loggingIn, setloggingIn] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const handleDetails = (e) => {
    if (e.target.id === "password") {
      let value = e.target.value;
      if (value.length > 0) {
        setShowRules(true);
      } else {
        setShowRules(false);
      }
      setpasswordValidation({
        length: value.length >= 8,
        lowercase: /[a-z]/.test(value),
        uppercase: /[A-Z]/.test(value),
        number: /\d/.test(value),
        special: /[!@#\$%^&*?_~]/.test(value),
      });
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: true,
      });
    }

    setemailDetails({ ...emailDetails, [e.target.id]: e.target.value });
  };
  const checkPasswordValidation = () => {
    if (
      passwordValidation.length &&
      passwordValidation.lowercase &&
      passwordValidation.uppercase &&
      passwordValidation.number &&
      passwordValidation.special
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [errors, seterrors] = useState({
    password:null,
    otp:null,
  })

  const navigate = useNavigate();

  const postOtpDetails = async () => {
    if(!emailDetails?.password || !emailDetails?.otp){
      seterrors({
        password: !emailDetails?.password ? "Password is required" : null,
        otp: !emailDetails?.otp ? "OTP is required" : null,
      })
      return;
    }
    seterrors({
      password: null,
      otp: null,
    });
    if (checkPasswordValidation()) {
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: true,
      });
    } else {
      setregiterDetailsError({
        ...regiterDetailsError,
        passwordValid: false,
      });
      return;
    }
    setloginError({
      status: false,
      message: "",
    })
    setloggingIn(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth/forget-password`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailDetails?.email,
          newPassword: emailDetails?.password,
          otp: emailDetails?.otp,
        },
      });

      // console.log(response, "response");
      if (response?.status == 200) {
        setloggingIn(false);
        setloginError({
          status: true,
          message: response?.data?.message,
        });
        setTimeout(() => {
          navigate("/login_email");
        }, 1500);
      }
    } catch (error) {
      setloggingIn(false);
      setloginError({
        status: true,
        message: error?.response?.data?.message,
      });
      console.log(error);
    }
  };
  //   console.log(location.state, "location.state");
  useEffect(() => {
    if (localStorage.getItem("user_token_headShots")) {
      navigate("/myheadshot");
    }
  }, []);

  return (
    <>
      <div className="drop-header w-full">
        <Navbar expand="lg" className="px-12 w-full">
          <Container className="w-full">
            <Navbar.Brand
              className="cursor-pointer br-right"
              onClick={() => {
                localStorage.removeItem("user_email_headShots");
                localStorage.removeItem("user_token_headShots");
                localStorage.removeItem("university_data");
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src="images/logo.png" alt="logo"></img>
            </Navbar.Brand>

            <div className="">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${
                  JSON.parse(localStorage.getItem("university_data"))?.logo
                }`}
                width={50}
                height={50}
                alt="..."
              />
            </div>
            <div className="px-2 fw-600">
              {
                JSON.parse(localStorage.getItem("university_data"))
                  ?.universityName
              }
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto flex flex-row justify-end mr-0 pr-0">
                <div className="flex flex-row justify-end ms-auto log-singbtn ">
                  <Nav.Link
                    className="fs-20 fw-600 me-4"
                    onClick={() => {
                      navigate("/login");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Log In
                  </Nav.Link>
                  <Nav.Link
                    className="fs-20 fw-600 cursor-pointer"
                    onClick={() => {
                      navigate("/sign_up");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Sign Up
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="hero-box">
        <img src="images/home-banner.png" className="hero-poster" alt="..." />
        <Container>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center txt-brown">
              <div className="login-form">
                <h2 className="fs-22 fw-700 text-center pb-4">
                  Create New Password
                </h2>
                <div className="mb-3">
                  <label className="form-label fw-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="Enter your email"
                    disabled
                    value={emailDetails?.email}
                    onChange={handleDetails}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-600">
                    Otp (Enter otp sent to your email)
                  </label>
                  <input
                    id="otp"
                    type="number"
                    class="form-control"
                    placeholder="Enter otp sent to your email"
                    value={emailDetails?.otp}
                    onChange={handleDetails}
                  />
                </div>
                {errors?.otp && (
                  <div className="m-1 text-danger">
                    {errors?.otp}
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label fw-600">New Password</label>
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    placeholder="Enter your new password"
                    value={emailDetails?.password}
                    onChange={handleDetails}
                  />
                </div>
                {showRules && (
                  <div className="mb-2">
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.length ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast 8 character</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.lowercase ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Lower case</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.uppercase ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Upper case</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.number ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>Atleast One Number</span>
                    </div>
                    <div className="reset-tick-box pb-2">
                      <span className="me-2">
                        <svg className="icon">
                          {passwordValidation.special ? (
                            <use href="#icon_green-tick"></use>
                          ) : (
                            <use href="#icon_wrong-tick"></use>
                          )}
                        </svg>
                      </span>
                      <span>
                        Atleast One Special Character (! @ # $ % ^ & % * ? _ ~)
                      </span>
                    </div>
                  </div>
                )}
                {!regiterDetailsError?.passwordValid && (
                  <div className="mb-3 text-danger">
                    Please enter valid password
                  </div>
                )}
                {loginError?.status && (
                  <div className="m-1 text-danger text-center">
                    {loginError?.message}
                  </div>
                )}
                <div className="mt-5">
                  <button
                    type="button"
                    disabled={loggingIn}
                    className="w-full rounded-md py-2 bg-[#FBB522] hover:bg-[#dfa423] hover:text-[#fff] font-semibold active:bg-[#dfa423] focus:outline-none focus:ring focus:ring-[#dfa423] ..."
                    onClick={postOtpDetails}
                  >
                    {loggingIn ? <Loader /> : "RESET PASSWORD"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPasswordOtp;
